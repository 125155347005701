// New store with typescript:
import {  PayloadAction } from "@reduxjs/toolkit";
import { HAVETOApi } from "../../services/api";
import { call, getContext, put, takeLeading } from "redux-saga/effects";
import { actionTypes, userWalletAction } from "./actions";
import { userWalletState } from "./reducer";
import { notification } from "antd";
import { TokenConversionPayload, UpdateTokenConversionPayload } from "../../services/api/wallet/types";

const { userWalletSuccess,userWalletFailure,tokenConversionSuccess,tokenConversionFailure,totalSoldHTCSuccess,totalSoldHTCFailure,totalSoldUSDSuccess,totalSoldUSDFailure,updateTokenConversionSuccess,updateTokenConversionFailure
} = userWalletAction;

function* getUserBalanceFlow(action: PayloadAction) {
  const api: HAVETOApi = yield getContext("api");
  const { wallet, error } = yield call(api.wallet.fetchUserBalance);
  
  if (error) {
    yield put(userWalletFailure({ wallet: null, error }));
  } else {
    yield put(userWalletSuccess({ wallet, error: null }));
  }
}

function* tokenConversionFlow(action: PayloadAction<TokenConversionPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { tokenConversion, error } = yield call(api.wallet.tokenConversion,action.payload);
  
  if (error) {
    yield put(tokenConversionFailure({ tokenConversion: null, error }));
  } else {
    yield put(tokenConversionSuccess({ tokenConversion, error: null }));
  }
}

function* updayeTokenConversionFlow(action: PayloadAction<UpdateTokenConversionPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { tokenConversion, error } = yield call(api.wallet.updateTokenConversionPrice,action.payload);
  
  if (error) {
    yield put(updateTokenConversionFailure({ tokenConversion: null, error }));
  } else {
    yield put(updateTokenConversionSuccess({ tokenConversion, error: null }));
  }
}

function* totalSoldHTCFlow(action: PayloadAction) {
  const api: HAVETOApi = yield getContext("api");
  const { totalSoldHtc, error } = yield call(api.wallet.totalSoldHTCCoin);
  
  if (error) {
    yield put(totalSoldHTCFailure({ totalSoldHtc: null, error }));
  } else {
    yield put(totalSoldHTCSuccess({ totalSoldHtc, error: null }));
  }
}

function* totalSoldUSDFlow(action: PayloadAction) {
  const api: HAVETOApi = yield getContext("api");
  const { totalSoldUsd, error } = yield call(api.wallet.totalSoldUSD);
  
  if (error) {
    yield put(totalSoldUSDFailure({ totalSoldUsd: null, error }));
  } else {
    yield put(totalSoldUSDSuccess({ totalSoldUsd, error: null }));
  }
}

export default function* userWalletSaga() {
    // Calling API fetch user balance
    yield takeLeading(actionTypes.UserWallet, getUserBalanceFlow);
    // Do Action when success called user wallet API
    yield takeLeading(actionTypes.UserWalletFailure, function* (action: PayloadAction<userWalletState>) {
      yield notification.error({message: action.payload?.error?.message.detail || "Something went wrong" })
    });

    // Calling API fetch token conversion
    yield takeLeading(actionTypes.TokenConversion, tokenConversionFlow);
    // Do Action when success called token conversion API
    yield takeLeading(actionTypes.TokenConversionFailure, function* (action: PayloadAction<userWalletState>) {
      yield notification.error({message: action.payload?.error?.message.detail || "Something went wrong" })
    });

    // Calling API fetch token conversion
    yield takeLeading(actionTypes.TotalSoldHTC, totalSoldHTCFlow);
    // Do Action when success called token conversion API
    yield takeLeading(actionTypes.TotalSoldHTCFailure, function* (action: PayloadAction<userWalletState>) {
      yield notification.error({message: action.payload?.error?.message.detail || "Something went wrong" })
    });

    // Calling API fetch token conversion
    yield takeLeading(actionTypes.totalSoldUSD, totalSoldUSDFlow);
    // Do Action when success called token conversion API
    yield takeLeading(actionTypes.totalSoldUSDFailure, function* (action: PayloadAction<userWalletState>) {
      yield notification.error({message: action.payload?.error?.message.detail || "Something went wrong" })
    });

    // Calling API edit token conversion
    yield takeLeading(actionTypes.UpdateTokenConversion, updayeTokenConversionFlow);
    // Do Action when success called edit token conversion API
    yield takeLeading(actionTypes.UpdateTokenConversionFailure, function* (action: PayloadAction<userWalletState>) {
      yield notification.error({message: action.payload?.error?.message || "Something went wrong" })
    });
}