import { Layout } from "antd";
import React, { useState } from "react";
import FooterSection from "./Footer";
import HeaderSection from "./Header";
import Sidebar from "./Sidebar";
import "./style.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import MobileSlidebar from "./MobileSlidebar";
import Transaction from "./Transaction";
import UserProfile from "./UserProfile";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import Users from "./Users";
import ManageUser from "./user_components/manage_user";

const { Content } = Layout;

const DashboardLayout = () => {
  const [mobileMenuCollapsed, setmobileMenuCollapsed] = useState(false);
  const {is_staff} = useAppSelector(userSelectors.getUserDetails);
  return (
    <Layout>
      <Sidebar/>
      <Layout className="site-layout">
        <HeaderSection setmenu={setmobileMenuCollapsed} />
        <MobileSlidebar
          menu={mobileMenuCollapsed}
          setmenu={setmobileMenuCollapsed}/>
        <div className="abs-header" />
        <Content style={{ margin: "0 30px" }}>
          <Routes>
            <Route path={"/"} element={<Dashboard />} />
            <Route path={"/transaction"} element={<Transaction/>} />
            <Route path={"/profile"} element={<UserProfile />} />
            {
              (is_staff !== undefined && is_staff) &&
                <>
                  <Route path={"/users"} element={<Users />} />
                  <Route path={"users/manage/:id"} element={<ManageUser />} />
                </>
            }
          </Routes>
        </Content>
        <FooterSection />
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
