import { createAction } from "@reduxjs/toolkit";
import { TransactionPayload, TransactionResponse  } from "../../services/api/transaction/types";


export const actionTypes = {
    GetAllTransactions: '[Transaction] Get All Transactions',
    GetAllTransactionsSuccess: '[Transaction] Get All Transactions Success',
    GetAllTransactionsFailure: '[Transaction] Get All Transactions Failure',

    GetUserTransactions: '[Transaction] Get User Transactions',
    GetUserTransactionsSuccess: '[Transaction] Get User Transactions Success',
    GetUserTransactionsFailure: '[Transaction] Get User Transactions Failure',
}

export const transactionActions = {
    getAllTransactions: createAction<TransactionPayload>(actionTypes.GetAllTransactions),
    getAllTransactionsSuccess: createAction<TransactionResponse>(actionTypes.GetAllTransactionsSuccess),
    getAllTransactionsFailure: createAction<TransactionResponse>(actionTypes.GetAllTransactionsFailure),

    // actions for get user transactions
    getUserTransactions: createAction<TransactionPayload>(actionTypes.GetUserTransactions),
    getUserTransactionsSuccess: createAction<TransactionResponse>(actionTypes.GetUserTransactionsSuccess),
    getUserTransactionsFailure: createAction<TransactionResponse>(actionTypes.GetUserTransactionsFailure),
}