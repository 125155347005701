import React, { useEffect } from "react";
import { Card, Col, Row } from "antd";
import KycVerificationCard from "./KycVerificationCard";
import PersonalInfoCard from "./PersonalInfoCard";
import RecentTransaction from "./RecentTransaction";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../store/hooks";
import { userActions } from "../../../../store/user";

const ManageUser = () => {
    const dispatch = useAppDispatch()
    let { id } = useParams();
    useEffect(() => {   
        dispatch(userActions.manageUserDetails({user_id:Number(id)}))
    }, [dispatch,id])
    
  return (
    <>
      <Card
        title={
          <Link to={"/users"}>
            <div className="profile-text-color text-12">
              <LeftOutlined /> Manage User
            </div>
          </Link>
        }
        className={"manage-user-container"}
      >
        <div className="manage-user-body">
          <Row gutter={[0,24]}>
            <Col span={24}>
              <PersonalInfoCard />
            </Col>
            <Col span={24}>
              <KycVerificationCard />
            </Col>
            <Col span={24}>
              <RecentTransaction />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default ManageUser;
