import { createAction } from "@reduxjs/toolkit";
import {  TokenConversionPayload, UpdateTokenConversionPayload, UserWalletResponse } from "../../services/api/wallet/types";


export const actionTypes = {
    UserWallet: '[Wallet] User Wallet',
    UserWalletSuccess: '[Wallet] User Wallet Success',
    UserWalletFailure: '[Wallet] User Wallet Failure',

    TokenConversion: '[Conversion] Token Conversion',
    TokenConversionSuccess: '[Wallet] Token Conversion Success',
    TokenConversionFailure: '[Wallet] Token Conversion Failure',

    UpdateTokenConversion: '[Update Price] Update Token Conversion',
    UpdateTokenConversionSuccess: '[Update Price] Update Token Conversion Success',
    UpdateTokenConversionFailure: '[Update Price] Update Token Conversion Failure',

    TotalSoldHTC:'[Total Sold] Total Sold Htc Coin',
    TotalSoldHTCSuccess:'[Total Sold] Total Sold Htc Coin Success',
    TotalSoldHTCFailure:'[Total Sold] Total Sold Htc Coin Failure',

    totalSoldUSD:'[Total Sold] Total Sold USD',
    totalSoldUSDSuccess:'[Total Sold] Total Sold USD Success',
    totalSoldUSDFailure:'[Total Sold] Total Sold USD Failure',
}

export const userWalletAction = {
    userWallet: createAction(actionTypes.UserWallet),
    userWalletSuccess: createAction<UserWalletResponse>(actionTypes.UserWalletSuccess),
    userWalletFailure: createAction<UserWalletResponse>(actionTypes.UserWalletFailure),

    tokenConversion: createAction<TokenConversionPayload>(actionTypes.TokenConversion),
    tokenConversionSuccess: createAction<UserWalletResponse>(actionTypes.TokenConversionSuccess),
    tokenConversionFailure: createAction<UserWalletResponse>(actionTypes.TokenConversionFailure),

    updateTokenConversion: createAction<UpdateTokenConversionPayload>(actionTypes.UpdateTokenConversion),
    updateTokenConversionSuccess: createAction<UserWalletResponse>(actionTypes.UpdateTokenConversionSuccess),
    updateTokenConversionFailure: createAction<UserWalletResponse>(actionTypes.UpdateTokenConversionFailure),

    totalSoldHTC: createAction(actionTypes.TotalSoldHTC),
    totalSoldHTCSuccess: createAction<UserWalletResponse>(actionTypes.TotalSoldHTCSuccess),
    totalSoldHTCFailure: createAction<UserWalletResponse>(actionTypes.TotalSoldHTCFailure),

    totalSoldUSD: createAction(actionTypes.totalSoldUSD),
    totalSoldUSDSuccess: createAction<UserWalletResponse>(actionTypes.totalSoldUSDSuccess),
    totalSoldUSDFailure: createAction<UserWalletResponse>(actionTypes.totalSoldUSDFailure),

}