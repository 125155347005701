import React from "react";
import { Col, Row, Image, Button, Space } from "antd";
import UserHeader from "./UserHeader";
import thanks from "../../assets/user-dashboard/thank.gif";
import  {RightOutlined}  from "@ant-design/icons";
import {  STEP_STATUS } from "../../constants/stepStatus";
import { userActions } from "../../store/user";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { stepStatusUpdate } from "../../store/user/reducer";

const ThankyouForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onClickDashboard =() =>{
    dispatch(userActions.updateUser({ step_status: STEP_STATUS.DASHBOARD.NUMBER }));
    dispatch(stepStatusUpdate("Dashboard"))
    navigate("/"); 
  }
  return (
    <>
      <UserHeader /> {/* Header */}
      <div className="app-data-container text-center" style={{marginTop:"60px"}}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Space direction="vertical" size={0}>
                <Image src={thanks} className={"process-img"} preview={false} />
                <p className="user-header-text">Thank you !!</p>
            </Space>
          </Col>
          <Col span={24}>
            <p className="sub-text text-white-color">
              We are currently checking your data,
              <br />
              The verification status will update automatically.
            </p>
          </Col>
          <Col span={24}>
            <Button className="verify-button" onClick={onClickDashboard}>Go to Dashboard <RightOutlined /></Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ThankyouForm;
