import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { UserTransactionInterface } from "../../../services/api/transaction/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { transactionActions, transactionSelectors } from "../../../store/transaction";
import "./style.css";

const UserTransactions: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const userTransactions = useAppSelector(transactionSelectors.getUserTransactions);
  const loader = useAppSelector(transactionSelectors.getLoader);
  const dispatch = useAppDispatch();

  const paginationProps = {
    showSizeChanger: true,
    pageSize,
    total: userTransactions.count,
    onChange: (pageNumber: number) => {
      dispatch(transactionActions.getUserTransactions({ page: pageNumber, pageSize }));
    },
    onShowSizeChange: (current: number, size: number) => {
      setPageSize(size);
      dispatch(transactionActions.getUserTransactions({ page: current, pageSize: size }));
    },
  };

  useEffect(() => {
    dispatch(transactionActions.getUserTransactions({ page: 1, pageSize }));
  }, [dispatch, pageSize]);

  const columns: ColumnsType<UserTransactionInterface> = [
    {
      key: "orderId",
      title: "Order Id",
      dataIndex: "product_id",
      className: 'max-width-250',
      render: (product_id: string) => <div className="grey"> {`${product_id}`}</div>,
      sorter: (a, b) => a.product_id.localeCompare(b.product_id),
    },
    {
      key: "paymentAddress",
      title: "Payment Address",
      className: 'max-width-250',
      dataIndex: "payment_address",
      render: (payment_address: string) => <div className="grey"
      // style={{ textAlign: "center" }}
      >
        {payment_address ? payment_address === "Null" ? "-" : payment_address : "-"}
      </div>
    },
    {
      key: "PurchaseAmount",
      title: "Purchase Amount",
      dataIndex: "coin_amount",
      render: (coin_amount: number, transaction) => (
        <div className="grey">
          {coin_amount}
          <span className="text-primary-color"> {transaction?.coin?.toUpperCase()}</span>
        </div>
      ),
      sorter: (a, b) => a.coin_amount - b.coin_amount,
    },
    {
      key: "usdAmount",
      title: "Amount in USD",
      dataIndex: "usd_amount",
      render: (usd_amount) => {
        const amount = parseFloat(usd_amount);
        return (
          <div className="grey">
            {isNaN(amount) ? '0.00' : amount.toFixed(2)}
            <span className="text-primary-color"> USD</span>
          </div>
        );
      },
      sorter: (a, b) => a.usd_amount - b.usd_amount,
    },
    {
      key: "dateTime",
      title: "Date-time [UTC]",
      dataIndex: "created_or_updated_at",
      render: (created_or_updated_at: string) => (
        <div className="grey">
          {moment(created_or_updated_at).format("DD MMM Y ● hh:mm:ss")}
        </div>
      ),
      sorter: (a, b) => a.created_or_updated_at.localeCompare(b.created_or_updated_at),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'checkout_session_status',
      render: (checkout_session_status) => {
        const status = checkout_session_status || "pending";
        const getStatusTag = (status: string) => {
          let color;
          switch (status) {
            case "complete":
              color = "#87d068";
              break;
            case "cancelled":
              color = "#f50";
              break;
            case "Cancelled":
              color = "#f50";
              break;
            case "expired":
              color = "#f50";
              break;
            default:
              color = "#FFC700";
              break;
          }
          return (
            <Tag style={{ backgroundColor: 'transparent', border: `1px solid ${color}`, color: color }}>
              {status.charAt(0).toUpperCase() + status.substring(1)}
            </Tag>
          );
        };
        return (
          <div>
            {getStatusTag(status)}
          </div>
        );
      },
      sorter: (a, b) => a.payment_status.localeCompare(b.payment_status),
    }


  ];

  return (
    <Table
      loading={loader}
      sticky
      scroll={{ x: true, y: 525 }}
      pagination={paginationProps}
      columns={columns}
      dataSource={userTransactions.results}
    />
  );
};

export default UserTransactions;
