import React, { useEffect, useState } from "react";
import Icon, {
  LogoutOutlined,
  UserOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Layout,
  Image,
  Dropdown,
  Menu,
  Popconfirm,
  Affix,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { uiSelectors } from "../../store/ui";
import { userActions } from "../../store/user";
import { setcurrentSelectedMenu, toggleSidebarCollapse } from "../../store/ui/reducer";
import { ReactComponent as menuIcon } from "../../assets/websiteIcon/menu.svg";
import havetoLogo from "../../assets/haveto.svg";
import userSelectors from "../../store/user/selectors";
import { Link } from "react-router-dom";
const { Header } = Layout;
interface menuInterface {
  setmenu: Function;
}
const HeaderSection = (props: menuInterface) => {
  //  screen width dynamically withput reloading
  const [screen, setScreen] = useState(getWindowSize());
  const getUser = useAppSelector(userSelectors.getUserDetails);
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector(uiSelectors.getSidebarCollapsed);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setScreen(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const Avatarmenu = (
    <Affix offsetTop={80}>
      <Menu
        className="header-menu"
        items={[
          {
            key: "1",
            icon: <UserOutlined />,
            label: (
              <Link to="profile"  onClick={()=>{
                dispatch(setcurrentSelectedMenu('/profile'));
              }}>
               Profile
              </Link>
            ),
          },
          {
            key: "2",
            icon: <LogoutOutlined />,
            label: (
              <Popconfirm
                placement="left"
                title={"Are you sure want to sign out ??"}
                onConfirm={() => dispatch(userActions.signOut())}
                okText="Yes"
                cancelText="No"
              >
                <div>Logout</div>
              </Popconfirm>
            ),
          },
        ]}
      />
    </Affix>
  );

  return (
    <Header className="site-layout-background">
      {React.createElement(
        collapsed ? VerticalLeftOutlined : VerticalRightOutlined,
        {
          className: "trigger",
          onClick: () => dispatch(toggleSidebarCollapse(!collapsed)),
        }
      )}
      <div className={screen.innerWidth >= 767 ? collapsed ? "header-right-collapse" : "header-right" : "header-right-collapse"}>
        {screen.innerWidth <= 767 && (
          <>
            <Button className="menu" onClick={() => props.setmenu(true)}>
              <Icon component={menuIcon} />
            </Button>
            <Image
              className="haveto-mobile-img"
              src={havetoLogo}
              preview={false}
            />
          </>
        )}
        <Dropdown overlay={Avatarmenu} placement="top">
          <Avatar className="profile-avtar" size="large">
            {getUser?.first_name?.[0]
              .concat(getUser?.last_name?.[0] ?? "")
              .toUpperCase()}
          </Avatar>
        </Dropdown>
      </div>
    </Header>
  );
};

export default HeaderSection;
