import React from "react";
import { Modal } from "antd";

interface UploadDocPropsInterface {
  previewOpen: boolean;
  imageUrl: string;
  onClose: () => void;
}
const UploadedDocsModal = ({
  previewOpen,
  imageUrl,
  onClose,
}: UploadDocPropsInterface) => {
  return (
    <Modal
      style={{ background: "white" }}
      visible={previewOpen}
      centered
      title={imageUrl.includes("front_image") ? "Front image" : "Back image"}
      footer={null}
      onCancel={onClose}
    >
      <img alt={imageUrl} style={{ width: "100%" }} src={imageUrl} />
    </Modal>
  );
};

export default UploadedDocsModal;
