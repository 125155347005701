import React, { useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
  Card
} from "antd";
import { DollarOutlined, ShopOutlined } from "@ant-design/icons";
import HAVETOApi from "../../services/api/now_payments/api";
import { CoinsInterface } from "../../services/api/now_payments/types";
import giftIcon from "../../assets/gift-icon.png";

interface BuyCoinModalInterface {
  visible: boolean;
  onClose: () => void;
}
interface BuyCoinFormInterface {
  amount: number;
  crypto_coin: string;
}

const BuyHavetoCoinModal = ({ visible, onClose }: BuyCoinModalInterface) => {
  const [appForm] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [paymentGatewayType, setPaymentGatewayType] = useState("copperx")
  const [paymentResponse, setPaymentResponse] = useState<any>({});
  const [coins, setCoins] = useState<CoinsInterface[]>([]);
  const [iscopy, setIscopy] = useState<boolean>(false);
  const [highlightedCard, setHighlightedCard] = useState<string | null>(null);
  const minAmounts: { [key: string]: number } = {
    USDC: 50,
    USDT: 50,
    ETH: 0.015,
    BTC: 0.00085,
    DAI: 50,
    BNB: 0.087,
    MATIC: 93,
    BUSD: 51,
    SOL: 0.38,
    TCN:0.01,
  };

  const offers = [
    {
      id: "3%",
      title: "Get 3% Extra",
      description: "Buy coins in the range of $10,000 to $100,000 and earn 3% extra coins as a bonus",
    },
    {
      id: "7%",
      title: "Get 7% Extra",
      description: "Purchase coins valued at over $100,000 and receive an additional 7% in bonus coins",
    },
  ];

  useEffect(() => {
    if (visible) {
      setIsVisible(false);
      appForm.resetFields();
      appForm.setFieldsValue({ crypto_coin: "USDC", amount: minAmounts.USDC });
      getCoins();
    }
  }, [visible]);

  const onFinish = (values: BuyCoinFormInterface) => {
    setLoader(true);
    const paymemntAPI=paymentGatewayType === "copperx"?HAVETOApi.makeCoperXPayment:HAVETOApi.makeCoinRemitterPayment
    paymemntAPI({
      price_amount: values.amount,
      crypto_coin: values.crypto_coin,
    })
      .then((result) => {
        setLoader(false);
        setPaymentResponse(result.paymentResponse)
        if(result?.paymentResponse?.url){
          window.location.href = result.paymentResponse.url;
          setIsVisible(true);
        }else{
          if (result?.paymentResponse.payment_url) {
            window.location.href = result.paymentResponse.payment_url;
            handleCancel();
          }
        }
      })
      .catch((error) => {
        setLoader(false);
        notification.error({ message: error?.detail.split(":")[1] });
      });
  };

  const handleCancel = () => {
    appForm.resetFields();
    setIsVisible(false);
    onClose();
  };

  const getCoins = () => {
    if (coins?.length === 0) {
      HAVETOApi.getCryptoCoin()
        .then((result) => {
          setCoins(result.coins);
        })
        .catch((error) => {
          setLoader(false);
          notification.error({ message: error?.detail.split(":")[1] });
        });
    }
  };

  const handleCoinChange = (value: keyof typeof minAmounts) => {
    appForm.setFieldsValue({ amount: minAmounts[value] });
    const selectedCoin:any[]=coins.filter(coin => coin.coin===value);
    setPaymentGatewayType(selectedCoin[0].payment_gateway)
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (value >= 10000 && value <= 99999) {
      setHighlightedCard("3%");
    } else if (value >= 100000) {
      setHighlightedCard("7%");
    } else {
      setHighlightedCard(null);
    }
  };

  return (
    <Modal
      style={{ background: "white" }}
      visible={visible}
      title={"Haveto Payments"}
      centered={true}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="offers-section">
        {offers.map((offer) => (
          <Card
            key={offer.id}
            className={`offer-card ${highlightedCard === offer.id ? "highlight" : ""}`}
          >
            <Row>
              <Col xs={4} lg={6} md={6} sm={6}>
                <img src={giftIcon} alt="Gift" className="offer-icon" />
              </Col>
              <Col xs={20} lg={18} md={18} sm={18}>
                <div className="offer-title-box">
                  <Typography.Text className="offer-title">
                    {offer.title}
                  </Typography.Text>
                </div>
                <Typography.Text className="offer-description">
                  {offer.description}
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        ))}
      </div>
      <Form form={appForm} layout="vertical" onFinish={onFinish}>
        <Row gutter={[0, 10]} align="middle" justify="center">
          <Col span={24}>
            <Form.Item
              label={
                <Typography.Text className="text-black-color">
                  Amount
                </Typography.Text>
              }
              name="amount"
              validateTrigger="onChange"
              rules={[
                {
                  required: true,
                  message: "Enter amount",
                },
                {
                  validator: (_, value) => {
                    if (
                      value >= minAmounts[appForm.getFieldValue("crypto_coin")]
                    ) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          `Minimum amount is ${
                            minAmounts[appForm.getFieldValue("crypto_coin")]
                          }`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                disabled={isVisible}
                prefix={<DollarOutlined />}
                type="number"
                className="email-input"
                placeholder="Enter Amount"
                onChange={handleAmountChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <Typography.Text className="text-black-color">
                  Coin
                </Typography.Text>
              }
              name="crypto_coin"
              rules={[
                {
                  required: true,
                  message: "Select Coin",
                },
              ]}
            >
              <Select
                onClick={getCoins}
                onChange={handleCoinChange}
                disabled={isVisible}
                className="currency-option-select"
                defaultValue="USDC"
              >
                {coins?.map((coin) => (
                  <Select.Option key={coin.coin} value={coin.coin}>
                    {coin.coin}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={" "}>
              <Button
                className={"buy-htc-coin"}
                onClick={appForm.submit}
                loading={loader}
                icon={<ShopOutlined />}
              >
                Buy Coin
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BuyHavetoCoinModal;
