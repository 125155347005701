import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import { TotalDurationPayload, TransactionApiInterface, TransactionPayload, TransactionResources } from "./types";

const TRANSACTION_ENDPOINTS: TransactionResources = {
  // create subscription get notified
  GET_USER_TRANSACTIONS: {
    URL: API.GET_USER_TRANSACTIONS.URL,
    METHOD: API.GET_USER_TRANSACTIONS.METHOD,
  },
  GET_ALL_TRANSACTIONS: {
    URL: API.GET_ALL_TRANSACTIONS.URL,
    METHOD: API.GET_ALL_TRANSACTIONS.METHOD,
  },
  GET_SALES_HAVETO_CHART: {
    URL: API.SALES_HAVETO_COIN_CHART.URL,
    METHOD: API.SALES_HAVETO_COIN_CHART.METHOD,
  },
};

const haveToApi: TransactionApiInterface = {
  // Get all transactions
  getAllTransactions: async (payload:TransactionPayload) => {
    const { response, error } = await haveToApiService({
      resource: TRANSACTION_ENDPOINTS.GET_ALL_TRANSACTIONS,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      options:{
        queryParams:{
          page:payload.page,
          page_size:payload.pageSize
        }
      }
    });

    if (error) {
      return { error, transaction: null };
    } else {
      return {
        transaction: response,
        error: null,
      };
    }
  },
  // Get all transactions of user 
  getUserTransactions: async (payload:TransactionPayload) => {
    const { response, error } = await haveToApiService({
      resource: TRANSACTION_ENDPOINTS.GET_USER_TRANSACTIONS,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      options: {
        // Admin access to user transaction
        queryParams: payload.user_id ?
        {
          id: Number(payload.user_id),
          page:payload.page,
          page_size:payload.pageSize
        }
        // User access to user transaction
        :
        {
          page:payload.page,
          page_size:payload.pageSize
        }
      },
    });

    if (error) {
      return { error, userTransaction: null };
    } else {
      return {
        userTransaction: response,
        error: null,
      };
    }
  },
  // Fetch chart data on dashboard : sales of haveto coin 
  getSalesHaveToChartData: async (payload:TotalDurationPayload) => {
    const { response, error } = await haveToApiService({
      resource: TRANSACTION_ENDPOINTS.GET_SALES_HAVETO_CHART,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      options:{
        queryParams: {
          duration:payload.duration
        },
      }
    });

    if (error) {
      throw error;
    } else {
      return {
        token_sale_data: response.data,
        error: null,
      };
    }
  },
};

export default haveToApi;

export { TRANSACTION_ENDPOINTS };
