import api from "./api";
import log from "./log";

export interface HAVETOServices {
  api: typeof api;
  log: typeof log;
}

const services: HAVETOServices = {
  api,
  log,
};
export default services;
