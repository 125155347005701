import React from "react";
import { Avatar, Button, Space, Typography } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import userSelectors from "../../../store/user/selectors";
import moment from "moment";
import { userActions } from "../../../store/user";

const PersonalInfoCard = () => {
  const user = useAppSelector(userSelectors.getUserDetails);
  const dispatch = useAppDispatch();
  return (
    <div className="profile-personal-info">
      {/* profile photo and name */}
      <Space direction="horizontal" size={40}>
        <Avatar
          className="profile-avtar-photo"
          icon={
            <div className="text-32 text-primary-color">
              {user?.first_name?.[0]
                .concat(user?.last_name?.[0] ?? "")
                .toUpperCase()}
            </div>
          }
        ></Avatar>
        <div className="profile-user-name">
          {user.first_name?.charAt(0).toUpperCase() +
            user.first_name?.slice(1)! +
            " " +
            user.last_name?.charAt(0).toUpperCase() +
            user.last_name?.slice(1)! ?? "-"}

          {user.is_staff ? (
            <Typography className="text-16 profile-text-color">
              <UserOutlined /> Admin
            </Typography>
          ) : (
            <Typography className="text-16 profile-text-color">
              Last logged in:{" "}
              {moment(user.last_login).format("Do MMM, Y") ?? "-"}
            </Typography>
          )}
        </div>
      </Space>
      {/* additional information */}
      <div>
        <Space direction="vertical" size={10}>
          <Typography.Text className="text-16 profile-text-color">
            Email : {user.email ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Location : {user.country ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            DOB : {moment(user.date_of_birth).format("Do MMM, Y") ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Joined : {moment(user.date_joined).format("Do MMM, Y") ?? "-"}
          </Typography.Text>
        </Space>
      </div>
      {/* Logout button */}
      <Button
        icon={<LogoutOutlined />}
        className="proifle-logout-button"
        onClick={() => {
          dispatch(userActions.signOut());
        }}
      >
        Logout
      </Button>
    </div>
  );
};

export default PersonalInfoCard;
