import React from 'react';
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/user";


const DirectPurchasePage: React.FC = () => {
  const dispatch = useAppDispatch()
  return (
    <div className="features" id='direct-purchase'>
      <div className="header-text">Direct Purchase</div>
      <div className='direct-purchase-page'>
        <div className="purchase-card">
          <h2>&lt;$10,000</h2>
          <p>Unlock exclusive benefits with a purchase of over $10,000 worth of coins. Secure your investment today!</p>
          <button onClick={() => dispatch(userActions.signInModal({visible:true}))}>Buy Now</button>
        </div>
        <div className="purchase-card">
          <h2>$10,000 - $100,000</h2>
          <div className="extra-info">Get 3% extra</div>
          <p>Get the best value for your purchase between $10,000 and $100,000. Your coins will be transferred to your wallet securely.</p>
          <button onClick={() => dispatch(userActions.signInModal({visible:true}))}>Buy Now</button>
        </div>
        <div className="purchase-card">
          <h2>&gt;$100,000</h2>
          <div className="extra-info">Get 7% extra</div>
          <p>Enjoy premium advantages with a purchase over $100,000 worth of coins. Quick and secure transfers guaranteed.</p>
          <button onClick={() => dispatch(userActions.signInModal({visible:true}))}>Buy Now</button>
        </div>
        </div>
    </div>
  );
};

export default DirectPurchasePage;
