import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import {
  MakePaymentPayload,
  NowPaymentApiInterface,
  NowPaymentsResources,
} from "./types";

const NOW_PAYMENT_ENDPOINTS: NowPaymentsResources = {
  // get all  coin
  GET_CRYPTO_COIN: {
    URL: API.GET_CRYPTO_COIN.URL,
    METHOD: API.GET_CRYPTO_COIN.METHOD,
  },
  // make payment
  GET_PAYMENT_CopperX_LINK: {
    URL: API.GET_PAYMENT_CopperX_LINK.URL,
    METHOD: API.GET_PAYMENT_CopperX_LINK.METHOD,
  },
  GET_PAYMENT_COINREMITTER_LINK: {
    URL: API.GET_PAYMENT_COINREMITTER_LINK.URL,
    METHOD: API.GET_PAYMENT_COINREMITTER_LINK.METHOD,
  },
};

const HAVETOApi: NowPaymentApiInterface = {
  makeCoperXPayment: async (payload: MakePaymentPayload) => {
    const { error, response } = await haveToApiService({
      resource: NOW_PAYMENT_ENDPOINTS.GET_PAYMENT_CopperX_LINK,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        data: {
          amount: payload.price_amount,
          crypto_coin: payload.crypto_coin,
        },
      },
    });
    if (error) {
      throw error.message;
    } else {
      return {
        paymentResponse: response,
        error: null,
      };
    }
  },
  makeCoinRemitterPayment: async (payload: MakePaymentPayload) => {
    const { error, response } = await haveToApiService({
      resource: NOW_PAYMENT_ENDPOINTS.GET_PAYMENT_COINREMITTER_LINK,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        data: {
          amount: payload.price_amount,
          crypto_coin: payload.crypto_coin,
        },
      },
    });
    if (error) {
      throw error.message;
    } else {
      return {
        paymentResponse: response,
        error: null,
      };
    }
  },
  getCryptoCoin: async () => {
    const { error, response } = await haveToApiService({
      resource: NOW_PAYMENT_ENDPOINTS.GET_CRYPTO_COIN,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      
    });
    if (error) {
      throw error.message;
    } else {
      return {
        coins: response,
        error: null,
      };
    }
  },
};

export default HAVETOApi;

export { NOW_PAYMENT_ENDPOINTS };
