import { createSlice, PayloadAction } from "@reduxjs/toolkit";


//  This reducer for sidebar collapse and expand in dashboard
export interface UIState {
  collapse : boolean;
  currentSelectedMenu : string;
}
const initialState: UIState = {
    collapse : (window.innerWidth<=767) || (window.screen.width >=992 && window.screen.width <=1200)  ?true : false,
    currentSelectedMenu:"/"
};
const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSidebarCollapse: (state, action: PayloadAction<boolean>) => {
        state.collapse = action.payload;
    },
    setcurrentSelectedMenu: (state, action: PayloadAction<string>) => {
        state.currentSelectedMenu = action.payload;
    },
  },
  extraReducers: {
    
  },
})

export default UISlice.reducer;

const {toggleSidebarCollapse,setcurrentSelectedMenu} = UISlice.actions;


export {toggleSidebarCollapse , setcurrentSelectedMenu};