import React, { useState } from "react";
import {
  Col,
  Form,
  Radio,
  Row,
  Select,
  Space,
  Image,
  Upload,
  UploadFile,
  UploadProps,
  Modal,
  notification,
} from "antd";
import tutorial1 from "../../assets/doc-tutorials/Tutorial_1.webp";
import tutorial2 from "../../assets/doc-tutorials/Tutorial_2.webp";
import tutorial3 from "../../assets/doc-tutorials/Tutorial_3.webp";
import { ReactComponent as uploadIcon } from "../../assets/doc-tutorials/Upload.svg";
import { RcFile } from "antd/lib/upload";
import Icon from "@ant-design/icons";
import {countryList} from '../../constants/countryList'

const IdentityDocument = (props:any) => {
  const [identitySelect, setIdentitySelect] = useState<number>(0);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const imageList = [
    {
      image: tutorial1,
      preview: false,
    },
    {
      image: tutorial2,
      preview: false,
    },
    {
      image: tutorial3,
      preview: false,
    },
  ];
  const [frontImg, setFrontImg] = useState<UploadFile[]>([]);
  const [backImg, setBackImg] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [uploadDocVisible, setUploadDocVisible] = useState<boolean>(false);

  const handleCancel = () => setPreviewOpen(false);

  const frontOnChange: UploadProps["onChange"] = ({
    fileList: newFileList
  }) => {
    setFrontImg(newFileList);
    props.formdata.setFieldsValue({ front_img: newFileList[0] });
  };
  const backOnChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setBackImg(newFileList);
    props.formdata.setFieldsValue({ back_img: newFileList[0] });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleUpload = async ({ onSuccess }: any) => {
    // Custom request function to prevent default upload behavior
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Space direction="vertical" size={20}>
      <Form.Item
        label="Select the country that issued your document"
        name="country"
        rules={[
          {
            required: true,
            message: "Select your country!",
          },
        ]}
      >
        <Select
          className="app-select-input"
          listHeight={160}
          allowClear={true}
          showSearch={true}
          placeholder="Select country"
        >
          {countryList?.map((item) => (
            <Select.Option key={item.code} value={item.name}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Choose your document type"
        name="identity_proof"
        rules={[
          {
            required: true,
            message: "Select any document field!",
          },
        ]}
      >
        <Radio.Group
          value={identitySelect}
          onChange={(value) => {
            setUploadDocVisible(true);
            setIdentitySelect(value.target.value);
          }}
        >
          <Space
            direction={window.screen.width <= 480 ? "vertical" : "horizontal"}
          >
            <Space direction="vertical">
              <Radio value={"Passport"} className="app-radio-input">
                Passport
              </Radio>
              <Radio value={"Driving license"} className="app-radio-input">
                Driving license
              </Radio>
            </Space>
            <Space direction="vertical">
              <Radio value={"ID card"} className="app-radio-input">
                ID card
              </Radio>
              <Radio value={"Residence permit"} className="app-radio-input">
                Residence permit
              </Radio>
            </Space>
          </Space>
        </Radio.Group>
      </Form.Item>
      {uploadDocVisible === true && (
        <>
          <Form.Item label="Take a photo of your driver's license. The photo should be:">
            <ul className="document-rule-list">
              <li>Bright & clear (Good quality)</li>
              <li>Uncut (All corners of the document should be visible)</li>
            </ul>
          </Form.Item>
          <Form.Item>
            <Row className="text-center" gutter={[10, 10]}>
              {imageList?.map((item) => (
                <Col key={item.image} span={8}>
                  <Image
                    src={item.image}
                    alt={item.image}
                    preview={item.preview}
                  />
                </Col>
              ))}
            </Row>
          </Form.Item>
          <Row className="text-center" gutter={[20, 20]}>
            <Col lg={12} xs={24}>
              <Form.Item
                name="front_img"
                rules={[
                  {
                    required: true,
                    message: "Upload front image of identity proof",
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  beforeUpload={(file) => {
                    const isValid =
                      file.type === "image/jpg" ||
                      file.type === "image/jpeg" ||
                      file.type === "image/png" ||
                      file.type === "image/pdf";
                    if (!isValid) {
                      notification.error({
                        message: "Document type must be jpg/jpeg/png/pdf only !!",
                      });
                    }
                    return isValid || Upload.LIST_IGNORE;
                  }}
                  fileList={frontImg}
                  onChange={frontOnChange}
                  onPreview={handlePreview}
                  accept=".png,.jpg,.jpeg"
                  customRequest={handleUpload}
                >
                  {frontImg.length < 1 && (
                    <Space direction="vertical" size={0}>
                      <Icon component={uploadIcon} className="upload-icon" />
                      <p className="grey">
                        Upload the <span className="primary-color">front</span>
                        &nbsp; of
                      </p>
                      <p className="grey"> your document</p>
                    </Space>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="back_img"
                rules={[
                  {
                    required: true,
                    message: "Upload back image of identity proof",
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  fileList={backImg}
                  beforeUpload={(file) => {
                    const isValid =
                      file.type === "image/jpg" ||
                      file.type === "image/jpeg" ||
                      file.type === "image/png" ||
                      file.type === "image/pdf";
                    if (!isValid) {
                      notification.error({
                        message: "Document type must be jpg/jpeg/png/pdf only !!",
                      });
                    }
                    return isValid || Upload.LIST_IGNORE;
                  }}
                  onChange={backOnChange}
                  onPreview={handlePreview}
                  accept=".png,.jpg,.jpeg"
                  customRequest={handleUpload}
                >
                  {backImg.length < 1 && (
                    <Space direction="vertical" size={0}>
                      <Icon component={uploadIcon} className="upload-icon" />
                      <p className="grey">
                        Upload the <span className="primary-color">back</span>
                        &nbsp; of
                      </p>
                      <p className="grey"> your document</p>
                    </Space>
                  )}
                </Upload>
              </Form.Item>
              <Modal
                style={{ background: "black" }}
                visible={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="uploaded_image"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Col>
          </Row>
        </>
      )}
    </Space>
  );
};

export default IdentityDocument;
