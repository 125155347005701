import jwt_decode from "jwt-decode";

const isToken:any = localStorage.getItem('authToken');

export const verify_token=()=>{
    try {
       let currentDate = new Date();
       const decodedToken=isToken && jwt_decode(isToken);
       // JWT exp is in seconds 
        if(isToken){
            if ( isToken=== "" || decodedToken?.exp * 1000 < currentDate.getTime()) {
                return false;
            } else {
                return true;
            }
        }
     } catch (error) {
       return false;
     }
}