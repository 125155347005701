// Step_status to identify user completed howmany steps in registration process
interface StepStatusInterface {
    [key: string]: {
      NUMBER: number;
      STATUS: string;
    };
}

export const STEP_STATUS: StepStatusInterface = {
    VERIFY_ACCOUNT : {
        NUMBER : 1,
        STATUS : "Verify Account"
    },
    LETS_VERIFY : {
        NUMBER : 2,
        STATUS : "Lets Verify"
    },
    IDENTITY_DOCUMENT : {
        NUMBER : 3,
        STATUS : "Identity Document"
    },
    THANK_YOU : {
        NUMBER : 4,
        STATUS : "Identity Document"
    },
    DASHBOARD : {
        NUMBER : 5,
        STATUS : "Dashboard"
    }
}
