// import React, { useState } from "react";
import { Col, Row, Space, Image } from "antd";
import automaticShard from "../../assets/websiteIcon/features/autoshard.png";
import docker from "../../assets/websiteIcon/features/docker.webp";
import ai from "../../assets/websiteIcon/features/AI.webp";
import tool from "../../assets/websiteIcon/features/tools.webp";
import transaction from "../../assets/websiteIcon/features/transaction.png";
// import demo from "../../assets/websiteIcon/features/demo.webp";

interface FeatureRowInterface{
  key:number;
  title: string;
  order1:string;
  order2:string;
  image:string;
  description:string;
}

const Features = () => {
  // const [videoVisible, setvideoVisible] = useState(false);

  const dataSource:FeatureRowInterface[] = [
    { 
      key:1,
      title:"Auto Scaling (Sharding)",
      order1:"feature-order2",
      order2:"feature-order1",
      image:automaticShard,
      description:"Auto scaling in blockchain distributes transaction processing across multiple nodes, enhancing network scalability and efficiency without manual intervention."
    },
    { 
      key:2,
      title:"Use Any Programming Language-Dockerization",
      order1:"feature-order1",
      order2:"feature-order2",
      image:docker,
      description:"HaveTo facilitates the use of any programming language through dockerization."
    },
    { 
      key:3,
      title:"On chain Artificial Intelligence (LLM)",
      order1:"feature-order2",
      order2:"feature-order1",
      image:ai,
      description:"HaveTo is empowered to execute Artificial Intelligence models directly on the blockchain."
    },
    { 
      key:4,
      title:"Supports AI Related Tools",
      order1:"feature-order1",
      order2:"feature-order2",
      image:tool,
      description:"Provides compatibility with AI-related tools."
    },
    { 
      key:5,
      title:"Unlimited Transaction Capacity",
      order1:"feature-order2",
      order2:"feature-order1",
      image:transaction,
      description:"Unlimited transaction capacity ensures seamless scalability for blockchain networks."
    }
  ];

  return (
    <div className="features" id={"features"}>
      <div className="header-text">Features</div>
      <Space direction={"vertical"} className="feature-space" size={30}>
        {
          dataSource.map((item)=>(
            <Row key={item.key} justify="center" align="middle" gutter={[12, 12]}>
              <Col sm={12} xs={24} xxl={12} className={item.order1}>
                <div className="text-center">
                  <Image src={item.image} preview={false} className={"feature-img"} />
                </div>
              </Col>
              <Col sm={12} xs={24} xxl={12} className={item.order2}>
                <div className="feature-title">{item.title}</div>
                <p className="feature-desc">{item.description}</p>
              </Col>
            </Row>
          ))
        }
      </Space>
      {/* <div className="gif-img" id={"demo"}>
        <Image
          src={demo}
          preview={false}
          className={"video-icon"}
          onMouseOver={() => setvideoVisible(true)}
        />
        {videoVisible && (
          <iframe
            title="menu"
            className="video"
            src="https://www.youtube.com/embed/rXYaBtjxI5o"
            onMouseOut={() => setvideoVisible(false)}
          ></iframe>
        )}
      </div> */}
    </div>
  );
};

export default Features;