import React from "react";
import { Button, Col, Divider, Image, Row, Space } from "antd";
import approveBadge from "../../assets/signInIcon/approveBadge.webp";
import Icon, { ArrowRightOutlined, UploadOutlined } from "@ant-design/icons";
import { ReactComponent as detailsIcon } from "../../assets/signInIcon/details.svg";
import UserHeader from "./UserHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/user";
import { STEP_STATUS } from "../../constants/stepStatus";
import { stepStatusUpdate } from "../../store/user/reducer";
import "./style.css";
import { ROUTE } from "../../config/route";


export const VerifyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verification = () => {
    dispatch(userActions.updateUser({ step_status: STEP_STATUS.LETS_VERIFY.NUMBER }));
    dispatch(stepStatusUpdate("Lets Verify"))
    navigate(ROUTE.LETS_VERIFIED.URL);
  };
  return (
    <>
      <UserHeader /> {/* Header for user verification */}
      <div className="verify-container">
        <Row
          justify="center"
          align="middle"
          gutter={[0, 24]}
          className="text-center"
        >
          <Col span={24}>
            <Image src={approveBadge} preview={false} />

            <p className="user-header-text">Please verify your account</p>
            <p className="sub-text">It only takes few minutes!</p>
          </Col>

          <Col span={24}>
            <p className="sub-text" style={{ color: "white" }}>
              To complete verification, you will need to :
            </p>
          </Col>
          <Col span={24}>
            <Space direction="vertical" className="verify-button-container">
              <div className="icon-text-container">
                <Icon component={detailsIcon} className="verify-icon" style={{color:'#707070'}}/>{" "}
                <span className="link">Provide some basic details</span>
              </div>
              <Divider className="divider" />
              <div className="icon-text-container">
                <UploadOutlined className="verify-icon" />{" "}
                <span className="link">
                  Upload a photo of a government-issued identification
                </span>
              </div>
            </Space>
          </Col>
          <Col span={24}>
            <Button className="verify-button" onClick={verification}>
              Begin verification <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
