import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import moment from "moment";
import {
  status,
} from "../../../../constants/ConstantVar";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import userSelectors from "../../../../store/user/selectors";
import UploadedDocsModal from "../../profile_components/UploadedDocsModal";
import { userActions } from "../../../../store/user";
import { useParams } from "react-router-dom";
import haveToAPI from "../../../../services/api/user/api";

const KycVerificationCard = () => {
  const user = useAppSelector(userSelectors.getManageUserDetails);
  let { id } = useParams();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [dataLoader, setdataLoader] = useState<boolean>(false);
  const [dataButtonText, setDataButtonText] = useState<string>("");
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const dispatch = useAppDispatch();
  function showModal(buttonType: string) {
    buttonType === "front"
      ? setUploadedImage(user.front_img || "")
      : setUploadedImage(user.back_img || "");
    setPreviewOpen(true);
  }
  const verifyData = React.useCallback(
    (buttonText: string) => {
      setDataButtonText(buttonText);
      setdataLoader(true);

      haveToAPI
        .VerifyDataAndDocsStatus({
          user_id: Number(id),
          application_status:
            buttonText === "Approve-Data" ? "Success" : "Failed",
        })
        .then((res) => {
          setdataLoader(false);
        });
    },
    [id]
  );
  const verifyDocument = React.useCallback(
    (buttonText: string) => {
      setDataButtonText(buttonText);
      setdataLoader(true);

      haveToAPI
        .VerifyDataAndDocsStatus({
          user_id: Number(id),
          document_status: buttonText === "Approve-Docs" ? "Success" : "Failed",
        })
        .then((res) => {
          setdataLoader(false);
        });
    },
    [id]
  );
  useEffect(() => {
    dispatch(userActions.manageUserDetails({ user_id: Number(id) }));
  }, [dispatch, id, dataLoader]);

  return (
    <div className="manage-user-kyc-info">
      {/* Application data */}
      <div className="application-data">
        <p className="profile-text-color text-16">KYC Verification</p>
        <div className="kyc-title-container">
          <div className="text-24">Application Data :</div>
          <Typography.Text
            className={user.application_status?.toLowerCase() + "-color-button"}
            title={user.application_status}
          >
            {user.application_status}
          </Typography.Text>
        </div>
        <Space direction="vertical" size={10}>
          <Typography.Text className="text-16 profile-text-color"></Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            First Name : {user.first_name ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Last Name : {user.last_name ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            DOB : {moment(user.date_of_birth).format("Do MMM, Y") ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Gender : {user.gender ?? "-"}
          </Typography.Text>
          <Space size={20}>
            <Button
              className={
                user.application_status === status.PENDING.STATUS ||
                user.application_status === status.SUCCESS.STATUS
                  ? "approve-button"
                  : "approve-disabled-button"
              }
              disabled={
                user.application_status === status.PENDING.STATUS ? false : true
              }
              icon={<CheckOutlined />}
              onClick={() => verifyData("Approve-Data")}
              loading={dataButtonText === "Approve-Data" && dataLoader}
            >
              Approve
            </Button>
            <Button
              className={
                user.application_status === status.PENDING.STATUS ||
                user.application_status === status.FAILED.STATUS
                  ? "reject-button"
                  : "reject-disabled-button"
              }
              icon={<CloseOutlined />}
              onClick={() => verifyData("Reject-Data")}
              loading={dataButtonText === "Reject-Data" && dataLoader}
              disabled={
                user.application_status === status.PENDING.STATUS ? false : true
              }
            >
              Reject
            </Button>
          </Space>
        </Space>
      </div>
      {/* Identity Document */}
      <hr className="kyc-verify-hr"></hr>
      <div className="identity-doc">
        <p className="texxt-primary-color text-16"> &nbsp;</p>
        <div className="kyc-title-container">
          <div className="texxt-white-color text-24">Identity Document :</div>
          <Typography.Text
            className={user.document_status?.toLowerCase() + "-color-button"}
            title={user.document_status}
          >
            {user.document_status}
          </Typography.Text>
        </div>
        <Space direction="vertical" size={10}>
          <Typography.Text className="text-16 profile-text-color"></Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Document type : {user.identity_proof ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Country : {user.country ?? "-"}
          </Typography.Text>
          <Typography.Text className="text-16 profile-text-color">
            Uploaded docs : &nbsp;
            <Space direction="horizontal">
              <Button
                type="ghost"
                className="kyc-button"
                icon={<EyeOutlined />}
                onClick={() => showModal("front")}
              >
                Front
              </Button>
              <Button
                type="ghost"
                className="kyc-button"
                icon={<EyeOutlined />}
                onClick={() => showModal("back")}
              >
                Back
              </Button>
            </Space>
          </Typography.Text>
          <Typography.Text></Typography.Text>
          <Space size={20}>
            <Button
              className={
                user.document_status === status.PENDING.STATUS ||
                user.document_status === status.SUCCESS.STATUS
                  ? "approve-button"
                  : "approve-disabled-button"
              }
              onClick={() => verifyDocument("Approve-Docs")}
              disabled={
                user.document_status === status.PENDING.STATUS ? false : true
              }
              icon={<CheckOutlined />}
              loading={dataButtonText === "Approve-Docs" && dataLoader}
            >
              Approve
            </Button>
            <Button
              className={
                user.document_status === status.PENDING.STATUS ||
                user.document_status === status.FAILED.STATUS
                  ? "reject-button"
                  : "reject-disabled-button"
              }
              icon={<CloseOutlined />}
              disabled={
                user.document_status === status.PENDING.STATUS ? false : true
              }
              onClick={() => verifyDocument("Reject-Docs")}
              loading={dataButtonText === "Reject-Docs" && dataLoader}
            >
              Reject
            </Button>
          </Space>
        </Space>
      </div>
      <hr className="kyc-identity"></hr>
      <UploadedDocsModal
        previewOpen={previewOpen}
        imageUrl={uploadedImage}
        onClose={() => setPreviewOpen(false)}
      />
    </div>
  );
};

export default KycVerificationCard;
