import { createAction } from "@reduxjs/toolkit";
import { SubscriptionPayload, SubscriptionResponse } from "../../services/api/subscription/types";


export const actionTypes = {
    CreateSubscription: '[Subscription] Create Subscription',
    CreateSubscriptionSuccess: '[Subscription] Create Subscription Success',
    CreateSubscriptionFailure: '[Subscription] Create Subscription Failure'
}

export const subscriptionActions = {
    createSubscription: createAction<SubscriptionPayload>(actionTypes.CreateSubscription),
    createSubscriptionSuccess: createAction<SubscriptionResponse>(actionTypes.CreateSubscriptionSuccess),
    createSubscriptionFailure: createAction<SubscriptionResponse>(actionTypes.CreateSubscriptionFailure),
}