import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {  UserTransactionInterface } from "../../../../services/api/transaction/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  transactionSelectors,
  transactionActions,
} from "../../../../store/transaction";
import { Link, useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import "./style.css";

const RecentTransaction = () => {
  const dispatch = useAppDispatch();
  let { id } = useParams();
  const userTransactions = useAppSelector(transactionSelectors.getUserTransactions);
  const loader = useAppSelector(transactionSelectors.getLoader);
  const [pageSize, setpageSize] = useState<number>(10);
  const paginationProps = {
    showLessItems: true,
    showSizeChanger: true,
    pageSize: pageSize,

    total: userTransactions.count,
    onChange: (pageNumber: number) => {
      dispatch(
        transactionActions.getUserTransactions({
          page: pageNumber,
          pageSize: pageSize,
          user_id: Number(id),
        })
      );
    },
    onShowSizeChange: (current: number, size: number) => {
      setpageSize(size);
      dispatch(
        transactionActions.getUserTransactions({ page: current, pageSize: size ,user_id: Number(id)})
      );
    },
  };
  useEffect(() => {
    dispatch(
      transactionActions.getUserTransactions({
        page: 1,
        pageSize: pageSize,
        user_id: Number(id),
      })
    );
  }, [dispatch, pageSize, id]);
  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  const columns: ColumnsType<UserTransactionInterface> = [
    {
      key: 1,
      title: "Order Id",
      render: (transaction) => {
        return <div className="grey">{"#" + transaction.product_id}</div>;
      },
      sorter: (a, b) => a.product_id.localeCompare(b.product_id),
    },
    {
      key: 2,
      title: "Payment Address",
      className: 'max-width-250',
      render: (transaction) => {
        return <div className="grey">
          {transaction.payment_address? 
                transaction.payment_address==="Null"? "-": transaction.payment_address
                :"-" }
          <span>{<CopyOutlined onClick={() => copyText(transaction.payment_address!)} />} </span>
        </div>;
      },

    },
    {
      key: 3,
      // title: "Token",
      title: "Amount in USD",
      render: (transaction) => {
        return (
          <div className="grey">
            {transaction.fiat_amount}
            <span className="grey">{parseFloat(transaction?.usd_amount).toFixed(2).toUpperCase()}</span>
            <span className="grey"> USD</span>
          </div>
        );
      },
      sorter: (a: UserTransactionInterface, b: UserTransactionInterface) =>
        a.fiat_amount - b.fiat_amount,
    },
    {
      key: 4,
      title: "Coin Amount",
      render: (transaction) => {
        return (
          <div className="grey">
            {transaction.coin_amount}
            <span className="text-primary-color"> {transaction?.coin?.toUpperCase()}</span>
          </div>
        );
      },
      sorter: (a: UserTransactionInterface, b: UserTransactionInterface) =>
        a?.coin_amount-b?.coin_amount,
    },
    {
      key: 5,
      title: "Date-time [UTC]",
      render: (transaction) => {
        return (
          <div className="grey">
            {moment(transaction.created_or_updated_at).format(
              "DD MMM Y ● hh:mm:ss"
            )}
          </div>
        );
      },
      sorter: (a: UserTransactionInterface, b: UserTransactionInterface) =>
        a.created_or_updated_at?.localeCompare(b.created_or_updated_at),
    },
    {
      key: 6,
      title: "Status",
      render: (transaction) => {
        const status = transaction.checkout_session_status === "" ? "pending" : transaction.checkout_session_status;
        const getStatusTag = (status: string) => {
          let color;
          switch (status) {
            case "complete":
              color = "#87d068";
              break;
            case "cancelled":
              color = "#f50";
              break;
            case "Cancelled":
              color = "#f50";
              break;
            case "expired":
              color = "#f50";
              break;
            default:
              color = "#FFC700";
              break;
          }
          return (
            <Tag style={{ backgroundColor: 'transparent', border: `1px solid ${color}`, color: color }}>
              {status.charAt(0).toUpperCase() + status.substring(1)}
            </Tag>
          );
        };
        return (
          <div>
            {getStatusTag(status)}
          </div>
        );
      }
    },
  ];
  return (
    <>
      <div className="profile-text-color text-16">
        All transactions
        <Link to={"/transaction"} className="float-right text-primary-color">
          View all
        </Link>
      </div>

      <br />
      <div className="manage-user-transaction-table">
        <Table
          sticky={true}
          scroll={{ x: true, y: 525 }}
          loading={loader}
          pagination={paginationProps}
          columns={columns}
          dataSource={userTransactions.results}
        />
      </div>
    </>
  );
};

export default RecentTransaction;
