import React, { useEffect, useState } from "react";
import { Avatar, Space, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { getRandomColor } from "../../../constants/getRandomColor";
import { TransactionInterface } from "../../../services/api/transaction/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  transactionActions,
  transactionSelectors,
} from "../../../store/transaction";
import userSelectors from "../../../store/user/selectors";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./style.css";

const AllTransactions = () => {
  const [pageSize, setpageSize] = useState<number>(10);
  const transactions = useAppSelector(transactionSelectors.getTransactions);
  const { is_staff } = useAppSelector(userSelectors.getUserDetails);
  const loader = useAppSelector(transactionSelectors.getLoader);
  const dispatch = useAppDispatch();
  const paginationProps = {
    showLessItems: true,
    showSizeChanger: true,
    pageSize: pageSize,

    total: transactions.count,
    onChange: (pageNumber: number) => {
      dispatch(
        transactionActions.getAllTransactions({
          page: pageNumber,
          pageSize: pageSize,
        })
      );
    },
    onShowSizeChange: (current: number, size: number) => {
      setpageSize(size);
      dispatch(
        transactionActions.getAllTransactions({ page: current, pageSize: size })
      );
    },
  };
  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  useEffect(() => {
    dispatch(
      transactionActions.getAllTransactions({ page: 1, pageSize: pageSize })
    );
  }, [dispatch, pageSize]);

  const customerColumns: ColumnsType<TransactionInterface> = [
    {
      key: 1,
      title: "Name",
      render: (transaction) => {
        return (
          <div>
            <Space direction="horizontal">
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                size="large"
              >
                {transaction?.user?.first_name?.[0]
                  .concat(transaction?.user?.last_name?.[0] ?? "")
                  .toUpperCase()}
              </Avatar>
              <div className="grey text-16">
                {transaction?.user?.first_name.charAt(0).toUpperCase()+transaction.user.first_name.slice(1) +
                  " " +
                  transaction?.user?.last_name}
              </div>
            </Space>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.user?.first_name?.localeCompare(b?.user?.first_name),
    },
    {
      key: 2,
      title: "Purchase Amount",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{transaction?.coin_amount}</span>
            <span className="text-primary-color"> {transaction?.coin?.toUpperCase()}</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.coin_amount - b.coin_amount,
    },
    {
      key: 3,
      title: "Amount in USD",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{parseFloat(transaction?.usd_amount).toFixed(2)}</span>
            <span className="text-primary-color"> USD</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.usd_amount - b.usd_amount,

    },
    {
      key: 4,
      title: "Date-time [UTC]",
      render: (transaction) => {
        return (
          <div className="grey">
            {moment(transaction.created_or_updated_at).format(
              "DD MMM Y ● hh:mm:ss"
            )}
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.created_or_updated_at?.localeCompare(b.created_or_updated_at),
    },
    {
      key: 5,
      title: "Location",
      render: (transaction) => {
        return (
          <div className="text-16 grey">
            {transaction?.user?.country}{" "}
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.user?.country?.localeCompare(b.user?.country),
    },
    {
      key: 6,
      title: "Status",
      render: (transaction) => {
        return (
          // <span className="text-16 grey">{transaction.payment_status.charAt(0).toUpperCase()+ transaction.payment_status.substring(1)}</span>
          <Tag style={{ backgroundColor: 'transparent', border: `1px solid #87d068`, color: "#87d068" }}>
              {transaction.payment_status.charAt(0).toUpperCase()+ transaction.payment_status.substring(1)}
            </Tag>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a?.payment_status.localeCompare(b?.payment_status),
    },
  ];

  const adminColumns: ColumnsType<TransactionInterface> = [
    {
      key: 1,
      title: "Name/Product id",
      render: (transaction) => {
        return (
          <div>
            <Space direction="horizontal">
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                size="large"
              >
                {transaction?.user?.first_name?.[0]
                  .concat(transaction?.user?.last_name?.[0] ?? "")
                  .toUpperCase()}
              </Avatar>
              <Space direction="vertical" size={0}>
                <div className="grey text-16">
                {transaction?.user?.first_name.charAt(0).toUpperCase()+transaction.user.first_name.slice(1) +
                  " " +
                  transaction?.user?.last_name}
                </div>
                <div className="profile-text-color text-12">
                  {"#" + transaction.product_id}
                </div>
              </Space>
            </Space>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.user?.first_name?.localeCompare(b?.user?.first_name),
    },
    {
      key: 2,
      title: "Purchase Amount",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{transaction?.coin_amount}</span>
            <span className="text-primary-color"> {transaction?.coin?.toUpperCase()}</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.coin_amount - b.coin_amount,
    },
    {
      key: 3,
      title: "Coin Amount",
      render: (transaction) => {
        return (
          <div className="grey">
            {transaction.coin_amount * 100000000}
          </div>
        );
      },
      sorter: (a, b) =>
        a.coin_amount! - b.coin_amount!,
    },
    {
      key: 4,
      title: "Amount in USD",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{parseFloat(transaction?.usd_amount).toFixed(2)}</span>
            <span className="text-primary-color"> USD</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.usd_amount - b.usd_amount,

    },
    {
      key: 5,
      title: "Payment Address /Account Address",
      className: 'max-width-200',
      render: (transaction) => {
        return (
          <Space direction="vertical" size={0}>
            <div className="profile-text-color text-12">{transaction.payment_address} &nbsp;
            </div>
            <div className="grey text-12">
              {transaction.account_address? 
                transaction.account_address==="Null"? "-": transaction.account_address
                :"-" }
              <span>{<CopyOutlined onClick={() => copyText(transaction.account_address!)} />} </span></div>
          </Space>
        );
      },
      sorter: (a, b) =>
        a.coin_amount! - b.coin_amount!,
    },
    {
      key: 6,
      title: "Date-time [UTC]",
      render: (transaction) => {
        return (
          <div className="grey">
            {moment(transaction.created_or_updated_at).format(
              "DD MMM Y ● hh:mm:ss"
            )}
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.created_or_updated_at?.localeCompare(b.created_or_updated_at),
    },
    {
      key: 7,
      title: "Location",
      render: (transaction) => {
        return (
          <div className="text-16 grey">
            {transaction?.user?.country}{" "}
          </div>
        );
      },
    },
    {
      key: 8,
      title: "Status",
      render: (transaction) => {
        const status = transaction.payment_status || "pending";
        const getStatusTag = (status: string) => {
          let color;
          switch (status) {
            case "paid":
              color = "#87d068";
              break;
            case "unpaid":
              color = "#f50";
              break;
            default:
              color = "#FFC700";
              break;
          }
          return (
            <Tag style={{ backgroundColor: 'transparent', border: `1px solid ${color}`, color: color }}>
              {status.charAt(0).toUpperCase() + status.substring(1)}
            </Tag>
          );
        };
        return (
          <div>
            {getStatusTag(status)}
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a?.payment_status.localeCompare(b?.payment_status),
    },
  ];

  return (
    <Table
      loading={loader}
      sticky={true}
      scroll={{ x: true, y: 525 }}
      pagination={paginationProps}
      columns={is_staff ? adminColumns : customerColumns}
      dataSource={transactions.results}
    />
  );
};

export default AllTransactions;
