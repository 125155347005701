import React from "react";
import { Image } from "antd";
import logo from "../../assets/haveto.svg";
import "./style.css";
import {  NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/user";
const UserHeader = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="user-header">
        <Image src={logo} preview={false} className="logo-img" />
        {/* Start Navbar section for desktop */}
        <div className="user-navbar">
          <ul className="user-navbar-items">
            <li>
              <a href="#white-paper">White Paper</a>
            </li>
            <li>
            <button
                  className="signin-button"
                  onClick={()=>{dispatch(userActions.signOut())}}
                >
                  Sign Out
                </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
