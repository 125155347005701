import React, { useEffect, useState } from 'react'
import scrollTopRobot from "../../assets/websiteIcon/footer/scrollTop.png";
import {Image} from  'antd';

const ScrollTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });
    }, []);
  return (
    <>
    {showTopBtn && 
        <button
          onClick={() => {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          }}
          className={"scroll-top"}
        >
        <Image src={scrollTopRobot} preview={false}/>
      </button>}
    </>
  )
}

export default ScrollTop