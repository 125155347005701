import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { Card, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  transactionActions,
  transactionSelectors,
} from "../../../store/transaction";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { TransactionInterface } from "../../../services/api/transaction/types";
import moment from "moment";
import { setcurrentSelectedMenu } from "../../../store/ui/reducer";
import userSelectors from "../../../store/user/selectors";
import { PaymentStatusColor } from "../../../constants/ConstantVar";

const TransactionCard = () => {
  const dispatch = useAppDispatch();
  const {is_staff} = useAppSelector(userSelectors.getUserDetails);
  const transactions = useAppSelector(transactionSelectors.getTransactions);
  const loader = useAppSelector(transactionSelectors.getLoader);
  useEffect(() => {
    dispatch(transactionActions.getAllTransactions({page:1,pageSize:5}))
  }, [dispatch,is_staff]);

  const columns: ColumnsType<TransactionInterface> = [
    {
      key: 1,
      title: "Order Id",
      render: (transaction) => {
        return (
          <div className="grey">
            {transaction.product_id}
          </div>
        );
      },
    },
    {
      key: 2,
      title: "Purchase Amount",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{transaction?.coin_amount}</span>
            <span className="text-primary-color"> {transaction?.coin?.toUpperCase()}</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.coin_amount - b.coin_amount,

    },
    {
      key: 3,
      title: "Amount in USD",
      render: (transaction) => {
        return (
          <div>
            <span className="grey">{parseFloat(transaction?.usd_amount).toFixed(2)}</span>
            <span className="text-primary-color"> USD</span>
          </div>
        );
      },
      sorter: (a: TransactionInterface, b: TransactionInterface) =>
        a.usd_amount - b.usd_amount,

    },
    {
      key: 4,
      title: "Date-time [UTC]",
      render: (transaction) => {
        return (
          <div className="grey">
            {moment(transaction.created_or_updated_at).format(
              "DD MMM Y ● hh:mm:ss"
            )}
          </div>
        );
      },
    },
    {
      key: 5,
      title: "Status",
      render: (transaction) => {
        const status = transaction.payment_status || "pending";
        const getStatusTag = (status: string) => {
          let color;
          switch (status) {
            case "paid":
              color = "#87d068";
              break;
            case "unpaid":
              color = "#f50";
              break;
            default:
              color = "#FFC700";
              break;
          }
          return (
            <Tag style={{ backgroundColor: 'transparent', border: `1px solid ${color}`, color: color }}>
              {status.charAt(0).toUpperCase() + status.substring(1)}
            </Tag>
          );
        };
        return (
          <div>
            {getStatusTag(status)}
          </div>
        );
      },
    }


  ];

  return (
    <Card
      title="Recent transactions"
      extra={
        <Link to={"transaction"} onClick={()=>dispatch(setcurrentSelectedMenu('/transaction'))}>
          View all
          <RightOutlined />
        </Link>
      }
      className={"table-card"}
    >
      <div className="transaction-card-table">
        <Table
          sticky={true}
          scroll={{ x: true }}
          loading={loader}
          pagination={false}
          columns={columns}
          dataSource={transactions.results}
        />
      </div>
    </Card>
  );
};

export default TransactionCard;
