import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import {
  AllUsersPayload,
  ChangePasswordPayload,
  DeactivateActivateUserPayload,
  EmailVerificationPayload,
  ManageUserPayload,
  ResendMailPayload,
  SignInApiInterface,
  SignInPayload,
  SignInResources,
  SignUpPayload,
  TokenInterface,
  UpdateUserPayload,
  VerifyDataAndDocumentPayload,
} from "./types";
import jwt_decode from "jwt-decode";

const SIGNIN_ENDPOINTS: SignInResources = {
  // create signin
  SIGN_IN: {
    URL: API.SIGN_IN.URL,
    METHOD: API.SIGN_IN.METHOD,
  },
  SIGN_UP: {
    URL: API.SIGN_UP.URL,
    METHOD: API.SIGN_UP.METHOD,
  },
  RESEND_MAIL: {
    URL: API.RESEND_MAIL.URL,
    METHOD: API.RESEND_MAIL.METHOD,
  },

  UPDATE_USER: {
    URL: API.UPDATE_USER.URL,
    METHOD: API.UPDATE_USER.METHOD,
  },
  GET_ALL_USERS:{
    URL: API.GET_ALL_USERS.URL,
    METHOD: API.GET_ALL_USERS.METHOD,
  },
  GET_USER_COUNT:{
    URL: API.GET_USER_COUNT.URL,
    METHOD: API.GET_USER_COUNT.METHOD,
  },
  GET_USER_DETAILS: {
    URL: API.GET_USER_DETAILS.URL,
    METHOD: API.GET_USER_DETAILS.METHOD,
  },
  VERIFY_DATA_DOCS_STATUS: {
    URL: API.VERIFY_DATA_DOCS_STATUS.URL,
    METHOD: API.VERIFY_DATA_DOCS_STATUS.METHOD,
  },
  ACTIVE_AND_DE_ACTIVE_USER: {
    URL: API.ACTIVE_AND_DE_ACTIVE_USER.URL,
    METHOD: API.ACTIVE_AND_DE_ACTIVE_USER.METHOD,
  },
  EMAIL_VERIFICATION: {
    URL: API.EMAIL_VERIFICATION.URL,
    METHOD: API.EMAIL_VERIFICATION.METHOD,
  },
  GET_STEP_STATUS: {
    URL: API.STEP_STATUS.URL,
    METHOD: API.STEP_STATUS.METHOD,
  },
  CHANGE_PASSWORD: {
    URL: API.CHANGE_PASSWORD.URL,
    METHOD: API.CHANGE_PASSWORD.METHOD,
  },
};

const haveToAPI: SignInApiInterface = {
  signIn: async (data: SignInPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.SIGN_IN,

      options: {
        data: {
          email: data.email,
          password: data.password,
        },
      },
    });

    if (error) {
      return { error, user: null };
    }
    else{
      return {
        user: response,
        error: null,
      };
    }
  },
  signUp: async (data: SignUpPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.SIGN_UP,

      options: {
        data: {
          email: data.email,
          password: data.password,
          is_notified_email: data.is_notified_email,
        },
      },
    });

    if (error) {
      return { error, user: null };
    }
    else{
      return {
        user: response,
        error: null,
      };
    }
  },
  resendEmailVerificationMail: async (data: ResendMailPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.RESEND_MAIL,

      options: {
        queryParams:{
          email: data.email,
        },
      },
    });

    if (error) {
      return { error, resendMail: null };
    }
    else{
      return {
        resendMail: response,
        error: null,
      };
    }
  },
  updateUser: async (payload: UpdateUserPayload) => {
    const decodedToken: TokenInterface = jwt_decode(localStorage.getItem('authToken')!);
    var formData = new FormData();
    
    formData.append('step_status',String(payload?.step_status));
    if(payload.first_name && payload.last_name) {
      formData.append('first_name',payload?.first_name ?? "");
      formData.append('last_name',payload?.last_name ?? "");
      formData.append('gender',payload?.gender ?? "");
      formData.append('date_of_birth',payload?.date_of_birth ?? "");
    }
    if(payload.front_img)
    {
      const front_img_obj:any = payload?.front_img
      const back_img_obj:any = payload?.back_img
      formData.append("front_img", front_img_obj?.originFileObj?? "");
      formData.append("back_img", back_img_obj?.originFileObj);
      formData.append('country',payload?.country ?? "");
      formData.append('identity_proof',payload?.identity_proof ?? "");
    }
    
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.UPDATE_USER,
      headers: {
        "Content-Type":payload.front_img? "multipart/form-data" : "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      options: {
        data: formData,
        pathVars:{user_id:decodedToken.user_id}
      },
    });
    if (error) {
      return { error, user: null };
    }
    else {
      return {
        user: response,
        error: null,
      };
    }
  },
  getAllUserDetails: async (payload:AllUsersPayload) => {
    // Get all users
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.GET_ALL_USERS,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options:{
        queryParams:(payload.page && payload.pageSize) ?{
          page:payload.page ?? null,
          page_size:payload.pageSize ?? null,
          kyc_status:payload.kyc_status ?? "",
          search:payload.search ?? ""
        }
        : null
      }
    });
    if (error) {
      return { error, allUser: null };
    }
    else{
      return {
        allUser: response,
        error: null,
      };
    }
  },
  getUserCount: async () => {
    // Get all users count,pending user count 
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.GET_USER_COUNT,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      
    });
    if (error) {
      throw error.message;
    }
    else{
      return {
        userCount: response,
        error: null,
      };
    }
  },
  getUserDetails: async () => {
    const decodedToken: TokenInterface = jwt_decode(localStorage.getItem('authToken')!);
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.GET_USER_DETAILS,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        pathVars:{user_id:decodedToken.user_id}
      },
    });
    if (error) {
      return { error, user: null };
    }
    else{
      return {
        user: response,
        error: null,
      };
    }
  },
  getManageUserDetails: async (payload:ManageUserPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.GET_USER_DETAILS,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        pathVars:{user_id:payload.user_id}
      },
    });
    if (error) {
      return { error, user: null };
    }
    else{
      return {
        user: response,
        error: null,
      };
    }
  },
  VerifyDataAndDocsStatus: async (payload:VerifyDataAndDocumentPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.VERIFY_DATA_DOCS_STATUS,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        pathVars:{user_id:payload.user_id},
        data:{
          application_status:payload.application_status,
          document_status:payload.document_status
        }
      },
    });
    if (error) {
      return { error, updateStatus: null };
    }
    else{
      return {
        updateStatus: response,
        error: null,
      };
    }
  },
  DeactivateActivateUser: async (payload:DeactivateActivateUserPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.ACTIVE_AND_DE_ACTIVE_USER,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        pathVars:{user_id:payload.user_id},
        data:{
          is_active :payload.active_status
        }
      },
    });
    if (error) {
      return { error, activeStatus: null };
    }
    else{
      return {
        activeStatus: response,
        error: null,
      };
    }
  },
  getStepStatus: async () => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.GET_STEP_STATUS,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });
    if (error) {
      return { error, stepStatus: null };
    }
    return {
      stepStatus: response.stepStatus,
      error: null,
    };
  },
  emailVerification: async (data: EmailVerificationPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.EMAIL_VERIFICATION,

      options: {
        data: {
          email: data.email,
          session_token:data.session_token
        },
      },
    });
    if (error) {
      return { error, user: null };
    }
    else{
      return {
        user: response.message,
        error: null,
      };
    }
  },
  changePassword: async (payload: ChangePasswordPayload) => {
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.CHANGE_PASSWORD,
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
      options: {
        data: payload
      },
    });
    if (error) {
      return { error, change_password: null };
    }
    else{
      return {
        change_password: response,
        error: null,
      };
    }
  },
  updateProfileDetails: async (payload:UpdateUserPayload) => {
    const decodedToken: TokenInterface = jwt_decode(localStorage.getItem('authToken')!);
    var formData = new FormData();
    formData.append('first_name',payload?.first_name ?? "");
    formData.append('last_name',payload?.last_name ?? "");
    formData.append('gender',payload?.gender ?? "");
    formData.append('date_of_birth',payload?.date_of_birth ?? "");

    formData.append('country',payload?.country ?? "");
    formData.append('identity_proof',payload?.identity_proof ?? "");
    if(payload.front_img){
      const front_img_obj:any = payload?.front_img
      formData.append('front_img',front_img_obj.originFileObj);
    }
    (payload.application_status) && formData.append('application_status',payload.application_status);
    (payload.document_status) && formData.append('document_status',payload.document_status);
    if(payload.back_img){
      const back_img_obj:any = payload?.back_img
      formData.append('back_img',back_img_obj.originFileObj);
    }
      
    const { response, error } = await haveToApiService({
      resource: SIGNIN_ENDPOINTS.UPDATE_USER,
      headers: {
        "Content-Type":payload.front_img? "multipart/form-data" : "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      options: {
        data: formData,
        pathVars:{user_id:decodedToken.user_id}
      },
    });
    if (error) {
      return { error, user: null };
    }
    else {
      return {
        user: response,
        error: null,
      };
    }
  }
};

export default haveToAPI;

export { SIGNIN_ENDPOINTS };
