import { createSlice } from "@reduxjs/toolkit";
import { SubscriptionInterface } from "../../services/api/subscription/types";
import { HAVETOApiError } from "../../services/api/types";
import { subscriptionActions } from "./actions";


export interface SubscriptionState {
    subscription: SubscriptionInterface[];
    loaders: boolean;
    error: HAVETOApiError | null;
    
}

const initialState: SubscriptionState = {
    subscription: [],
    loaders: false,
    error: null,
};

const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers:{
        // reducers property both creates an action creator function and responds to that action in the slice reducer
    },
    extraReducers: {
        // The extraReducers allows you to respond to an action in your slice reducer but does not create an action creator function
        [subscriptionActions.createSubscription.type]: (state) => {
            state.loaders = true;
        },
        [subscriptionActions.createSubscriptionSuccess.type]: (state,action) => {
            state.loaders = false;
            state.subscription = action.payload.subscription;
        },
        [subscriptionActions.createSubscriptionFailure.type]: (state, action) => {
            state.loaders = false;
            state.error = action.payload.error;
        },
    }
});

export default subscriptionSlice.reducer;