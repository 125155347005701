import { Method } from "axios";
interface APIInterface {
  [key: string]: {
    URL: string;
    METHOD: Method;
  };
}
const AUTH_URL = "auth/user/";
export const API: APIInterface = {
  // Subscription API
  CREATE_SUBSCRIPTION: { URL: "subscribe/", METHOD: "POST" },

  // User API
  SIGN_IN: { URL: AUTH_URL+"login/", METHOD: "POST" },
  SIGN_UP: { URL: AUTH_URL+"register/", METHOD: "POST" },
  RESEND_MAIL: { URL: AUTH_URL+"resend-mail/", METHOD: "GET" },
  UPDATE_USER: { URL: AUTH_URL+":user_id/", METHOD: "PATCH" },
  GET_ALL_USERS: {URL:AUTH_URL,METHOD: "GET"},
  GET_USER_COUNT: {URL:AUTH_URL+"count/",METHOD: "GET"},
  GET_USER_DETAILS: { URL: AUTH_URL+":user_id/", METHOD: "GET" },
  VERIFY_DATA_DOCS_STATUS: { URL: AUTH_URL+":user_id/update-status/", METHOD: "PATCH" },
  ACTIVE_AND_DE_ACTIVE_USER: { URL: AUTH_URL+":user_id/activate-account/", METHOD: "PATCH" },
  EMAIL_VERIFICATION: { URL: AUTH_URL+"email/verify/", METHOD: "POST" },
  STEP_STATUS: { URL: AUTH_URL+"step-status/", METHOD: "GET" },
  USER_WALLET: { URL: AUTH_URL+":user_id/wallet/", METHOD: "GET" },
  TOKEN_CONVERSION: { URL: AUTH_URL+"convert-crypto/", METHOD: "GET" },
  UPDATE_TOKEN_CONVERSION: { URL: AUTH_URL+"convert-crypto/:id/", METHOD: "PATCH" },
  GET_USER_TRANSACTIONS: { URL: AUTH_URL+"my-payments/", METHOD: "GET" },
  GET_ALL_TRANSACTIONS: { URL: AUTH_URL+"payments/", METHOD: "GET" },
  SALES_HAVETO_COIN_CHART: { URL: AUTH_URL+"sales/", METHOD: "GET" },
  CHANGE_PASSWORD: { URL: AUTH_URL+"change-password/", METHOD: "PATCH" },
  TOTAL_SOLD_HTC_COIN: { URL: AUTH_URL+"total_sold_htc/", METHOD: "GET" },
  TOTAL_SOLD_USD: { URL: AUTH_URL+"total_sold_usd/", METHOD: "GET" },

  //  ********  Make payment with  Now payments  *********  

  GET_PAYMENT_CopperX_LINK : { URL: AUTH_URL+"payment-intent/", METHOD: "POST" },
  GET_PAYMENT_COINREMITTER_LINK : { URL: "coinremitter/payment-intent", METHOD: "POST" },
  GET_CRYPTO_COIN : { URL: AUTH_URL+"coins/", METHOD: "GET" },



};
