import React from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { spaceGenerator } from "../../../constants/ConstantVar";
import { ChangePasswordPayload } from "../../../services/api/user/types";
import { useAppDispatch } from "../../../store/hooks";
import { userActions } from "../../../store/user";

interface PasswordInterface {
  label: string;
  name: string;
  space: number;
}
const PasswordInpput = ({ name, label, space }: PasswordInterface) => {
  return (
    <Space key={name}>
      <Typography.Text className="text-16 change-password-label">
        {spaceGenerator(space)} {label} :
      </Typography.Text>
      <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: label + " field is required!",
          },
        ]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? (
              <EyeInvisibleOutlined style={{ color: "#707070" }} />
            ) : (
              <EyeOutlined style={{ color: "#707070" }} />
            )
          }
          type="password"
          className="change-password-input"
          placeholder={"Type your " + label.toLowerCase()}
        ></Input.Password>
      </Form.Item>
    </Space>
  );
};
const ChangePassword = () => {
  const [signForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const submitChangePassword = (values: ChangePasswordPayload) => {
    if (values.new_password === values.confirm_password) {
      dispatch(
        userActions.changePassword({
          old_password: values.old_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        })
      );
    } else {
      notification.error({
        message: "New password and Confirm password does not match.",
      });
    }
  };
  return (
    <div className="change-password">
      <Collapse
        expandIconPosition={"end"}
        expandIcon={(props) => {
          return props.isActive ? <UpOutlined /> : <DownOutlined />;
        }}
        className="border-none"
        defaultActiveKey={"0"}
      >
        <Collapse.Panel
          header={
            <div className="text-24">Change password</div>
          }
          key="1"
          className="border-none"
        >
          <div className="change-pass-container">
            <Form
              form={signForm}
              requiredMark={false}
              onFinish={submitChangePassword}
            >
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <PasswordInpput
                    name="old_password"
                    label="Old password"
                    space={8}
                  />
                </Col>
                <Col span={24}>
                  <PasswordInpput
                    name="new_password"
                    label="New password"
                    space={6}
                  />
                </Col>
                <Col span={24}>
                  <PasswordInpput
                    name="confirm_password"
                    label="Confirm password"
                    space={0}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="submit-button change-password-button"
                    >
                      Change Password
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ChangePassword;
