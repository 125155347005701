import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Steps } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { userActions } from "../../store/user";
import ApplicationDataForm from "./ApplicationDataForm";
import IdentityDocument from "./IdentityDocumentForm";
import userSelectors from "../../store/user/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import UserHeader from "./UserHeader";
import { useNavigate } from "react-router-dom";
import { STEP_STATUS } from "../../constants/stepStatus";
import { stepStatusUpdate } from "../../store/user/reducer";
import { ROUTE } from "../../config/route";

interface ApplicationDataInterface {
  firstname?: string;
  lastname?: string;
  dateOfBirth?: string;
  gender?: string;
  country?: string;
  identity_proof?: string;
  front_img?: string;
  back_img?: string;
}
const ApplicationData = () => {
  const stepStatus: string | null = useAppSelector(
    userSelectors.stepStatusChange
  );
  const [currentStep, setCurrentStep] = useState<number>(stepStatus === "Identity Document" ? 1 : 0);
  const [appForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(userActions.getUserDetails());
  }, [dispatch]);

  const getUser = useAppSelector(userSelectors.getUserDetails);
  const { first_name, last_name, date_of_birth, gender }: any = getUser;
  appForm.setFieldsValue({
    firstname: first_name,
    lastname: last_name,
    dateOfBirth: date_of_birth && moment(date_of_birth, "YYYY-MM-DD"),
    gender: gender,
  });
  const steps = [
    {
      title: "data",
      content: "Applicant Data",
    },
    {
      title: "document",
      content: "Identity Document",
    },
  ];
  const back = () => {
    setCurrentStep(currentStep - 1);
  };
  const onFinish = (values: ApplicationDataInterface) => {
    dispatch(
      userActions.updateUser({
        first_name: values.firstname,
        last_name: values.lastname,
        date_of_birth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
        gender: values.gender,
        front_img: values.front_img,
        back_img: values.back_img,
        country: values.country,
        identity_proof: values.identity_proof,
        step_status:
          currentStep === 0
            ? STEP_STATUS.IDENTITY_DOCUMENT.NUMBER
            : STEP_STATUS.THANK_YOU.NUMBER, // 3 if step page is application data else identity docs
      })
    );

    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      dispatch(stepStatusUpdate("Identity Document"));
    } else {
      dispatch(stepStatusUpdate("Thankyou"));
      navigate(ROUTE.THANK_YOU.URL);
    }
  };
  return (
    <>
      <UserHeader /> {/* Header */}
      <div className="verify-container">
        <div className="step-container">
          <Steps
            size={window.screen.width <= 480 ? "small" : "default"}
            current={currentStep}
            responsive={false}
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.content} />
            ))}
          </Steps>
        </div>

        <div className="app-data-container">
          <Form form={appForm} layout="vertical" onFinish={onFinish}>
            <Row gutter={[0, 20]} justify={"center"}>
              <Col span={24}>
                <p className="user-header-text text-center">
                  {steps[currentStep].content}
                </p>
              </Col>
              <Col>
                {currentStep === 0 ? (
                  <ApplicationDataForm />
                ) : (
                  <IdentityDocument formdata={appForm} />
                )}
              </Col>
              <Col span={24} className={"text-center"}>
                <Space direction="horizontal" size={30}>
                  <Button
                    className="back-button"
                    onClick={back}
                    disabled={currentStep < steps.length - 1}
                  >
                    <LeftOutlined />
                    Back
                  </Button>
                  <Button
                    className="next-button bg-primary-color"
                    onClick={appForm.submit}
                  >
                    {currentStep === 1 ? "Done" : "Next"}
                    <RightOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ApplicationData;
