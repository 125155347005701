import React, { useEffect } from "react";
import Website from "./components/Website";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import userSelectors from "./store/user/selectors";
import VerifyEmail from "./components/User/VerifyEmail";
import { userActions } from "./store/user";
import {
  dashboardAdminRoute,
  dashboardUserRoute,
  replaceRoute,
  ROUTE,
  stepStatusRoute,
} from "./config/route";
import ResetPassword from "./components/User/ResetPassword";
const App = () => {
  const isAuthenticate = useAppSelector(userSelectors.getIsAutheticated);
  const dispatch = useAppDispatch();
  const {is_staff} =useAppSelector(userSelectors.getUserDetails)
  useEffect(() => {
    dispatch(userActions.getStepStatus());
  });
  const stepStatus: string | null = useAppSelector(
    userSelectors.stepStatusChange
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          {isAuthenticate && stepStatus ? (
            stepStatus !== "Dashboard" ? (
              <>
                {stepStatusRoute}
                <Route
                  path={"*"}
                  element={<Navigate to={replaceRoute(stepStatus)} replace />}
                />
              </>
            ) : (
              (is_staff !== undefined && is_staff )
                ? dashboardAdminRoute
                : dashboardUserRoute
            )
          ) : (
            <>
              <Route path={"/"} element={<Website />} />
              <Route path={ROUTE.VERIFY_EMAIL.URL} element={<VerifyEmail />} />

              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path={"*"} element={<Navigate to={"/"} replace />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
