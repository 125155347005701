import React, { FC, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Image,
  notification,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import googleIcon from "../../assets/signInIcon/google.webp";
import appleIcon from "../../assets/signInIcon/apple.webp";
import userSelectors from "../../store/user/selectors";
import CheckMailForm from "./CheckMailForm";
import { userActions } from "../../store/user";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface singUpInterface {
  email: `${string|number}@${string}.${string}`;
  password: string;
  confirm_password: string;
  is_notified_email: boolean;
}
const SignUpForm: FC = () => {
  const dispatch = useAppDispatch();
  const signUpModalVisible = useAppSelector(userSelectors.signUpModal);
  const loader = useAppSelector(userSelectors.getLoader);

  const [email, setemail] = useState("");
  const [signUpForm] = Form.useForm();
  const onFinish = (values: singUpInterface) => {
    setemail(values.email);
    if (values.password === values.confirm_password) {
      dispatch(
        userActions.signUp({
          email: values.email,
          password: values.password,
          is_notified_email: values.is_notified_email,
        })
      );

    } else {
      notification.error({
        message: "Password and confirm password must be same !!",
      });
      signUpForm.resetFields()
    }
  };
  return (
    <>
      <CheckMailForm email={email} />
      {/* <SignUpForm /> */}
      <Modal
      transitionName=""
        title="Sign Up to HaveTo"
        visible={signUpModalVisible}
        centered={true}
        onCancel={() => {
          dispatch(userActions.signUpModal({ visible: false }));
          signUpForm.resetFields();
        }}
        footer={null}
      >
        <Form form={signUpForm} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[0, 15]}>
            <Col span={24}>
              <Space direction="vertical">
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email field is required!" },
                    { type: "email", message: "Invaid email id!" },
                  ]}
                >
                  <Input
                    type="email"
                    className="email-input"
                    placeholder="Email address"
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Password field is required!",
                    },
                    { min:8, message: "Password must be minimum 8 characters!" }
                  ]}
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? (
                        <EyeInvisibleOutlined style={{ color: "#707070" }} />
                      ) : (
                        <EyeOutlined style={{ color: "#707070" }} />
                      )
                    }
                    type="password"
                    className="email-input"
                    placeholder="Password"
                  ></Input.Password>
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Confirm Password field is required!",
                    },
                    { min:8, message: "Confirm Password must be minimum 8 characters!" }
                  ]}
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? (
                        <EyeInvisibleOutlined style={{ color: "#707070" }} />
                      ) : (
                        <EyeOutlined style={{ color: "#707070" }} />
                      )
                    }
                    type="password"
                    className="email-input"
                    placeholder="Confirm Password"
                  ></Input.Password>
                </Form.Item>

                <Form.Item name="is_notified_email">
                  <Checkbox
                    className="checkbox-btn"
                    defaultChecked
                    onChange={(value) =>
                      signUpForm.setFieldsValue({
                        is_notified_email: value.target.checked,
                      })
                    }
                  >
                    
                    {" "}
                    I agree to receiving updates about HaveTo via email
                  </Checkbox>
                </Form.Item>
              </Space>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button className="submit-button" loading={loader} onClick={signUpForm.submit} htmlType={"submit"}>
                  Continue with email
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <p className="continue-text">
                Already have an account?{" "}
                <span
                  className="primary-color"
                  onClick={() => {
                    dispatch(userActions.signUpModal({ visible: false }));
                    dispatch(userActions.signInModal({ visible: true }));
                  }}
                >
                  Sign in
                </span>
              </p>
            </Col>
            {/* <Col span={24}>
              <hr className="or-text" />
            </Col>
            <Col span={24}>
              <p className="continue-text">Continue with</p>
            </Col>
            <Col span={24}>
              <div className="text-center">
                <Space direction="horizontal" size={30}>
                  <Image
                    src={googleIcon}
                    preview={false}
                    style={{ cursor: "pointer" }}
                  />
                  <Image
                    src={appleIcon}
                    preview={false}
                    style={{ cursor: "pointer" }}
                  />
                </Space>
              </div>
            </Col> */}
            <Col span={24}>
              <p className="continue-text">
                By continuing you agree to haveto.com's <br />
                <span className="primary-color">User Agreement</span>,{" "}
                <span className="primary-color">Wallet Services Terms</span> and{" "}
                <span className="primary-color">Privacy policy</span>
              </p>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SignUpForm;
