import React, { useEffect } from "react";
import { Skeleton, Image, Space, Button } from "antd";
import hImg from "../../../assets/user-dashboard/h.png";
import convertImg from "../../../assets/user-dashboard/transfer.png";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userWalletAction, userWalletSelectors } from "../../../store/wallet";
import { ShoppingCartOutlined } from "@ant-design/icons";
import userSelectors from "../../../store/user/selectors";
import { formatNumber } from "../../../constants/ConstantVar";
import BuyHavetoCoinModal from "../../Payment/BuyHavetoCoinModal";

const AdminTokenBalanceCard = () => {
  const totalSoldHTC = useAppSelector(userWalletSelectors.getTotalSoldHTC);
  const totalSoldUSD = useAppSelector(userWalletSelectors.getTotalSoldUSD);
  
  return (
    <>
      <div className="token-balance" >
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Token Sold</div>
          <div className="text-32w">
            {formatNumber(String(totalSoldHTC.toFixed(2)))}
            <sub className="sub-script-text">HaveTo</sub>
          </div>
        </Space>
      </div>
      <div className="equivalent_usd">
        <Image src={convertImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Equivalent value</div>
          <div className="text-32w">
          {formatNumber(String(totalSoldUSD.toFixed(2)))}
            <sub className="sub-script-text">USD</sub>
          </div>
        </Space>
      </div>
    </>
  );
};

const UserTokenBalanceCard = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const { total_paid_usd } = useAppSelector(
    userWalletSelectors.fetchuserBalance
  );
  return (
    <>
      <div className="token-balance">
        <Image src={hImg} className="h-img" preview={false} />
        <Space direction="vertical" size={0}>
          <div className="text-16w">Balance in USD</div>
          <div className="text-32w">
            {parseFloat(total_paid_usd!).toFixed(2)}
            <sub className="sub-script-text">USD</sub>
          </div>
        </Space>
      </div>
      <Button className="token-balance-btn" icon={<ShoppingCartOutlined />} onClick={()=>setModalVisible(true)}>
        Buy HaveTo Token
      </Button>
      <BuyHavetoCoinModal visible={modalVisible} onClose={()=>setModalVisible(false)}/>
    </>
  );
};

const TokenBalanceCard = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelectors.getUserDetails);
  const loader: boolean = useAppSelector(userWalletSelectors.getLoader);
  useEffect(() => {
    if (user.is_staff && user.is_staff !== undefined) {
      dispatch(userWalletAction.totalSoldHTC());
      dispatch(userWalletAction.totalSoldUSD());
    } else if (!user.is_staff && user.is_staff !== undefined) {
      dispatch(userWalletAction.userWallet());
      dispatch(userWalletAction.totalSoldUSD());
    } else {
      return;
    }
  }, [dispatch, user.is_staff]);

  return (
    <div className="token-balance-card" >
      {loader ? (
        <Skeleton active avatar paragraph={{ rows: 4 }} />
      ) : user.is_staff && user.is_staff !== undefined ? (
        <AdminTokenBalanceCard />
      ) : !user.is_staff && user.is_staff !== undefined ? (
          <UserTokenBalanceCard />
      ) : (
            <Skeleton active avatar paragraph={{ rows: 4 }} />
          )}
    </div>
  );
};

export default TokenBalanceCard;
