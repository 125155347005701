import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Row, Image } from "antd";
import UserHeader from "./UserHeader";
import qrCode from "../../assets/websiteIcon/QR.png";
import {
  ArrowRightOutlined,
  CheckOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { COPIED_URL } from "../../constants/ConstantVar";
import { ROUTE } from "../../config/route";

const LetsVerified = () => {
  //  screen width dynamically withput reloading
  const [screen, setScreen] = useState<any>(getWindowSize());
  function getWindowSize() {
    const { innerWidth, innerHeight }: Window = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setScreen(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [copiedText, setcopiedText] = useState<boolean>(false);
  const [agreeChecked, setAgreeChecked] = useState<boolean>(true);
  const navigate = useNavigate();

  return (
    <>
      <UserHeader /> {/* Header for user verification */}
      <div className="verify-container">
        <Row
          justify="center"
          align="middle"
          gutter={[0, 24]}
          className="text-center"
        >
          <Col span={24}>
            <p className="user-header-text">Let's get you verified</p>
          </Col>

          <Col span={24}>
            <p className="sub-text">
              Before you start, please prepare your identity document and make
              sure it is valid.&nbsp;
              {screen.innerWidth >= 768 && <br />}
              We also require you to agree to our processing of your personal
              data:
            </p>
          </Col>

          <Col span={24}>
            <Checkbox
              className="checkBox"
              defaultChecked
              onChange={(val) => setAgreeChecked(val.target.checked)}
            >
              <p className=" sub-text">
                I agree to the processing of my personal data, as described in
                &nbsp;
                {screen.innerWidth >= 550 && <br />}
                <span className="sub-textp">
                  the consent to Personal data processing
                </span>
              </p>
            </Checkbox>
          </Col>
          <Col span={24}>
            <Button
              className="verify-button"
              disabled={agreeChecked ? false : true}
              onClick={() => {
                navigate(ROUTE.APPLICATION_DATA.URL);
              }}
            >
              Continue <ArrowRightOutlined />
            </Button>
          </Col>
          <Col span={24}>
            <hr className="user-or-text" />
          </Col>
          <Col span={24}>
            <p className="sub-text" style={{fontWeight:'bold'}}>Start via mobile</p>
          </Col>
          <Col span={24}>
            <Image src={qrCode} className={"qrcode-img"} preview={false} />
          </Col>
          <Col span={24}>
            <p className="sub-text text-white-color">
              Scan QR code and follow the link or{" "}
              <Button
                className="copy-button sub-text"
                type="text"
                icon={copiedText ? <CheckOutlined /> : <CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(COPIED_URL);
                  setcopiedText(true);
                }}
              >
                {" "}
                {copiedText ? "Copied" : "Copy link"}
              </Button>
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LetsVerified;
