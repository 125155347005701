import subscription from './subscription/api';
import user from './user/api';
import wallet from './wallet/api'
import transaction from './transaction/api'
import now_payment from './now_payments/api'

export interface HAVETOApi {
    subscription: typeof subscription;
    user: typeof user;
    wallet: typeof wallet;
    transaction: typeof transaction;
    now_payment:typeof now_payment;
}

const api: HAVETOApi = {
    subscription,
    user,
    wallet,
    transaction,
    now_payment
};

export default api;