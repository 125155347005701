import React, { useEffect, useState } from "react";
import { Col, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userActions } from "../../store/user";
import userSelectors from "../../store/user/selectors";
import UserHeader from "./UserHeader";
import { useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.css";

const VerifyEmail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const email: string = searchParams.get("email") ?? "";
  const token: string = searchParams.get("session_token") ?? "";
  const error: string = useAppSelector(userSelectors.emailVerifyMessage);
  const [timer, settimer] = useState(5)
  useEffect(() => {
    dispatch(
      userActions.emailVerification({
        email: email,
        session_token: token,
      })
    );
    dispatch(
      userActions.signInModal({
        visible: true,
      })
      );
    setTimeout(() => {
      settimer(timer-1)
    }, 1000);

    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [dispatch, email, token, navigate,timer]);
  return (
    <>
      <UserHeader /> {/* Header for user verification */}
      <div className="verify-container">
        <Row
          justify="center"
          align="middle"
          gutter={[0, 24]}
          className="text-center"
        >
          <Col span={24}>
            <Space direction="vertical" className="verify-button-container">
              <span className="user-header-text">Email verification </span>
              <hr className="text-white-color" />
              <span className="link">
                {error ? error : "Email id and session token can't be empty !! "}
              </span>
              <span className="link">
                <LoadingOutlined /> &nbsp;&nbsp;Redirecting to sign in page in {timer} second .....
              </span>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default VerifyEmail;
