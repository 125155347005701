import React, { FC } from "react";
import { Col, Modal, Row, Space, Image, Button } from "antd";
import gmail from "../../assets/signInIcon/gmail.webp";
import outlook from "../../assets/signInIcon/outlook.webp";
import yahoo from "../../assets/signInIcon/yahoo.webp";
import userSelectors from "../../store/user/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { userActions } from "../../store/user";
import { GMAIL_LINK } from "../../constants/ConstantVar";

interface CheckMailModalProps {
  email: string;
}
const CheckMailForm: FC<CheckMailModalProps> = ({ email }) => {
  const dispatch = useAppDispatch();
  const checkMailModalVisible = useAppSelector(userSelectors.checkMailModal);
  return (
    <Modal
      title="Check your email"
      visible={checkMailModalVisible}
      centered={true}
      onCancel={() => {
        dispatch(userActions.checkMailModal({ visible: false }));
      }}
      footer={null}
    >
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <p className="email-desc">An email is sent to {email}</p>
        </Col>
        <Col span={24}>
          <div className="text-center">
            <Space direction="vertical" size={0}>
              <Space direction="horizontal" size={10}>
                <Image className="icon" src={gmail} preview={false} />
                <a
                  href={GMAIL_LINK}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <p className="icon-desc">Open in gmail</p>
                </a>
              </Space>
              <Space direction="horizontal" size={10}>
                <Image className="icon" src={yahoo} preview={false} />
                <p className="icon-desc">Open in yahoo</p>
              </Space>
              <Space direction="horizontal" size={10}>
                <Image className="icon" src={outlook} preview={false} />
                <p className="icon-desc">Open in outlook</p>
              </Space>
            </Space>
          </div>
        </Col>
        <Col span={24}>
          <p className="continue-text">Didn't receive an email ??</p>
        </Col>
        <Col span={24}>
          <Button className="submit-button" onClick={() => {
            dispatch(userActions.resendEmailVerificationMail({
              email: email
            }))
          }}>
            Send again
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
    </Modal>
  );
};

export default CheckMailForm;
