import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Image,
  Typography,
  notification,
} from "antd";
import React, { FC, useState } from "react";
import googleIcon from "../../assets/signInIcon/google.webp";
import appleIcon from "../../assets/signInIcon/apple.webp";
import "./modalStyle.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { userActions } from "../../store/user";
import userSelectors from "../../store/user/selectors";
import SignUpForm from "./SignUpForm";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios,{AxiosError} from "axios";
import APIURL from "../../utils/getApiURL";

interface singInInterface {
  email: `${string|number}@${string}.${string}`;
  password: string;
}
const SignInForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signInModalVisible = useAppSelector(userSelectors.signInModal);
  const [forgotpassword,setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const loader = useAppSelector(userSelectors.getLoader);

  const [signForm] = Form.useForm();
  const [forgotPasswordForm] = Form.useForm();
  const onFinish = (values: singInInterface) => {
    dispatch(
      userActions.signIn({
        email: values.email,
        password: values.password,
      })
    );
    navigate("/verify-account");
    signForm.resetFields();
  };
  const onFinishForgotPassword = async (values: { email: string }) => {
    if (loading) return;
    setLoading(true);
    
    try {
      await axios.post(`${APIURL}/auth/user/forgot-password/`, { email: values.email });
      notification.success({ message: "Password reset instructions have been sent to your email." });
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message?: string; non_field_errors?: string[] }>;
        if (axiosError.response) {
          const responseData = axiosError.response.data;
          if (responseData.non_field_errors && responseData.non_field_errors.length > 0) {
            errorMessage = responseData.non_field_errors[0];
          } else if (responseData.message) {
            errorMessage = responseData.message;
          }
        }
      }
      
      notification.error({ message: errorMessage });
    } finally {
      setLoading(false);
      setForgotPassword(false);
      forgotPasswordForm.resetFields();
    }
  };
  return (
    <>
      <SignUpForm />
      <Modal
        title="Sign In to HaveTo"
        visible={signInModalVisible}
        transitionName=""
        centered={true}
        onCancel={() => {
          dispatch(userActions.signInModal({ visible: false }));
          signForm.resetFields();
        }}
        footer={null}
      >
        <Form form={signForm} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Space direction="vertical">
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email field is required!" },
                    { type: "email", message: "Invaid email id!" },
                  ]}
                >
                  <Input
                    type="email"
                    className="email-input"
                    placeholder="Email address"
                  ></Input>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Password field is required!",
                    },
                  ]}
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? (
                        <EyeInvisibleOutlined style={{ color: "#707070" }} />
                      ) : (
                        <EyeOutlined style={{ color: "#707070" }} />
                      )
                    }
                    type="password"
                    className="email-input"
                    placeholder="Password"
                  ></Input.Password>
                </Form.Item>
                <Typography.Link onClick={() => {
                  setForgotPassword(true);
                  dispatch(userActions.signInModal({ visible: false }))
                  }} style={{ float: "right" }}>
                  Forgot Password?
                </Typography.Link>
              </Space>
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  className="submit-button"
                  loading={loader}
                  onClick={signForm.submit}
                  htmlType={"submit"}
                >
                  Continue with email
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <p className="continue-text">
                Don't have an account?{" "}
                <span
                  className="primary-color"
                  onClick={() => {
                    dispatch(userActions.signUpModal({ visible: true }));
                    dispatch(userActions.signInModal({ visible: false }));
                  }}
                >
                  Sign up
                </span>
              </p>
            </Col>
            {/* <Col span={24}>
              <hr className="or-text" />
            </Col>
            <Col span={24}>
              <p className="continue-text">Continue with</p>
            </Col>
            <Col span={24}>
              <div className="text-center">
                <Space direction="horizontal" size={30}>
                  <Image
                    src={googleIcon}
                    preview={false}
                    style={{ cursor: "pointer" }}
                  />
                  <Image
                    src={appleIcon}
                    preview={false}
                    style={{ cursor: "pointer" }}
                  />
                </Space>
              </div>
            </Col> */}
            <Col span={24}>
              <p className="continue-text">
                By continuing you agree to haveto.com's <br />
                <span className="primary-color">User Agreement</span>,{" "}
                <span className="primary-color">Wallet Services Terms</span> and{" "}
                <span className="primary-color">Privacy policy</span>
              </p>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Forgot Password"
        visible={forgotpassword}
        transitionName=""
        centered={true}
        onCancel={() => setForgotPassword(false)}
        footer={null}
      >
        <Form
          form={forgotPasswordForm}
          layout={"vertical"}
          onFinish={onFinishForgotPassword}
        >
          <Row gutter={[0, 20]}>
      <Col span={24}>
        <p className="continue-text">Enter your email address and we will send you a link to reset your password.</p>
      </Col>
      <Col span={24}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Email field is required!" },
            { type: "email", message: "Invalid email id!" },
          ]}
        >
          <Input
            type="email"
            className="email-input"
            placeholder="Email address"
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Button
            className="submit-button"
            loading={loader}
            onClick={forgotPasswordForm.submit}
            htmlType={"submit"}
          >
            Reset Password
          </Button>
        </Form.Item>
      </Col>
    </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SignInForm;
