import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Button, Space, Tooltip, Typography } from "antd";
import { ExclamationCircleFilled, StopOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import userSelectors from "../../../../store/user/selectors";
import { useParams } from "react-router-dom";
import haveToAPI from "../../../../services/api/user/api";
import { userActions } from "../../../../store/user";

const PersonalInfoCard = () => {
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelectors.getManageUserDetails);
  const [dataLoader, setdataLoader] = useState<boolean>(false);

  const updateActivateStatus = useCallback(
    (is_active: string) => {
      setdataLoader(true);
      haveToAPI
        .DeactivateActivateUser({
          user_id: Number(id),
          active_status: !is_active,
        })
        .then((re) => {
          setdataLoader(false);
        });
    },
    [id]
  );

  useEffect(() => {
    dispatch(userActions.manageUserDetails({ user_id: Number(id) }));
  }, [dispatch, dataLoader, id]);

  return (
    <div className="manage-user-profile">
      {/* Personal info */}
      <Space direction="horizontal" size={40}>
        <Avatar
          className="manage-user-avatar"
          icon={
            <div className="text-24 text-primary-color">
              {user?.first_name?.[0]
                .concat(user?.last_name?.[0] ?? "")
                .toUpperCase()}
            </div>
          }
        ></Avatar>
        <div
          className={
            user.is_active ? "text-24 " : "text-24 failed-color"
          }
        >
          {user.first_name?.charAt(0).toUpperCase() +
            user.first_name?.slice(1)! +
            " " +
            user.last_name?.charAt(0).toUpperCase() +
            user.last_name?.slice(1)! ?? "-"}
          <Typography className="text-16 profile-text-color">
            {user.email ?? "-"}
          </Typography>
        </div>
      </Space>
      {/* Ban profile button */}
      <Space size={20}>
        <Button
          icon={<StopOutlined />}
          loading={dataLoader}
          className={
            user.is_active ? "ban-profile-button" : "unlock-profile-button"
          }
          onClick={() => updateActivateStatus(user.is_active!)}
        >
          {user.is_active ? "Ban Profile" : "Unlock Profile"}
        </Button>

        <Tooltip
          placement="topLeft"
          color={"white"}
          title={
            <Typography.Text>
              {user.is_active
                ? "Blocked user will no longer be able to login !!"
                : "Unlocked user will have access of site !!"}
            </Typography.Text>
          }
        >
          <ExclamationCircleFilled />
        </Tooltip>
      </Space>
    </div>
  );
};

export default PersonalInfoCard;
