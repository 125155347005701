import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, notification, Typography,Space } from 'antd';
import axios from 'axios';
import { useAppDispatch } from '../../store/hooks';
import { userActions } from "../../store/user";
import APIURL from '../../utils/getApiURL';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

interface ResetPasswordValues {
  newPassword: string;
  confirmPassword: string;
}

const { Title } = Typography;

const ResetPassword: FC = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values: ResetPasswordValues) => {
    setLoading(true);
    try {
      const sessionToken = new URLSearchParams(window.location.search).get('session_token');

      const response = await axios.post(`${APIURL}/auth/user/reset-password/`, {
        session_token: sessionToken,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword,
      });

      notification.success({
        message: response.data.message || 'Password has been reset successfully.',
      });

      dispatch(userActions.signInModal({ visible: true }));
      navigate('/'); 
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      notification.error({ message: errorMessage });
    } finally {
      setLoading(false);
      form.resetFields();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: 20,
      }}
    >
      <div
        style={{
          maxWidth: 400,
          width: '100%',
          padding: 20,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: 10,
        }}
      >
        <Title level={2} style={{ textAlign: 'center'}}>
          Reset Password
        </Title>
        <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                { required: true, message: 'New password is required!' },
                { min: 8, message: 'Password must be at least 8 characters!' },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="Enter new password" iconRender={(visible) =>
                      visible ? (
                        <EyeInvisibleOutlined style={{ color: "#707070" }} />
                      ) : (
                        <EyeOutlined style={{ color: "#707070" }} />
                      )
                    }
                    type="password"
                    className="email-input"/>
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm New Password"
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" iconRender={(visible) =>
                      visible ? (
                        <EyeInvisibleOutlined style={{ color: "#707070" }} />
                      ) : (
                        <EyeOutlined style={{ color: "#707070" }} />
                      )
                    }
                    type="password"
                    className="email-input"/>
            </Form.Item>
          </Space>
          <Space direction="vertical" size="middle" style={{ width: '100%',marginTop:10,gap:5 }}>
            <Button
              className="submit-button"
              htmlType="submit"
              loading={loading}
              block
            >
              Reset Password
            </Button>
            <Button
              type="default"
              onClick={() => {
                dispatch(userActions.signInModal({ visible: true }));
                navigate('/');
                    }   
                }
              block
              style={{borderRadius:20,borderColor:"#707070"}}
            >
              Back to Login
            </Button>
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
