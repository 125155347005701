import { combineReducers } from "redux";
import { signInReducer } from "./user";
import { subscriptionReducer } from "./subscription";
import { uiReducer } from "./ui";
import { userWalletReducer } from "./wallet";
import { transactionReducer } from "./transaction";


export const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  user: signInReducer,
  ui:uiReducer,
  wallet:userWalletReducer,
  transaction:transactionReducer
});
