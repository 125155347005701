import React, { useState } from "react";
import { DatePicker, Form, Input, Radio, Space } from "antd";
import moment from "moment";

export interface GenderInterface {
  key: number;
  status: string;
  value: string;
}
export const GenderStatus: GenderInterface[] = [
  {
    key: 1,
    status: "Male",
    value: "Male",
  },
  {
    key: 2,
    status: "Female",
    value: "Female",
  },
  {
    key: 3,
    status: "Prefer not to say",
    value: "Other",
  },
];
const ApplicationDataForm = () => {
  const [genderSelect, setGenderSelect] = useState<string>("");
  return (
    <>
      <Space direction="vertical" size={20}>
        <Form.Item
          label="First name"
          name="firstname"
          rules={[
            {
              required: true,
              message: "Firstname field is required!",
            },
          ]}
        >
          <Input
            type="text"
            className="app-input"
            placeholder="Write your first name here"
          />
        </Form.Item>

        <Form.Item
          label="Last name"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Lastname field is required!",
            },
          ]}
        >
          <Input
            type="text"
            className="app-input"
            placeholder="Write your last name here"
          />
        </Form.Item>

        <Form.Item
          label="Date Of Birth"
          name="dateOfBirth"
          rules={[
            {
              required: true,
              message: "Date Of Birth field is required!",
            },
          ]}
        >
          <DatePicker
            className="app-input"
            inputReadOnly={true}
            format={"DD-MM-YYYY"}
            placeholder="Choose your birthdate"
            disabledDate={(d) => d.isAfter(moment())}
          />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="gender"
          rules={[
            {
              required: true,
              message: "Gender field is required!",
            },
          ]}
        >
          <Radio.Group
            value={genderSelect}
            onChange={(value) => {
              setGenderSelect(value.target.value);
            }}
          >
            <Space
              direction={window.screen.width <= 480 ? "vertical" : "horizontal"}
            >
              {GenderStatus.map((item: GenderInterface) => (
                <Radio key={item.key} value={item.value} className="app-radio-input">
                  {item.status}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Space>
    </>
  );
};

export default ApplicationDataForm;
