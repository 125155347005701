import React, { useEffect, useState } from "react";
import { InfoCircleFilled, RightOutlined } from "@ant-design/icons";
import { Button, Card, Input, Select, Skeleton, Space, Typography, notification } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userWalletSelectors } from "../../../store/wallet";
import userSelectors from "../../../store/user/selectors";
import { Link } from "react-router-dom";
import { setcurrentSelectedMenu } from "../../../store/ui/reducer";
import { formatNumber } from "../../../constants/ConstantVar";
import haveToAPI from "../../../services/api/user/api";
import { UserCountInterface } from "../../../services/api/user/types";

const AdminTotalUsers = () => {
  const dispatch = useAppDispatch();
  const { is_staff } = useAppSelector(userSelectors.getUserDetails);
  const [userCount, setuserCount] = useState<UserCountInterface>({})
  useEffect(() => {
    (is_staff && is_staff !== undefined) &&
      haveToAPI.getUserCount().then((result) => {
        setuserCount(result.userCount)
      }).catch((err) => {
        notification.error({message:err})
      });
  }, [is_staff]);

  return (
    <Card
      title="Total users"
      extra={
        <Link
          to={"users"}
          onClick={() => dispatch(setcurrentSelectedMenu("/users"))}
        >
          View all
          <RightOutlined />
        </Link>
      }
      className={"table-card"}
    >
      <div className="total-user-container">
        <Space direction="vertical" size={32}>
          <div>
            <Typography.Text className="text-32 ">
              {formatNumber(userCount?.total_user?.toString() ?? "0")}
            </Typography.Text>
            <br />
            <Typography.Text className="text-16">
              <span className="success-color text-16">+{formatNumber(userCount?.joined_weekly_user?.toString() ?? "0")}</span> from last week
            </Typography.Text>
          </div>
          <div>
            <Typography.Text className="text-18 profile-text-color">
              Pending KYC
            </Typography.Text>
            <br />
            <br />
            <div className="pending-kyc">
              <Typography.Text className="text-16 profile-text-color">
                <span className="text-32">{formatNumber(userCount?.total_pending_user?.toString() ?? "0")}</span>{" "}
                Applications
              </Typography.Text>
              <Link
                to={"users"}
                onClick={() => dispatch(setcurrentSelectedMenu("/users"))}
              >
                <Button className="user-verify-button">
                  Verify
                  <RightOutlined />
                </Button>
              </Link>
            </div>
          </div>
        </Space>
      </div>
    </Card>
  );
};

const UserTokenCalculation = () => {
  const [tokenPrice, settokenPrice] = useState<number>(0);
  const [coin, setCoin] = useState<string>("USD");
  const [conversionPrice, setConversionPrice] = useState<number>(0);
  const { USDT, ETH } = useAppSelector(userWalletSelectors.htcTokenConversion);

  const CryptoCurrency = {
      USD_CRYPTO : "USD",
      ETH_CRYPTO : "ETH"
  }
  useEffect(() => {
    let convertedPrice: number = 0;
    switch (coin) {
      case CryptoCurrency.ETH_CRYPTO:
        convertedPrice = tokenPrice / ETH!;
        if (tokenPrice >= 100000) {
          convertedPrice *= 1.07; // Add 7%
        } else if (tokenPrice >= 10000) {
          convertedPrice *= 1.03; // Add 3%
        }
        setConversionPrice(convertedPrice);
        break;
      case CryptoCurrency.USD_CRYPTO:
        convertedPrice = tokenPrice / USDT!;
        if (tokenPrice >= 100000) {
          convertedPrice *= 1.07; // Add 7%
        } else if (tokenPrice >= 10000) {
          convertedPrice *= 1.03; // Add 3%
        }
        setConversionPrice(convertedPrice);
        break;
      default:
        setConversionPrice(convertedPrice);
        break;
    }
  }, [tokenPrice, ETH, USDT, coin,CryptoCurrency.ETH_CRYPTO,CryptoCurrency.USD_CRYPTO]);
  return (
    <>
      <div className="token-calculation">
        <Space direction="vertical" size={20}>
          <div>
            <span className="text-24">Token calculation</span>
            <br />
            <span className="text-12">Enter amount to calculate tokens</span>
          </div>
          <Input
            type="number"
            className="token-calculation-input text-white-color"
            autoComplete="off"
            defaultValue={0}
            maxLength={10}
            onChange={(e) => settokenPrice(parseFloat(e.target.value))}
            placeholder="Enter amount"
            size="large"
            suffix={
              // <Select
              //   size="large"
              //   className="token-calculation-dropdown"
              //   defaultValue={CryptoCurrency.ETH_CRYPTO}
              //   onChange={(coin) => {
              //     setCoin(coin);
              //   }}
              // >
              //   <Select.Option value={CryptoCurrency.ETH_CRYPTO}>ETH</Select.Option>
              //   <Select.Option value={CryptoCurrency.USD_CRYPTO}>USD</Select.Option>
              // </Select>
                <span className="fixed-span">{CryptoCurrency.USD_CRYPTO}&nbsp;&nbsp;&nbsp; </span>
            }
          />
          <div className="text-32">
            = {!Number.isNaN(conversionPrice) ? conversionPrice.toFixed(3) : 0}{" "}
            <sub className="text-12p">HaveTo</sub>
          </div>
          <span className="text-12o">
            <InfoCircleFilled />
            &nbsp;Amount calculated based on current token price
          </span>
        </Space>
      </div>
    </>
  );
};

const TokenCalculation = () => {
  const { is_staff } = useAppSelector(userSelectors.getUserDetails);

  return (
    <>
      {
        is_staff && is_staff !== undefined ? (
        <AdminTotalUsers />
      ) : !is_staff && is_staff !== undefined ? (
        <UserTokenCalculation />
      ) : <Skeleton active paragraph={{ rows: 7 }} />}
    </>
  );
};

export default TokenCalculation;
