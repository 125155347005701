import React, { useEffect, useState } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Space, Avatar, Table, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { getRandomColor } from "../../../constants/getRandomColor";
import { UpdateUserInterface } from "../../../services/api/user/types";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { userActions } from "../../../store/user";
import userSelectors from "../../../store/user/selectors";
import VerifyUserModal from "./manage_user/VerifyUserModal";

const PendingKYCUsersList = () => {
  const [pageSize, setpageSize] = useState<number>(10);
  const [user, setUser] = useState<number>();
  const users = useAppSelector(userSelectors.getAllUserDetails);
  const dispatch = useAppDispatch();
  const loader = useAppSelector(userSelectors.getLoader);
  const paginationProps = {
    showLessItems: true,
    showSizeChanger: true,
    pageSize: pageSize,

    total: users.count,
    onChange: (pageNumber: number) => {
      dispatch(
        userActions.getAllUserDetails({
          page: pageNumber,
          pageSize: pageSize,
          kyc_status:"Pending" 
        })
      );
    },
    onShowSizeChange: (current: number, size: number) => {
      setpageSize(size);
      dispatch(
        userActions.getAllUserDetails({ page: current, pageSize: size, kyc_status:"Pending" })
      );
    },
  };
  const columns: ColumnsType<UpdateUserInterface> = [
    {
      key: 1,
      title: "Username",
      render: (user) => {
        return (
          <div>
            <Space direction="horizontal" size={20} className="grey">
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                size="large"
              >
                {user?.first_name?.[0]
                  .concat(user?.last_name?.[0] ?? "")
                  .toUpperCase()}
              </Avatar>
              <Space direction="vertical" size={0}>
                <div className="grey text-16">
                  {user?.first_name + " " + user?.last_name}
                </div>
                <div className="profile-text-color text-12">{user?.email}</div>
              </Space>
            </Space>
          </div>
        );
      },
      sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
        a.first_name?.localeCompare(b?.first_name!)!,
    },
    {
      key: 2,
      title: "Doc type",
      render: (user) => {
        return (
          <div className="grey text-16">{user.identity_proof}</div>
        );
      },
      sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
        a.identity_proof?.localeCompare(b?.identity_proof!)!,
    },
    {
      key: 3,
      title: "KYC",
      render: (user) => {
        return (
          <Space size={25} align="center" className="grey"> 
            <Space direction="horizontal">
              {user?.application_status === "Pending" ? (
                <ExclamationCircleOutlined className="pending-color text-18" />
              ) : user?.application_status === "Success" ? (
                <CheckCircleOutlined className="success-color text-18" />
              ) : (
                <CloseCircleOutlined className="failed-color text-18" />
              )}
              Data
            </Space>
            <Space direction="horizontal">
              {user?.document_status === "Pending" ? (
                <ExclamationCircleOutlined className="pending-color text-18" />
              ) : user?.document_status === "Success" ? (
                <CheckCircleOutlined className="success-color text-18" />
              ) : (
                <CloseCircleOutlined className="failed-color text-18" />
              )}
              Document
            </Space>
          </Space>
        );
      },
    },
    {
      key: 4,
      title: "Joined",
      render: (user) => {
        return (
          <div className="grey">{moment(user?.date_joined).format("DD MMM Y ● hh:mm:ss")}</div>
        );
      },
      sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
        a.last_login?.localeCompare(b.last_login!)!,
    },
    {
      key: 5,
      title: "Verify Docs",
      render: (user) => {
        return (
          <Button className="user-manage-verify-button " onClick={()=>{dispatch(userActions.verifyUserModal({ visible: true }));setUser(user?.id!)}}>
            Verify
            <RightOutlined />
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(userActions.getAllUserDetails({ page: 1, pageSize: pageSize ,kyc_status:"Pending"}));
    return ()=>{
      dispatch(userActions.getAllUserDetails({ page: 1, pageSize: 10 }));
    }
  }, [dispatch, pageSize]);
  return (
    <>
    <VerifyUserModal user_id = {user!}/>
    <Table
      loading={loader}
      sticky={true}
      scroll={{ x: true, y: 525 }}
      pagination={paginationProps}
      columns={columns}
      dataSource={users.results}
      />
      </>
  );
};

export default PendingKYCUsersList;
