import React from "react";
import { Layout, Image, MenuProps, Button, Menu } from "antd";
import havetoLogo from "../../assets/haveto.svg";
import hLogo from "../../assets/H icon.svg";
import {
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { uiSelectors } from "../../store/ui";
import { useNavigate } from "react-router-dom";
import { AdminItems, items } from "../../config/route";
import { setcurrentSelectedMenu } from "../../store/ui/reducer";
import userSelectors from "../../store/user/selectors";
import BuyHavetoCoinModal from "../Payment/BuyHavetoCoinModal";
const { Sider } = Layout;


const Sidebar = () => {
  const collapsed = useAppSelector(uiSelectors.getSidebarCollapsed);
  const currentSelectedMenu = useAppSelector(uiSelectors.getCurrentSelectedMenu)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = React.useState(false)
  const {is_staff} = useAppSelector(userSelectors.getUserDetails)
  const TabbarClick: MenuProps["onClick"] = (e) => {
    dispatch(setcurrentSelectedMenu(e.key));
    navigate(e.key);
  };

  const buyHavetoCoin = ()=>{
    setModalVisible(true)
  }
  return (
    <Sider width={250} collapsible={true} collapsed={collapsed}>
      <div className="logo">
        <Image
          src={collapsed ? hLogo : havetoLogo}
          preview={false}
          height={50}
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={is_staff ? AdminItems : items}
        selectedKeys={[currentSelectedMenu]}
        defaultSelectedKeys={[currentSelectedMenu]}
        onClick={TabbarClick}
      />
      <Button
        className={collapsed ? "collapse-buy-htc-btn" : "buy-htc-btn"}
        icon={<ShoppingCartOutlined />}
        onClick={buyHavetoCoin}
      >
        {!collapsed && "Buy HaveTo Token"}
      </Button>
      <BuyHavetoCoinModal visible={modalVisible} onClose={()=>setModalVisible(false)}/>
    </Sider>
  );
};

export default Sidebar;
