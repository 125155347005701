import React, { useEffect, useState } from "react";
import logo from "../../assets/haveto.svg";
import robot from "../../assets/websiteIcon/robot.png";
import haveto from "../../assets/websiteIcon/haveto.png";
import plus from "../../assets/websiteIcon/plus.webp";
import { ReactComponent as hIcon } from "../../assets/websiteIcon/h.svg";
import { ReactComponent as menuIcon } from "../../assets/websiteIcon/Menu2.svg";
import { Button, Image, Space } from "antd";
import Icon, { CloseOutlined } from "@ant-design/icons";
import SignInForm from "../User/SignInForm";
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/user";

const Header = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false); // checking menu button is clicked or not in mobile view
  const [scroll, setScroll] = useState("");
  const dispatch = useAppDispatch()

  // Scroll indicator code
  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;

      setScroll(scroll);
    };
    window.addEventListener("scroll", progressBarHandler);
    return () => window.removeEventListener("scroll", progressBarHandler);
  });

  return (
    <>
      <SignInForm/>
      {/* This div is for showing scrolling progress indicator */}
      <div id="progressBarContainer">
        <div id="progressBar" style={{ transform: `scale(${scroll}, 1)` }} />
      </div>
      <div className="home-back">
        <div className="header">
          <Image
            src={logo}
            preview={false}
            className="logo-img"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
          {/* Start Navbar section for desktop */}
          <div className="navbar">
            <ul className="navbar-items">
              <li>
                <a href="#features">Features</a>
              </li>
              {/* <li>
                <a href="#demo">Demo</a>
              </li> */}
              <li>
                <a href="#roadmap">Roadmap</a>
              </li>
              <li>
                <a href="#coin-table">Coin Table</a>
              </li>
              {/* <li>
                <a href="#faq">FAQ</a>
              </li> */}
              <li>
                <a href="#about">About</a>
              </li>
              {/* <li>
                <a href="#white-paper">White Paper</a>
              </li> */}
              <li>
                <button
                  className="signin-button"
                  onClick={() => dispatch(userActions.signInModal({visible:true}))}
                >
                  Sign In
                </button>
              </li>
              <li>
                <button
                  className="signup-button"
                  onClick={() => dispatch(userActions.signUpModal({visible:true}))}
                >
                  Sign Up
                </button>
              </li>
              {/* <li>
                <button className="buynow-button">Buy Now</button>
              </li> */}
            </ul>
          </div>
          {/* Condition checking that mobile screen display menu icons and close icon */}
          <Button
            className="menu"
            onClick={() => setisMenuOpen(!isMenuOpen)}
            icon={
              isMenuOpen ? <CloseOutlined /> : <Icon component={menuIcon} />
            }
          />
        </div>
        {/* Start Navbar for mobile and tablet screen */}
        <div style={{ height: isMenuOpen ? "100%" : "0" }} className="overlay">
          <div className="overlay-content">
            <Space direction={"vertical"}>
              <a href="#features" onClick={() => setisMenuOpen(false)}>
                Features
              </a>
              {/* <a href="#demo" onClick={() => setisMenuOpen(false)}>
                Demo
              </a> */}
              <a href="#roadmap" onClick={() => setisMenuOpen(false)}>
                Roadmap
              </a>
              <a href="#coin-table" onClick={() => setisMenuOpen(false)}>
                Coin Table
              </a>
              {/* <a href="#faq" onClick={() => setisMenuOpen(false)}>
                FAQ
              </a> */}
              <a href="#about" onClick={() => setisMenuOpen(false)}>
                About
              </a>
              {/* <a href="#whitepaper" onClick={() => setisMenuOpen(false)}>
                White Paper
              </a> */}
              <button
                className="signin-button"
                onClick={() => {
                  setisMenuOpen(false);
                  dispatch(userActions.signInModal({visible:true}))
                }}
              >
                Sign In
              </button>
              
                <button
                  className="signup-button"
                  onClick={() => dispatch(userActions.signUpModal({visible:true}))}
                >
                  Sign Up
                </button>
              
              {/* <button
                className="buynow-button"
                onClick={() => setisMenuOpen(false)}
              >
                Buy Now
              </button> */}
            </Space>
          </div>
        </div>
        <div className="abs-height"></div>{" "}
        {/* Absolute height because of fixed height of header */}
        <div className="home" id="home">
          <ul>
            <li className="text">
              Blockchain{" "}
            </li>
            <li className="text">Artificial Intelligence (LLM){" "}
              </li>
            <li className="text">
              Auto Scaling (Sharding)
            </li>
            {/*<li>
              <Button
                className="buycoin-button"
                onClick={() => {
                  setisMenuOpen(false);
                  dispatch(userActions.signInModal({visible:true}))
                }}
                icon={<Icon component={hIcon}  />}
                
              >
                Buy haveto coin
              </Button>
            </li>*/}
          </ul>
          <Image src={robot} className={"robot-img"} preview={false}></Image>
        </div>
        <div className="explore-more">
          <Button className="explore-button" type="text">
            <a href="#features">
              <p className="explore-text">Explore more</p>
            </a>
          </Button>
          <img src={haveto} className={"haveto-img"} alt={"haveto"} />
        </div>
      </div>
    </>
  );
};

export default Header;