import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Card, Tabs, Input } from "antd";
import PendingKYCUsersList from "./user_components/PendingKycUsersList";
import UsersList from "./user_components/UsersList";
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/user";

const Users = () => {
  const [usersTab, setUsersTab] = useState<string>("1");
  const dispatch = useAppDispatch();
  function onChnageSearchText(val: string) {
    dispatch(
      userActions.getAllUserDetails({
        page: 1,
        pageSize: 10,
        search: val,
        kyc_status: usersTab === "2" ? "Pending" : "",
      })
    );
  }
  return (
    <Row className="row-gap">
      <Col span={24}>
        <Card
          title={
            <div className="user-tab-container">
              <Tabs
                defaultActiveKey={usersTab}
                className="grey "
                onChange={(tab) => {
                  setUsersTab(tab); 
                }}
              >
                <Tabs.TabPane tab="All Users" key={1} />
                <Tabs.TabPane tab="Pending KYC" key={2} />
              </Tabs>
              <Input
                className="search-input"
                onChange={(e) => onChnageSearchText(e.target.value)}
                placeholder="search"
                suffix={<SearchOutlined className="profile-text-color"/>}
              ></Input>
            </div>
          }
          className={"transaction-card"}
        >
          {usersTab === "1" ? <UsersList /> : <PendingKYCUsersList />}
        </Card>
      </Col>
    </Row>
  );
};

export default Users;
