import { call, getContext, put, takeLeading } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { HAVETOApi } from "../../services/api";
import { userActions } from "./actions";
import {
  AllUsersPayload,
  ChangePasswordPayload,
  DeactivateActivateUserPayload,
  EmailVerificationPayload,
  ManageUserPayload,
  ResendMailPayload,
  SignInPayload,
  SignUpPayload,
  UpdateUserPayload,
  VerifyDataAndDocumentPayload,
} from "../../services/api/user/types";
import { notification } from "antd";
import { SignInState } from "./reducer";

const {
  signInSuccess,
  signInFailure,
  signUpFailure,
  signUpSuccess,
  updateUserSuccess,
  updateUserFailure,
  getAllUserDetailsFailure,
  getAllUserDetailsSuccess,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  manageUserDetailsSuccess,
  manageUserDetailsFailure,
  activeAndDeactivateUserSuccess,
  activeAndDeactivateUserFailure,
  emailVerificationFailure,
  emailVerificationSuccess,
  getStepStatusFailure,
  getStepStatusSuccess,
  changePasswordFailure,
  changePasswordSuccess,
  resendEmailVerificationMailFailure,
  resendEmailVerificationMailSuccess,
  verifyDataAndDocStatusSuccess,
  verifyDataAndDocStatusFailure
} = userActions;

// call sign-in API
function* createSignInFlow(action: PayloadAction<SignInPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(api.user.signIn, action.payload);
  if (error) {
    yield put(signInFailure({ user: null, error }));
  } else {
    yield put(signInSuccess({ user, error: null }));
  }
}
// call sign-up or registration API
function* createSignUpFlow(action: PayloadAction<SignUpPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(api.user.signUp, action.payload);
  if (error) {
    yield put(signUpFailure({ user: null, error }));
  } else {
    yield put(signUpSuccess({ user, error: null }));
  }
}
// call resend-mail API
function* resendEmailVerificationMailFlow(action: PayloadAction<ResendMailPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { resendMail, error } = yield call(api.user.resendEmailVerificationMail, action.payload);
  if (error) {
    yield put(resendEmailVerificationMailFailure({ resendMail: null, error }));
  } else {
    yield put(resendEmailVerificationMailSuccess({ resendMail, error: null }));
  }
}
// call fetch all users details
function* getAllUsersFlow(action: PayloadAction<AllUsersPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { allUser, error } = yield call(api.user.getAllUserDetails,action.payload);
  if (error) {
    yield put(getAllUserDetailsFailure({ allUser: null, error }));
  } else {
    yield put(getAllUserDetailsSuccess({ allUser, error: null }));
  }
}
// call fetch user details of authenticated user API
function* getUserFlow(action: PayloadAction) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(api.user.getUserDetails);
  if (error) {
    yield put(getUserDetailsFailure({ user: null, error }));
  } else {
    yield put(getUserDetailsSuccess({ user, error: null }));
  }
}
// call fetch perticular user details for managing
function* getManageUserDetailsFlow(action: PayloadAction<ManageUserPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(api.user.getManageUserDetails,action.payload);
  if (error) {
    yield put(manageUserDetailsFailure({ user: null, error }));
  } else {
    yield put(manageUserDetailsSuccess({ user, error: null }));
  }
}
// call fetch step status of authenticated user API
function* getStepStatusFlow(action: PayloadAction) {
  const api: HAVETOApi = yield getContext("api");
  const { stepStatus, error } = yield call(api.user.getStepStatus);
  if (error) {
    yield put(getStepStatusFailure({ stepStatus: null, error }));
  } else {
    yield put(getStepStatusSuccess({ stepStatus, error: null }));
  }
}
// call fetch user details of authenticated user API
function* createUpdateUserFlow(action: PayloadAction<UpdateUserPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(api.user.updateUser, action.payload);
  if (error) {
    yield put(updateUserFailure({ user: null, error }));
  } else {
    yield put(updateUserSuccess({ user, error: null }));
  }
}
// call Email verification API
function* createEmailVerificationFlow(
  action: PayloadAction<EmailVerificationPayload>
) {
  const api: HAVETOApi = yield getContext("api");
  const { user, error } = yield call(
    api.user.emailVerification,
    action.payload
  );
  if (error) {
    yield put(emailVerificationFailure({ user: null, error }));
  } else {
    yield put(emailVerificationSuccess({ user, error: null }));
  }
}
// call Application Data and Document status API
function* verifyDataAndDocsStatusFlow(
  action: PayloadAction<VerifyDataAndDocumentPayload>
) {
  const api: HAVETOApi = yield getContext("api");
  const { updateStatus, error } = yield call(
    api.user.VerifyDataAndDocsStatus,
    action.payload
  );
  if (error) {
    yield put(verifyDataAndDocStatusFailure({ updateStatus: null, error }));
  } else {
    yield put(verifyDataAndDocStatusSuccess({ updateStatus, error: null }));
  }
}
// call API for activate and de-activate user
function* activateOrDeActivateUserFlow(
  action: PayloadAction<DeactivateActivateUserPayload>
) {
  const api: HAVETOApi = yield getContext("api");
  const { activeStatus, error } = yield call(
    api.user.DeactivateActivateUser,
    action.payload
  );
  if (error) {
    yield put(activeAndDeactivateUserFailure({ activeStatus: null, error }));
  } else {
    yield put(activeAndDeactivateUserSuccess({ activeStatus, error: null }));
  }
}

export default function* userSaga() {
  // Calling API signin
  yield takeLeading(actionTypes.Signin, createSignInFlow);
  // Do Action when success called signin
  yield takeLeading(
    actionTypes.SigninFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message:
          action.payload.error?.message.message || "Something went wrong",
      });
    }
  );

// call change-password API for user
function* changePasswordFlow(action: PayloadAction<ChangePasswordPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { change_password, error } = yield call(api.user.changePassword, action.payload);
  if (error) {
    yield put(changePasswordFailure({ change_password: null, error }));
  } else {
    yield put(changePasswordSuccess({ change_password, error: null }));
  }
}

  yield takeLeading(actionTypes.Signup, createSignUpFlow);
  yield takeLeading(
    actionTypes.SignupFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message:
          action.payload.error?.message?.email ||
          action.payload.error?.message?.non_field_errors[0] ||
          "Something went wrong",
      });
    }
  );
  yield takeLeading(actionTypes.ResendEmailVerificationMail, resendEmailVerificationMailFlow);
  yield takeLeading(
    actionTypes.ResendEmailVerificationMailFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message:
          action.payload.error?.message || "Something went wrong",
      });
    }
  );
  yield takeLeading(
    actionTypes.ResendEmailVerificationMailSuccess,
    function* (action: PayloadAction<SignInState>) {
      yield notification.success({
        message:
          "Verification mail has been sent in your mail id" || "Something went wrong",
      });
    }
  );
  yield takeLeading(actionTypes.GetUserDetails, getUserFlow);
  yield takeLeading(actionTypes.GetAllUserDetails, getAllUsersFlow);
  yield takeLeading(
    actionTypes.GetAllUserDetailsFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message: action.payload.error?.message?.non_field_errors || action.payload.error?.message || action.payload.error?.message?.detail || "Something went wrong",
      });
    }
  );
  yield takeLeading(actionTypes.ManageUserDetails, getManageUserDetailsFlow);
  yield takeLeading(actionTypes.GetStepStatus, getStepStatusFlow);
  yield takeLeading(actionTypes.ActiveAndDeactivateUser, activateOrDeActivateUserFlow);
  yield takeLeading(actionTypes.UpdateUser, createUpdateUserFlow);
  yield takeLeading(
    actionTypes.UpdateUserFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message:
          action.payload.error?.message?.detail ||
          action.payload.error?.message?.email ||
          action.payload.error?.message?.non_field_errors[0] ||
          "Something went wrong",
      });
    }
  );
  yield takeLeading(actionTypes.EmailVerification, createEmailVerificationFlow);
  yield takeLeading(actionTypes.ChangePassword, changePasswordFlow);
  yield takeLeading(
    actionTypes.ChangePasswordFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message: action.payload.error?.message?.non_field_errors || action.payload.error?.message[0] || action.payload.error?.message?.message[0]|| "Something went wrong",
      });
    }
  );
  yield takeLeading(
    actionTypes.ChangePasswordSuccess,
    function* (action: PayloadAction<SignInState>) {
      yield notification.success({
        message: "Password Changed",description:"Your password has been changed successfully. Login with your new password."
      });
    }
  );
  // call API for verify application data and docs status
  yield takeLeading(actionTypes.VerifyDataAndDocStatus, verifyDataAndDocsStatusFlow);
  yield takeLeading(
    actionTypes.VerifyDataAndDocStatusFailure,
    function* (action: PayloadAction<SignInState>) {
      yield notification.error({
        message: action.payload.error?.message?.non_field_errors || action.payload.error?.message || action.payload.error?.message?.detail || "Something went wrong",
      });
    }
  );
  yield takeLeading(
    actionTypes.VerifyDataAndDocStatusSuccess,
    function* (action: PayloadAction<SignInState>) {
      yield notification.success({
        message: "Verify data status updated." || "Something went wrong",
      });
    }
  );
}
