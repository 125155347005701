import React, { useState } from "react";
import { Row, Col, Image, Space, Button, Input } from "antd";
// import whitePaper from "../../assets/websiteIcon/footer/white_paper.webp";
import havetoLogo from "../../assets/haveto.svg";
// import instagramIcon from "../../assets/websiteIcon/footer/instagram.svg";
// import fbIcon from "../../assets/websiteIcon/footer/facebook.svg";
// import discordIcon from "../../assets/websiteIcon/footer/discord.svg";
// import twitterIcon from "../../assets/websiteIcon/footer/twitter.svg";
import moment from "moment";
import { useAppDispatch } from "../../store/hooks";
import { subscriptionActions } from "../../store/subscription";

const Footer = () => {
  const currentYear = moment().utc().format("YYYY");
  const [email, setemail] = useState("")
  const dispatch = useAppDispatch();
  function onSubscribe() {
    dispatch(subscriptionActions.createSubscription({
      email :email
    }));
  }
  return (
    <>
      <div className="footer">
        <div className="features">
          <div className="text-center">
            <Image src={havetoLogo} className="logo-img" preview={false} />
            <div className="footer-text">
              First coin network to allow any programming
              <br />
              language and supports AI
            </div>
            <div className="features">
              <Row justify="space-between" gutter={[15, 15]}>
                <Col>
                  <div className="footer-text" style={{ textAlign: "center" }}>
                    Updates right to your inbox
                  </div>

                  <Space direction="vertical" size={12}>
                    <Input
                      type="text"
                      placeholder="Your email here"
                      className="footer-input"
                      onChange={(e)=>setemail(e.target.value)}/>
                    <Button className="footer-button" onClick={onSubscribe}>Subscribe</Button>
                  </Space>
                </Col>
                <Col>
                  <ul className="footer-menu">
                    <li>
                      <a href="#features">Features</a>
                    </li>
                    {/* <li>
                      <a href="#demo">Demo</a>
                    </li> */}
                    <li>
                      <a href="#roadmap">Roadmap</a>
                    </li>
                    <li>
                      <a href="#coin-table">Coin Table</a>
                    </li>
                    {/* <li>
                      <a href="#faq">FAQ</a>
                    </li> */}
                    <li>
                      <a href="#about">About</a>
                    </li>
                  </ul>
                </Col>
                {/* <Col>
                  <Image
                    src={whitePaper}
                    preview={false}
                    className="white-paper"
                  />
                </Col> */}
              </Row>
            </div>
          </div>
          <hr />
          {/* <Row justify={"space-between"}>
            <Col>
              <Space direction="horizontal">
                <a href="#privacy">Privacy policy</a>
                <a href="#term">Terms & Condition</a>
                <a href="#contact">Contact</a>
              </Space>
            </Col>
            <Col className="footer-icon">
              <Space direction="horizontal" size={15}>
                <a href="instagram">
                  <Image src={instagramIcon} preview={false} />
                </a>
                <a href="fbicon">
                  <Image src={fbIcon} preview={false} />
                </a>
                <a href="discord">
                  <Image src={discordIcon} preview={false} />
                </a>
                <a href="twitter">
                  <Image src={twitterIcon} preview={false} />
                </a>
              </Space>
            </Col>
          </Row> */}
          <p className="copyright-text">
            &#169; {currentYear} HaveTo. All rights reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;