import React, { useEffect, useState } from "react";
import Icon, { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import userSelectors from "../../../store/user/selectors";
import { ReactComponent as uploadIcon } from "../../../assets/doc-tutorials/Upload.svg";
import { GenderInterface, GenderStatus } from "../../User/ApplicationDataForm";
import { countryList } from "../../../constants/countryList";
import { RcFile } from "antd/lib/upload";
import haveToAPI from "../../../services/api/user/api";

interface ProfileInterface {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  gender: string;

  country: string;
  identity_proof: string;
  front_img: string;
  back_img: string;
}

const UpdateProfileModal = ({ visibleModal, onClose }: any) => {
  const user = useAppSelector(userSelectors.getUserDetails);
  const [genderSelect, setGenderSelect] = useState<string>("Male");
  const [identitySelect, setIdentitySelect] = useState<number>(0);
  const [frontImg, setFrontImg] = useState<UploadFile[]>([]);
  const [backImg, setBackImg] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [appForm] = Form.useForm();

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const frontOnChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFrontImg(newFileList);
    appForm.setFieldsValue({ front_img: newFileList[0] });
  };
  const backOnChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setBackImg(newFileList);
    appForm.setFieldsValue({ back_img: newFileList[0] });
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleCancel = () => setPreviewOpen(false);
  const dispatch = useAppDispatch();

  const onFinish = (values: ProfileInterface) => {
    if (
      user.first_name !== values.firstname ||
      user.last_name !== values.lastname ||
      user.date_of_birth !== moment(values.dateOfBirth).format("YYYY-MM-DD") ||
      user.gender !== values.gender ||
      user.country !== values.country ||
      user.identity_proof !== values.identity_proof ||
        user.front_img !==
        values.front_img ||
        user.back_img !==
        values.back_img
    ) {
      if (
        values.front_img !== user.front_img
      ) {
        haveToAPI
          .updateProfileDetails({
            first_name: values.firstname,
            last_name: values.lastname,
            date_of_birth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
            gender: values.gender,
            country: values.country,
            front_img: values.front_img,
            identity_proof: values.identity_proof,
            document_status: "Pending",
          })
          .then((res) => {
            notification.success({ message: "Details updated !!" });
          });
      } else if (
        values.back_img !==user.back_img
      ) {
        haveToAPI
          .updateProfileDetails({
            first_name: values.firstname,
            last_name: values.lastname,
            date_of_birth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
            gender: values.gender,
            country: values.country,
            back_img: values.back_img,
            identity_proof: values.identity_proof,
            document_status: "Pending",
          })
          .then((result) => {
            notification.success({ message: "Details updated !!" });
          });
      } else {
        haveToAPI
          .updateProfileDetails({
            first_name: values.firstname,
            last_name: values.lastname,
            date_of_birth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
            gender: values.gender,
            country: values.country,
            identity_proof: values.identity_proof,
            application_status: "Pending",
          })
          .then((result) => {
            notification.success({ message: "Details updated !!" });
          });
      }
    }
  };

  useEffect(() => {
    appForm.setFieldsValue({
      firstname: user.first_name,
      lastname: user.last_name,
      dateOfBirth: moment(user.date_of_birth),
      gender: user.gender,
      country: user.country,
      identity_proof: user.identity_proof,
      front_img:user.front_img,
      back_img:user.back_img,
    });
    setFrontImg([
      {
        uid: "-1",
        name: "front_img.png",
        status: "done",
        url:user.front_img,
      },
    ]);
    setBackImg([
      {
        uid: "-1",
        name: "back_img.png",
        status: "done",
        url:user.back_img,
      },
    ]);
  }, [
    appForm,
    user.first_name,
    user.last_name,
    user.date_of_birth,
    user.gender,
    user.country,
    user.identity_proof,
    user.front_img,
    user.back_img,
    dispatch,
  ]);

  return (
    <Modal
      style={{ backgroundColor: "black" }}
      title="Edit details"
      visible={visibleModal}
      onCancel={onClose}
      footer={null}
      width={1050}
    >
      <Form form={appForm} layout="vertical" onFinish={onFinish}>
        <Row justify="center" gutter={[6, 12]}>
          <Col lg={12} xs={24}>
            {/* Application data */}
            <div className="application-data">
              <div className="kyc-title-container">
                <div className="text-white-color text-24">
                  Application Data :
                </div>
                <Typography.Text
                  className={
                    user.application_status?.toLowerCase() + "-color-button"
                  }
                  title={user.application_status}
                >
                  {user.application_status}
                </Typography.Text>
              </div>
              <br />
              <Space direction="vertical" size={10}>
                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      First name
                    </Typography.Text>
                  }
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Firstname field is required!",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      user.application_status === "Failed" ? false : true
                    }
                    type="text"
                    className="app-input"
                    placeholder="Write your first name here"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      Last name
                    </Typography.Text>
                  }
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Lastname field is required!",
                    },
                  ]}
                >
                  <Input
                    disabled={
                      user.application_status === "Failed" ? false : true
                    }
                    type="text"
                    className="app-input"
                    placeholder="Write your last name here"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      Date of birth
                    </Typography.Text>
                  }
                  name="dateOfBirth"
                  rules={[
                    {
                      required: true,
                      message: "Date Of Birth field is required!",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={
                      user.application_status === "Failed" ? false : true
                    }
                    className="app-input"
                    inputReadOnly={true}
                    format={"DD-MM-YYYY"}
                    placeholder="Choose your birthdate"
                    disabledDate={(d) => d.isAfter(moment())}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      Gender
                    </Typography.Text>
                  }
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender field is required!",
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={
                      user.application_status === "Failed" ? false : true
                    }
                    className={"text-white-color"}
                    value={genderSelect}
                    defaultValue={genderSelect}
                    onChange={(value) => {
                      setGenderSelect(value.target.value);
                    }}
                  >
                    <Space
                      direction={
                        window.screen.width <= 480 ? "vertical" : "horizontal"
                      }
                    >
                      {GenderStatus.map((item: GenderInterface) => (
                        <Radio
                          key={item.key}
                          value={item.value}
                          className="app-radio-input"
                        >
                          {item.status}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </div>
          </Col>
          <Col lg={12} xs={24}>
            {/* Identity proof data */}
            <div className="application-data">
              <div className="kyc-title-container">
                <div className="text-white-color text-24">
                  Identity document
                </div>
                <Typography.Text
                  className={
                    user.document_status?.toLowerCase() + "-color-button"
                  }
                  title={user.document_status}
                >
                  {user.document_status}
                </Typography.Text>
              </div>
              <br />
              <Space direction="vertical" size={20}>
                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      Country
                    </Typography.Text>
                  }
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Select your country!",
                    },
                  ]}
                >
                  <Select
                    disabled={user.document_status === "Failed" ? false : true}
                    listHeight={160}
                    allowClear={true}
                    showSearch={true}
                    placeholder="Select country"
                  >
                    {countryList?.map((item) => (
                      <Select.Option key={item.code} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={
                    <Typography.Text className="text-white-color">
                      Document Type
                    </Typography.Text>
                  }
                  name="identity_proof"
                  rules={[
                    {
                      required: true,
                      message: "Select any document field!",
                    },
                  ]}
                >
                  <Radio.Group
                    disabled={user.document_status === "Failed" ? false : true}
                    value={identitySelect}
                    onChange={(value) => {
                      setIdentitySelect(value.target.value);
                    }}
                  >
                    <Space
                      direction={
                        window.screen.width <= 480 ? "vertical" : "horizontal"
                      }
                    >
                      <Space direction="vertical">
                        <Radio value={"Passport"} className="app-radio-input">
                          Passport
                        </Radio>
                        <Radio
                          value={"Driving license"}
                          className="app-radio-input"
                        >
                          Driving license
                        </Radio>
                      </Space>
                      <Space direction="vertical">
                        <Radio value={"ID Card"} className="app-radio-input">
                          ID card
                        </Radio>
                        <Radio
                          value={"Residence permit"}
                          className="app-radio-input"
                        >
                          Residence permit
                        </Radio>
                      </Space>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Row className="text-center" gutter={[20, 20]}>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={
                        <Typography.Text className="text-white-color">
                          Front image
                        </Typography.Text>
                      }
                      name="front_img"
                      rules={[
                        {
                          required: true,
                          message: "Upload front image of identity proof",
                        },
                      ]}
                    >
                      <Upload
                        disabled={
                          user.document_status === "Failed" ? false : true
                        }
                        listType="picture-card"
                        beforeUpload={(file) => {
                          const isPNG =
                            file.type === "image/jpg" ||
                            file.type === "image/jpeg" ||
                            file.type === "image/png" ||
                            file.type === "image/pdf";
                          if (!isPNG) {
                            notification.error({
                              message:
                                "Document type must be jpg/jpeg/png/pdf only !!",
                            });
                          }
                          return isPNG || Upload.LIST_IGNORE;
                        }}
                        fileList={frontImg}
                        onChange={frontOnChange}
                        onPreview={handlePreview}
                        accept=".png,.jpg,.jpeg"
                      >
                        {frontImg.length < 1 && (
                          <Space direction="vertical" size={0}>
                            <Icon
                              component={uploadIcon}
                              className="upload-icon"
                            />
                            <p>
                              Upload the &nbsp;
                              <span className="primary-color">front</span>
                              &nbsp; of
                            </p>
                            <p> your document</p>
                          </Space>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      label={
                        <Typography.Text className="text-white-color">
                          Back image
                        </Typography.Text>
                      }
                      name="back_img"
                      rules={[
                        {
                          required: true,
                          message: "Upload back image of identity proof",
                        },
                      ]}
                    >
                      <Upload
                        disabled={
                          user.document_status === "Failed" ? false : true
                        }
                        listType="picture-card"
                        fileList={backImg}
                        beforeUpload={(file) => {
                          const isPNG =
                            file.type === "image/jpg" ||
                            file.type === "image/jpeg" ||
                            file.type === "image/png" ||
                            file.type === "image/pdf";
                          if (!isPNG) {
                            notification.error({
                              message:
                                "Document type must be jpg/jpeg/png/pdf only !!",
                            });
                          }
                          return isPNG || Upload.LIST_IGNORE;
                        }}
                        onChange={backOnChange}
                        onPreview={handlePreview}
                        accept=".png,.jpg,.jpeg"
                      >
                        {backImg.length < 1 && (
                          <Space direction="vertical" size={0}>
                            <Icon
                              component={uploadIcon}
                              className="upload-icon"
                            />
                            <p>
                              Upload the &nbsp;
                              <span className="primary-color">back</span>
                              &nbsp; of
                            </p>
                            <p> your document</p>
                          </Space>
                        )}
                      </Upload>
                    </Form.Item>
                    <Modal
                      style={{ background: "black" }}
                      visible={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="uploaded_image"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Col>
                </Row>
              </Space>
            </div>
          </Col>
          <Col xs={12}>
            <div>
              <Button
                className="submit-button"
                htmlType={"submit"}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateProfileModal;
