const { REACT_APP_URL } = process.env;

export const DUE_DATE:string="THU JAN 10 2023 23:59:59 GMT+0530 (UTC)";

export const GMAIL_LINK:string = "https://mail.google.com/mail/u/0/#search/from%3A(support%40haveto.com)"

export const COPIED_URL:string = REACT_APP_URL+"/lets-verified";

export const SALES_PROGRESS_DUE_DATE:string="Sat Dec 31 2022 23:59:59 GMT+0530 (UTC)";

interface statusInterface {
    [key: string]: {
      NUMBER: number;
      STATUS: string;
    };
}
export const status:statusInterface ={
    PENDING : {
        NUMBER:1,
        STATUS:"Pending"
    },
    FAILED : {
        NUMBER:2,
        STATUS:"Failed"
    },
    SUCCESS : {
        NUMBER:3,
        STATUS:"Success"
    },
}

export const PaymentStatusColor:any={
    'Waiting' : "#FDFD96",
    'Failed' : "#F290A3",
    'paid' : "#7ADAB8",
    'Partially Paid' : "#0077b6",
    'In Progress' : "#b7e4c7",
    'Refunded' : "#e5e5e5",
    "Expired" : "#ff1654"
}

// Generating space by passing a number as argument 
export function spaceGenerator(no_of_space: number) {
    let space_str = "";
    for (let i = 1; i <= no_of_space; i++) {
      space_str += "\u00a0";
    }
    return space_str;
  }

// Default URL for AWS s3 bucket : 
// export const FRONT_IMAGE_URL = "https://haveto-website.s3.us-east-2.amazonaws.com/front_image/";
// export const BACK_IMAGE_URL = "https://haveto-website.s3.us-east-2.amazonaws.com/back_image/";

// function for comma with digit :

export function formatNumber(x:string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
