import { createAction } from "@reduxjs/toolkit";
import { AllUsersPayload, ChangePasswordPayload, DeactivateActivateUserPayload, EmailVerificationPayload, ManageUserPayload, ModalPayload, ResendMailPayload, SignInPayload, SignInResponse, SignUpPayload, UpdateUserPayload, VerifyDataAndDocumentPayload } from "../../services/api/user/types";


export const actionTypes = {
    Signin: '[Signin] Sign In',
    SigninSuccess: '[Signin] Sign In Success',
    SigninFailure: '[Signin] Sign In Failure',

    Signup: '[Signup] Sign Up',
    SignupSuccess: '[Signup] Sign Up Success',
    SignupFailure: '[Signup] Sign Up Failure',

    ResendEmailVerificationMail: '[Resend-Mail] Resend Email verification mail',
    ResendEmailVerificationMailSuccess: '[Resend-Mail] Resend Email verification mail Success',
    ResendEmailVerificationMailFailure: '[Resend-Mail] Resend Email verification Failure',

    SigninModal: '[Signin] Sign In Modal',
    SignupModal: '[Signup] Sign Up Modal',
    CheckMailModal: '[Checkmail] Check Mail Modal',

    SignOut: '[Signout] Sign Out',

    GetAllUserDetails: '[Get All User] Get All User Details',
    GetAllUserDetailsSuccess: '[Get All User] Get All User Details Success',
    GetAllUserDetailsFailure: '[Get All User] Get All User Details Failure',

    GetUserDetails: '[Get User] Get User Details',
    GetUserDetailsSuccess: '[Get User] Get User Details Success',
    GetUserDetailsFailure: '[Get User] Get User Details Failure',

    ManageUserDetails: '[Manage User] Manage User Details',
    ManageUserDetailsSuccess: '[Manage User] Manage User Details Success',
    ManageUserDetailsFailure: '[Manage User] Manage User Details Failure',

    VerifyDataAndDocStatus: '[Veify Data] Verify Data And Docs Status',
    VerifyDataAndDocStatusSuccess: '[Veify Data] Verify Data And Docs Status Success',
    VerifyDataAndDocStatusFailure: '[Veify Data] Verify Data And Docs Status Failure',

    ActiveAndDeactivateUser: '[Active Deactive User] Active or Deactivate User',
    ActiveAndDeactivateUserSuccess: '[Active Deactive User] Active or Deactivate User Success',
    ActiveAndDeactivateUserFailure: '[Active Deactive User] Active or Deactivate User Failure',

    GetStepStatus: '[Status] Get Step Status',
    GetStepStatusSuccess: '[Status] Get Step Status Success',
    GetStepStatusFailure: '[Status] Get Step Status Failure',

    UpdateUser: '[User] Update User',
    UpdateUserSuccess: '[User] Update User Success',
    UpdateUserFailure: '[User] Update User Failure',

    EmailVerification: '[Verification] Email Verification',
    EmailVerificationSuccess: '[Verification] Email Verification Success',
    EmailVerificationFailure: '[Verification] Email Verification Failure',

    ChangePassword: '[Change Password] Change Password',
    ChangePasswordSuccess: '[Change Password] Change Password Success',
    ChangePasswordFailure: '[Change Password] Change Password Failure',
    
    VerifyUserModal :'[Verify Modal] Verify user modal'
}

export const userActions = {
    signIn: createAction<SignInPayload>(actionTypes.Signin),
    signInSuccess: createAction<SignInResponse>(actionTypes.SigninSuccess),
    signInFailure: createAction<SignInResponse>(actionTypes.SigninFailure),

    signUp: createAction<SignUpPayload>(actionTypes.Signup),
    signUpSuccess: createAction<SignInResponse>(actionTypes.SignupSuccess),
    signUpFailure: createAction<SignInResponse>(actionTypes.SignupFailure),

    resendEmailVerificationMail: createAction<ResendMailPayload>(actionTypes.ResendEmailVerificationMail),
    resendEmailVerificationMailSuccess: createAction<SignInResponse>(actionTypes.ResendEmailVerificationMailSuccess),
    resendEmailVerificationMailFailure: createAction<SignInResponse>(actionTypes.ResendEmailVerificationMailFailure),

    signInModal: createAction<ModalPayload>(actionTypes.SigninModal),
    signUpModal: createAction<ModalPayload>(actionTypes.SignupModal),
    checkMailModal: createAction<ModalPayload>(actionTypes.CheckMailModal),
    verifyUserModal: createAction<ModalPayload>(actionTypes.VerifyUserModal),
    
    signOut: createAction(actionTypes.SignOut),

    getAllUserDetails:createAction<AllUsersPayload>(actionTypes.GetAllUserDetails),
    getAllUserDetailsSuccess:createAction<SignInResponse>(actionTypes.GetAllUserDetailsSuccess),
    getAllUserDetailsFailure:createAction<SignInResponse>(actionTypes.GetAllUserDetailsFailure),

    getUserDetails:createAction(actionTypes.GetUserDetails),
    getUserDetailsSuccess:createAction<SignInResponse>(actionTypes.GetUserDetailsSuccess),
    getUserDetailsFailure:createAction<SignInResponse>(actionTypes.GetUserDetailsFailure),

    manageUserDetails:createAction<ManageUserPayload>(actionTypes.ManageUserDetails),
    manageUserDetailsSuccess:createAction<SignInResponse>(actionTypes.ManageUserDetailsSuccess),
    manageUserDetailsFailure:createAction<SignInResponse>(actionTypes.ManageUserDetailsFailure),
    
    verifyDataAndDocStatus:createAction<VerifyDataAndDocumentPayload>(actionTypes.VerifyDataAndDocStatus),
    verifyDataAndDocStatusSuccess:createAction<SignInResponse>(actionTypes.VerifyDataAndDocStatusSuccess),
    verifyDataAndDocStatusFailure:createAction<SignInResponse>(actionTypes.VerifyDataAndDocStatusFailure),

    activeAndDeactivateUser:createAction<DeactivateActivateUserPayload>(actionTypes.ActiveAndDeactivateUser),
    activeAndDeactivateUserSuccess:createAction<SignInResponse>(actionTypes.ActiveAndDeactivateUserSuccess),
    activeAndDeactivateUserFailure:createAction<SignInResponse>(actionTypes.ActiveAndDeactivateUserFailure),

    getStepStatus:createAction(actionTypes.GetStepStatus),
    getStepStatusSuccess:createAction<SignInResponse>(actionTypes.GetStepStatusSuccess),
    getStepStatusFailure:createAction<SignInResponse>(actionTypes.GetStepStatusFailure),

    updateUser:createAction<UpdateUserPayload>(actionTypes.UpdateUser),
    updateUserSuccess:createAction<SignInResponse>(actionTypes.UpdateUserSuccess),
    updateUserFailure:createAction<SignInResponse>(actionTypes.UpdateUserFailure),

    emailVerification:createAction<EmailVerificationPayload>(actionTypes.EmailVerification),
    emailVerificationSuccess:createAction<SignInResponse>(actionTypes.EmailVerificationSuccess),
    emailVerificationFailure:createAction<SignInResponse>(actionTypes.EmailVerificationFailure),

    changePassword:createAction<ChangePasswordPayload>(actionTypes.ChangePassword),
    changePasswordSuccess:createAction<SignInResponse>(actionTypes.ChangePasswordSuccess),
    changePasswordFailure:createAction<SignInResponse>(actionTypes.ChangePasswordFailure),
    
}