import React, { useEffect } from "react";
import { Row, Col } from "antd";
import {  WarningOutlined } from "@ant-design/icons";
import AlertComponent from "./components/AlertComponent";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";
import { userActions } from "../../store/user";
import { status } from "../../constants/ConstantVar";
import TokenBalanceCard from "./components/TokenBalanceCard";
import TokenConvertor from "./components/TokenConvertor";
import TransactionCard from "./components/RecentTransaction";
import TokenCalculation from "./components/TokenCalculation";
import UserTokenCalculation from "./components/TokenCalculation";
import LineChart from "./components/LineChart";
import SaleProgress from "./components/SaleProgress";
import HotFullNode from "./components/HotFullNode";
import ColdFullNode from "./components/ColdFullNode";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userActions.getUserDetails());
  }, [dispatch]);
  const { document_status,is_staff } = useAppSelector(userSelectors.getUserDetails);

  return (
    <Row className="row-gap">
      <Col span={24}>
        {(!is_staff && document_status === status.PENDING.STATUS) ? (
          <AlertComponent
            title="Your verification is pending, we are currently verifying your
            documents."
            type="warning"
            icon={<WarningOutlined />}
          />
        ) :(!is_staff && document_status === status.FAILED.STATUS) ? (
          <AlertComponent
            title="Document verification failed!"
            type="error"
            icon={<WarningOutlined />}
          />
        ) : (
          <></>
        )}
      </Col>
      <Col span={24}>
        <Row gutter={[30, 20]}>
          <Col xs={24} md={24} lg={9} xl={7} xxl={6}>
            <TokenBalanceCard />
          </Col>
          <Col xs={24} md={24} lg={15} xl={17} xxl={18}>
            <TokenConvertor/>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={[30, 20]}>
          <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
            <HotFullNode />
          </Col>
          <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
            <ColdFullNode/>
          </Col>
        </Row>
      </Col> 
      
      {/* To do remove the commented code below when actual price rate we get */}
       <Col span={24}>
        <Row gutter={[30, 20]}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <TransactionCard />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <TokenCalculation />
          </Col>
        </Row>
      </Col> 

      {/* when we uncomment the above code we need to remove the below code */}
    {/*  {
        is_staff && is_staff !== undefined ? (
          <Col span={24}>
            <Row gutter={[30, 20]}>
              <Col xs={24} sm={24} md={24} lg={16}>
                <TransactionCard />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <UserTokenCalculation />
              </Col>
            </Row>
          </Col>
        ) : !is_staff && is_staff !== undefined ? (
          <Col span={24}>
            <Row gutter={[30, 20]}>
              <Col>
                <TransactionCard />
              </Col>
            </Row>
          </Col>
        ) : (
          <></>
        )
      }
      */}
      <Col span={24}>
        <Row gutter={[30, 20]}>
          <Col xs={24} sm={24} md={24} lg={16}>
            <LineChart/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <SaleProgress/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
