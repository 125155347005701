import React, { useState } from 'react';
import { Card, Space, Button, Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import axios from 'axios';

const ColdFullNode: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyNowClick = async (nodeType: any) => {
    setIsLoading(true); // Set loading to true when the button is clicked
    const token = localStorage.getItem("authToken");
    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_PROD_URL+`/auth/user/user-node-details/`,
        {
          node_type: nodeType,
        },
        option
      );
      setModalContent("Our representative will connect with you soon.");
    } catch (error) {
      setModalContent("You have already subscribed for this node.");
    } finally {
      setIsLoading(false); // Set loading to false once the API call is completed
      setIsModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  
  return (
    <>
      <Card className="hotfullnode" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Space direction="vertical" size={20} style={{ textAlign: 'center' }}>
          <div>
            <span className="text-32b" style={{ display: 'block' }}>Cold Full Node</span>
            <br />
            <span className="text-16b" style={{ display: 'block' }}>$200,000 / lifetime access</span>
          </div>
          <div>
            <Button
              className='hotfullnode-btn'
              size="large"
              onClick={() => handleBuyNowClick("ColdFullNode")}
              loading={isLoading} // Add loading state to the button
            >
              Buy Now
            </Button>
          </div>
        </Space>
      </Card>
      <Modal
        visible={isModalVisible}
        footer={null}
        centered
        onCancel={handleCloseModal}
        bodyStyle={{ textAlign: 'center', padding: '40px' }}
      >
        <p style={{ fontWeight: 'bold', fontSize: '32px', color: '#353535', marginTop: '30px' }}>{modalContent}</p>
        <Button
          type="primary"
          onClick={handleCloseModal}
          style={{ marginTop: '15px', borderRadius: '25px', height: '42px', width: '85px' }}
        >
          Okay
        </Button>
      </Modal>
    </>
  );
};

export default ColdFullNode;
