import { call, getContext, put, takeLeading } from "redux-saga/effects";
import { actionTypes } from "./actions";
import { PayloadAction } from "@reduxjs/toolkit";
import { HAVETOApi } from "../../services/api";
import { subscriptionActions } from "./actions";
import { SubscriptionPayload } from "../../services/api/subscription/types";
import { notification } from "antd";
import { SubscriptionState } from "./reducer";

const { createSubscriptionSuccess, createSubscriptionFailure } = subscriptionActions;

function* createSubscriptionFlow(action: PayloadAction<SubscriptionPayload>) {
  const api: HAVETOApi = yield getContext("api");
  const { subscription, error } = yield call(api.subscription.createSubscription,action.payload);
  if (error) {
    
    yield put(createSubscriptionFailure({ subscription: null, error }));
  } else {
    yield put(createSubscriptionSuccess({ subscription, error: null }));
  }
}

export default function* subscriptionSaga() {
    // Calling API Subscription
    yield takeLeading(actionTypes.CreateSubscription, createSubscriptionFlow);
    // Do Action when success called Subscription
    yield takeLeading(actionTypes.CreateSubscriptionSuccess, function* (action: PayloadAction<SubscriptionState>) {
      yield notification.success({message: "Thanks for subscribing !!" || "Something went wrong"})
    });
    // Do Action when Failure called Subscription
    yield takeLeading(actionTypes.CreateSubscriptionFailure, function* (action: PayloadAction<SubscriptionState>) {
      yield notification.error({message: action.payload.error?.message?.email[0] || "Something went wrong"})
    });
}
