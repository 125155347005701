import React from "react";
import { Col, Layout, Row } from "antd";
import moment from "moment";
const { Footer } = Layout;

const FooterSection = () => {
  const currentYear = moment().utc().format("YYYY");
  return (
    <Footer>
      <Row>
        <Col span={24}>&#169; {currentYear} HaveTo. All rights reserved</Col>
      </Row>
    </Footer>
  );
};

export default FooterSection;
