import React, { useEffect, useState } from "react";
import { Area } from "@ant-design/plots";
import { Card, Select, notification } from "antd";
import moment from "moment";
import haveToApi from "../../../services/api/transaction/api";
import { TokenSaleInterface } from "../../../services/api/transaction/types";

const LineChart = () => {
  const [salesData, setSalesData] = useState<TokenSaleInterface[]>([]);
  const [loader, setloader] = useState<boolean>(false)
  useEffect(() => {
    setloader(true)
    haveToApi
      .getSalesHaveToChartData({ duration: "all" })
      .then((result) => {
        setSalesData(result.token_sale_data);
        setloader(false);
      })
      .catch((err) => {
        setloader(false)
        notification.error({message:err?.message?.detail})
      });
  }, []);

  const defaultData = [
    {
      usd_value: 100,
      created_or_updated_at__date: moment().format("YY-MM-DD"),
    },
  ];
  const config = {
    data: salesData?.length > 0 ? salesData : defaultData ,
    xField: "created_or_updated_at__date",
    yField: "usd_value",
    color: "#4285F4",
    smooth: true,
    xAxis: {
      range: [0, 1] /* if reverse then [1,0] */,
      tickCount: salesData.length,
    },
    yAxis: {
      tickCount: 5,
      title: {
        text: "No.of Sale haveto coin",
        style: {
          fontSize: 16,
        },
      },
    },
    areaStyle: () => {
      return {
        fill: "l(90) 0.9:#d6e5fd 0.7:#a8c7fa 0.5:#7facf8 0.3:#4c8bf5",
      };
    },
    slider: {
      trendCfg: {
        isArea: false,
      },
    },
  };

  return (
    <Card
      className="token-sale-chart-card"
      title={"Token Sale"}
      extra={
        <Select
          defaultValue={"all"}
          className="token-sale-select"
          onChange={(selectedVal) => {
            setloader(true)
            haveToApi
            .getSalesHaveToChartData({ duration: selectedVal })
            .then((result) => {
              setSalesData(result.token_sale_data)
              setloader(false)
            })
            .catch((err) => {
              notification.error({message:err?.message?.detail})
              setloader(false)
            });
          }}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="week">Weekly</Select.Option>
          <Select.Option value="month">Monthly</Select.Option>
          <Select.Option value="year">Yearly</Select.Option>
        </Select>
      }
    >
      <div className="token-sale-chart-card-body">
        <Area loading={loader} {...config} />
      </div>
    </Card>
  );
};

export default LineChart;
