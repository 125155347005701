import { API } from "../../allApi";
import { haveToApiService } from "../../plugins/axios";
import {
  SubscriptionApiInterface,
  SubscriptionPayload,
  SubscriptionResources,
} from "./types";

const SUBSCRIPTION_ENDPOINTS: SubscriptionResources = {
  // create subscription get notified
  CREATE_SUBSCRIPTION: {
    URL: API.CREATE_SUBSCRIPTION.URL,
    METHOD: API.CREATE_SUBSCRIPTION.METHOD,
  },
};

const HAVETOApi: SubscriptionApiInterface = {
  createSubscription: async (data: SubscriptionPayload) => {
    const { response, error } = await haveToApiService({
      resource: SUBSCRIPTION_ENDPOINTS.CREATE_SUBSCRIPTION,
      options: {
        data: {
          email: data.email,
        },
      },
    });

    if (error) {
      return { error, subscription: null };
    } else {
      return {
        subscription: response,
        error: null,
      };
    }
  },
};

export default HAVETOApi;

export { SUBSCRIPTION_ENDPOINTS };
