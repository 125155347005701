import React, { useEffect, useState } from "react";
import { Button, Divider, Image, Skeleton, Space } from "antd";
import hImg from "../../../assets/user-dashboard/New_Hlogo.png";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userWalletAction, userWalletSelectors } from "../../../store/wallet";
import userSelectors from "../../../store/user/selectors";
import EditTokenModal from "./EditTokenModal";

const TokenConvertor = () => {
  const [modelVisible, setmodelVisible] = useState<boolean>(false);
  const loader: boolean = useAppSelector(userWalletSelectors.getLoader);
  const { USDT, ETH } = useAppSelector(userWalletSelectors.htcTokenConversion);
  const { is_staff } = useAppSelector(userSelectors.getUserDetails);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userWalletAction.tokenConversion({ htc: 1 }));
  }, [dispatch]);

  return (
    <div className="token-converter-card">
      {loader ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : (
        <>
          {/* left container */}
          {/* TO DO remove the comment when we get the usd price of the HTC */}
          {/* <div className="token-converter">
            {is_staff && (
              <>
                <EditTokenModal
                  modalVisible={modelVisible}
                  onClose={() => {
                    setmodelVisible(false);
                  }}
                />
                <div className="edit-htc-token">
                  <EditOutlined
                    className=""
                    onClick={() => {
                      setmodelVisible(true);
                    }}
                  />
                </div>
              </>
            )} */}
            <div className="token-converter">
            <Image src={hImg} className="token-h-img" preview={false} />
            <div className="text-32">
              1 HTC =  {parseFloat(USDT?.toString()!).toFixed(3)} USD
            </div>
          </div>
          <Divider type="vertical" className="token-divider" /> 
          {/* right container */}
          <div className="token-converter">
            <Space direction="horizontal">
              <div className="text-16">Token name:</div>
              <div className="text-16 text-primary-color">HaveTo</div>
            </Space>
            <Space direction="horizontal">
              <div className="text-16">Token symbol:</div>
              <div className="text-16 text-primary-color">HaveTo</div>
            </Space>
            <Button
              className="download-whitepaper-btn"
              icon={<DownloadOutlined />}
            >
              Download Whitepaper
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default TokenConvertor;
