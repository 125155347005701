import React, { useEffect, useState } from "react";
import { Avatar, Button, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { getRandomColor } from "../../../constants/getRandomColor";
import { UpdateUserInterface } from "../../../services/api/user/types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userActions } from "../../../store/user";
import userSelectors from "../../../store/user/selectors";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { formatNumber } from "../../../constants/ConstantVar";
import { useNavigate } from "react-router-dom";

const UsersList = () => {
  const [pageSize, setpageSize] = useState<number>(10);
  const users = useAppSelector(userSelectors.getAllUserDetails);
  const dispatch = useAppDispatch();
  const loader = useAppSelector(userSelectors.getLoader);
  const navigate = useNavigate()
  function manageUser (id:number){
    navigate('/users/manage/'+id)
  }
  const paginationProps = {
    showLessItems: true,
    showSizeChanger: true,
    pageSize: pageSize,

    total: users.count,
    onChange: (pageNumber: number) => {
      dispatch(
        userActions.getAllUserDetails({
          page: pageNumber,
          pageSize: pageSize,
        })
      );
    },
    onShowSizeChange: (current: number, size: number) => {
      setpageSize(size);
      dispatch(
        userActions.getAllUserDetails({ page: current, pageSize: size })
      );
    },
  };
  const columns: ColumnsType<UpdateUserInterface> = [
    {
      key: 1,
      title: "Username",
      render: (user) => {
        return (
          <div>
            <Space direction="horizontal" size={20}>
              <Avatar
                style={{ backgroundColor: getRandomColor() }}
                size="large"
              >
                {user?.first_name?.[0]
                  .concat(user?.last_name?.[0] ?? "")
                  .toUpperCase()}
              </Avatar>
              <Space direction="vertical" size={0}>
                {
                  user.is_active ?
                    <div className={"grey text-16"}>
                      {user?.first_name + " " + user?.last_name}
                    </div>
                  :
                    <div className="failed-color text-16">
                      <Tooltip title={user?.first_name + " " + user?.last_name+" is locked by admin"}>
                        {user?.first_name + " " + user?.last_name}
                      </Tooltip>
                    </div>
                    
                }
                <div className="profile-text-color text-12">{user?.email}</div>
              </Space>
            </Space>
          </div>
        );
      },
        sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
          a.first_name?.localeCompare(b?.first_name!)!,
    },
    {
      key: 2,
      title: "Token [HTC]",
      render: (user) => {
        return (
          <div className="grey">
            {parseFloat(user?.htc_token).toFixed(2)}
            <sub className="text-primary-color"> HTC</sub>
          </div>
        );
      },
        sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
          a?.htc_token! - b?.htc_token!,
    },
    {
      key: 3,
      title: "Verification status",
      render: (user) => {
        return (
          <Space size={25} align="center" className="grey">
            <Space direction="horizontal">
              {user?.is_active === true ? (
                <CheckCircleOutlined className="success-color text-18" />
              ) : (
                <CloseCircleOutlined className="failed-color text-18" />
              )}
              Active
            </Space>
            <Space direction="horizontal" className="grey">
              {user?.is_email_verified === true ? (
                <CheckCircleOutlined className="success-color text-18" />
              ) : (
                <CloseCircleOutlined className="failed-color text-18" />
              )}
              Email
            </Space>
            <Space direction="horizontal" className="grey">
              {user?.document_status === "Pending" ? (
                <ExclamationCircleOutlined className="pending-color text-18" />
              ) : user?.document_status === "Success" ? (
                <CheckCircleOutlined className="success-color text-18" />
              ) : (
                <CloseCircleOutlined className="failed-color text-18" />
              )}
              KYC
            </Space>
          </Space>
        );
      },
    },
    {
      key: 4,
      title: "Last login",
      render: (user) => {
        return (
          <div className="grey">
            {moment(user?.last_login).format(
              "DD MMM Y ● hh:mm:ss"
            )}
          </div>
        );
      },
        sorter: (a: UpdateUserInterface, b: UpdateUserInterface) =>
          a.last_login?.localeCompare(b.last_login!)!,
    },
    {
      key: 5,
      title: "Manage user",
      render: (user) => {
        return (
          <Button className="user-manage-verify-button " onClick={()=>manageUser(user.id)}>
            Manage<RightOutlined/>
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(userActions.getAllUserDetails({ page: 1, pageSize: pageSize }));
  }, [dispatch, pageSize]);
  return (
      <Table
      loading={loader}
      sticky={true}
      scroll={{ x: true, y: 525 }}
      pagination={paginationProps}
      columns={columns}
      dataSource={users.results}
    />
  );
};

export default UsersList;
