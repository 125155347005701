import {all, fork} from "redux-saga/effects";
import {subscriptionSaga} from './subscription';
import userSaga from "./user/saga";
import { userWalletSaga } from "./wallet";
import {transactionSaga} from './transaction';


export function* rootSaga() {
    yield all([fork(subscriptionSaga)]);
    yield all([fork(userSaga)]);
    yield all([fork(userWalletSaga)]);
    yield all([fork(transactionSaga)]);
}
