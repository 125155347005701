import { createSlice } from "@reduxjs/toolkit";
import { TokenConversionInterface, WalletInterface } from "../../services/api/wallet/types";
import { HAVETOApiError } from "../../services/api/types";
import { userWalletAction } from "./actions";


export interface userWalletState {
    totalSoldUSD: number;
    userBalance: WalletInterface;
    htcTokenConvert: TokenConversionInterface;
    totalSoldHTC:number;
    loader: boolean;
    error: HAVETOApiError | null; 
}

const initialState: userWalletState = {
    userBalance: {},
    htcTokenConvert:{},
    totalSoldHTC:0,
    totalSoldUSD:0,
    loader: false,
    error: null,
};

const userWalletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers:{
        // reducers property both creates an action creator function and responds to that action in the slice reducer
    },
    extraReducers: {
        // The extraReducers allows you to respond to an action in your slice reducer but does not create an action creator function
        [userWalletAction.userWallet.type]: (state,action) => {
            state.loader = true;
        },
        [userWalletAction.userWalletSuccess.type]: (state,action) => {
            state.loader = false;
            state.userBalance = action.payload.wallet;
        },
        [userWalletAction.userWalletFailure.type]: (state, action) => {
            state.loader = false;
            state.error = action.payload.error;
        },

        // Token conversion reducer 
        [userWalletAction.tokenConversion.type]: (state,action) => {
            state.loader = true;
        },
        [userWalletAction.tokenConversionSuccess.type]: (state,action) => {
            state.loader = false;
            state.htcTokenConvert = action.payload.tokenConversion;
        },
        [userWalletAction.tokenConversionFailure.type]: (state, action) => {
            state.loader = false;
            state.error = action.payload.error;
        },

        // Edit Token conversion price reducer 
        [userWalletAction.updateTokenConversion.type]: (state,action) => {
            state.loader = true;
        },
        [userWalletAction.updateTokenConversionSuccess.type]: (state,action) => {
            state.loader = false;
            state.htcTokenConvert.USDT = action.payload.tokenConversion.usd_price;
            state.htcTokenConvert.ETH = action.payload.tokenConversion.eth_price
        },
        [userWalletAction.updateTokenConversionFailure.type]: (state, action) => {
            state.loader = false;
            state.error = action.payload.error;
        },

        // Total sold HTC coin
        [userWalletAction.totalSoldHTC.type]: (state,action) => {
            state.loader = true;
        },
        [userWalletAction.totalSoldHTCSuccess.type]: (state,action) => {
            state.loader = false;
            state.totalSoldHTC = action.payload.totalSoldHtc
        },
        [userWalletAction.totalSoldHTCFailure.type]: (state,action) => {
            state.loader = false;
            state.error = action.payload.error
        },

        // Total sold USD
        [userWalletAction.totalSoldUSD.type]: (state,action) => {
            state.loader = true;
        },
        [userWalletAction.totalSoldUSDSuccess.type]: (state,action) => {
            state.loader = false;
            state.totalSoldUSD = action.payload.totalSoldUsd
        },
        [userWalletAction.totalSoldUSDFailure.type]: (state,action) => {
            state.loader = false;
            state.error = action.payload.error
        },

    }
});

export default userWalletSlice.reducer;