import React from "react";
import "./style.css";
import Header from "./Header";
import Roadmap from "./Roadmap";
import DirectPurchasePage from "./DirectPurchase"
import BuyFullNodePage from "./BuyFullNode";
import CoinTable from "./CoinTable";
 import ScrollTop from "./ScrollTop";
// import FAQ from "./Faq";
import About from "./About";
import Footer from "./Footer";
import Features from "./Feature";

const Website = () => {
  
  return (
    <>
      {/* {--------    Header section ---------  } */}
      <Header/>

      {/*  -------------   Features section  -------------------- */}
      <Features/>

      {/* -----------------   Roadmap section   ------------------   */}
      <Roadmap/>

      {/* -----------------   Direct Purchase Page  ------------------   */}
      <DirectPurchasePage/>

      {/* -----------------   Buy Full Node Page ------------------   */}
      <BuyFullNodePage/>
      
      {/* -----------------   Coin table section   ------------------   */}
      <CoinTable/>
      {/* ----------------   FAQs section  ----------------*/}
      <ScrollTop/> 
      {/* <FAQ/> */}
      <About/>
      <Footer/>
    </>
  );
};

export default Website;