import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AllUserInterface,
  TokenInterface,
  UpdateUserInterface,
} from "../../services/api/user/types";
import { HAVETOApiError } from "../../services/api/types";
import { userActions } from "./actions";
import { verify_token } from "../../utils/verifyToken";
import jwt_decode from "jwt-decode";

export interface SignInState {
  user: UpdateUserInterface;
  allUser: AllUserInterface;
  manageUser: UpdateUserInterface;
  loaders: boolean;
  error: HAVETOApiError | null;
  isAuthenticated?: boolean; // These two states are managed in the auth reducer
  SignInModalvisible: boolean;
  SignUpModalvisible: boolean;
  CheckMailvisible: boolean;
  VerifyUserModalVisible:boolean;
  userId: number;
  emailVerifyMessage: string;
  stepStatus : string | null;
}

const initialState: SignInState = {
  user: {},
  allUser: {
    count: 0,
    results:[]
  },
  manageUser:{},
  loaders: false,
  error: null,
  isAuthenticated: verify_token() ? true : false,
  SignInModalvisible: false,
  SignUpModalvisible: false,
  CheckMailvisible: false,
  VerifyUserModalVisible:false,
  userId: -1,
  emailVerifyMessage: "",
  stepStatus : null,
};

const signInSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    // reducers property both creates an action creator function and responds to that action in the slice reducer
    stepStatusUpdate: (state, action: PayloadAction<string>) => {
      state.stepStatus = action.payload;
  }
  },
  extraReducers: {
    // The extraReducers allows you to respond to an action in your slice reducer but does not create an action creator function
    [userActions.signIn.type]: (state) => {
      state.loaders = true;
      state.isAuthenticated = false;
    },
    [userActions.signInSuccess.type]: (state, action) => {
      state.loaders = false;
      localStorage.setItem("authToken", action.payload.user.access);
      const decodedToken: TokenInterface = jwt_decode(
        action.payload.user.access
      );
      state.userId = decodedToken.user_id;
      state.isAuthenticated = true;
      state.SignInModalvisible = false;
      state.stepStatus = action.payload.user.stepStatus;
    },
    [userActions.signInFailure.type]: (state, action) => {
      state.loaders = false;
      state.isAuthenticated = false;
      state.error = action.payload.error;
    },

    [userActions.signUp.type]: (state) => {
      state.loaders = true;
    },
    [userActions.signUpSuccess.type]: (state, action) => {
      state.loaders = false;
      state.SignUpModalvisible = false;
      state.CheckMailvisible = true;
    },
    [userActions.signUpFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [userActions.resendEmailVerificationMailFailure.type]: (state,action) => {
      state.error = action.payload.error;
    },

    [userActions.signInModal.type]: (state, action) => {
      state.SignInModalvisible = action.payload.visible;
    },

    [userActions.signUpModal.type]: (state, action) => {
      state.SignUpModalvisible = action.payload.visible;
    },

    [userActions.checkMailModal.type]: (state, action) => {
      state.CheckMailvisible = action.payload.visible;
    },

    [userActions.verifyUserModal.type]: (state, action) => {
      state.VerifyUserModalVisible = action.payload.visible;
    },

    [userActions.signOut.type]: (state, action) => {
      localStorage.clear();
      state.isAuthenticated = false;
    },

    [userActions.getUserDetailsSuccess.type]: (state, action) => {
      state.user = action.payload.user;
    },
    [userActions.getUserDetailsFailure.type]: (state, action) => {
      state.error = action.payload.error;
    },

    [userActions.getAllUserDetails.type]: (state, action) => {
      state.loaders = true;
    },
    [userActions.getAllUserDetailsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.allUser = action.payload.allUser;
    },
    [userActions.getAllUserDetailsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [userActions.manageUserDetails.type]: (state, action) => {
      state.loaders = true;
    },
    [userActions.manageUserDetailsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.manageUser = action.payload.user;
    },
    [userActions.manageUserDetailsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [userActions.getStepStatusSuccess.type]: (state, action) => {
      state.stepStatus = action.payload.stepStatus
    },
    [userActions.getStepStatusFailure.type]: (state, action) => {
      state.error = action.payload.error;
    },

    [userActions.updateUserSuccess.type]: (state, action) => {
      state.user = action.payload.user;
    },
    [userActions.signUpFailure.type]: (state, action) => {
      state.error = action.payload.error;
    },
    [userActions.emailVerificationFailure.type]: (state, action) => {
      state.emailVerifyMessage =
        (action.payload.error.message?.non_field_errors &&
          action.payload.error.message?.non_field_errors[0]) ??
        action.payload.error.message.error;
    },
    [userActions.emailVerificationSuccess.type]: (state, action) => {
      state.emailVerifyMessage = action.payload.user;
    },
    [userActions.changePasswordSuccess.type]: (state, action) => {
      localStorage.clear();
      state.isAuthenticated = false;
    },
    [userActions.changePasswordFailure.type]: (state, action) => {
      state.error = action.payload.change_password;
    },
  },
});

const {stepStatusUpdate} = signInSlice.actions;
export default signInSlice.reducer;
export {stepStatusUpdate};
