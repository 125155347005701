import { get } from "http";
import { RootState } from "../store";

const userWalletSelectors = {
    fetchuserBalance: (state: RootState) =>state.wallet.userBalance,
    htcTokenConversion: (state: RootState) =>state.wallet.htcTokenConvert,
    getTotalSoldHTC: (state: RootState) =>state.wallet.totalSoldHTC,
    getTotalSoldUSD: (state: RootState) =>state.wallet.totalSoldUSD,
    getLoader: (state: RootState) =>state.wallet.loader
};

export default userWalletSelectors;
