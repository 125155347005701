import React from 'react';
import { useAppDispatch } from "../../store/hooks";
import { userActions } from "../../store/user";

const BuyFullNodePage: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="features" id='buy-full-node'>
      <div className="header-text">Buy Full Node</div>
      <p className="sub-header-text">Get <span>early</span> access</p>
      <div className='node-cards'>
        <div className="node-card">
          <h2>Hot Full Node</h2>
          <h3>$100,000 / 1 year lease</h3>
          <p>Our Hot Validation plan offers fast, real-time blockchain validation with full server management. Ideal for businesses needing continuous high-speed transaction processing.</p>
          <ul>
            <li>Real-time Processing</li>
            <li>Fully Managed Service</li>
            <li>High Availability</li>
            <li>24/7 Expert Support</li>
          </ul>
          <button onClick={() => dispatch(userActions.signInModal({visible:true}))}>Buy Now</button>
        </div>
        <div className="node-card">
          <h2>Cold Full Node</h2>
          <h3>$200,000 / lifetime access</h3>
          <p>Our Cold Validation plan provides long-term security and control, with full access and credentials for self-managed validation. Perfect for clients wanting a customizable and secure solution.</p>
          <ul>
            <li>Secure, Delayed Processing</li>
            <li>Complete Access and Control</li>
            <li>Lifetime Service</li>
            <li>Enhanced Security</li>
          </ul>
          <button onClick={() => dispatch(userActions.signInModal({visible:true}))}>Buy Now</button>
        </div>
      </div>
    </div>
  );
};

export default BuyFullNodePage;
