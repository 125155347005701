import React, { useEffect, useState } from "react";
import coinTable from "../../assets/websiteIcon/cointable.svg";
import coinTableMobile from "../../assets/websiteIcon/coin_table_mobile.png";
import { Col, Image, Row, Space } from "antd";

const CoinTable = () => {
  //  screen width dynamically withput reloading
  const [screen, setScreen] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setScreen(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div className="features" id={"coin-table"}>
      <div className="header-text">Coin Table</div>
      <Row justify={"center"} align={"middle"}>
        <Col span={24} className="text-center">
          {screen.innerWidth > 480 ? (
            <Image src={coinTable} preview={false} />
          ) : (
            <div>
              <Image src={coinTableMobile} preview={false} />
              <div className="coin-list">
                <Space direction="vertical" size={0}>
                  <Space direction="horizontal">
                    <div
                      className="coin-logo"
                      style={{ background: "#bfd5ff" }}
                    />
                    <p className="coin-text">Sale B (15%)</p>
                  </Space>
                  <Space direction="horizontal">
                    <div className="coin-logo" style={{ background: "#e24c4c" }} />
                    <p className="coin-text">Ecosysytem Incentives (37%)</p>
                  </Space>
                  <Space direction="horizontal">
                    <div
                      className="coin-logo"
                      style={{ background: "#d6ae37" }}
                    />
                    <p className="coin-text">Sale A (15%)</p>
                  </Space>
                  <Space direction="horizontal">
                    <div
                      className="coin-logo"
                      style={{ background: "#63ad4c" }}
                    />
                    <p className="coin-text">Team (10%)</p>
                  </Space>
                  <Space direction="horizontal">
                    <div
                      className="coin-logo"
                      style={{ background: "#8f5ed0" }}
                    />
                    <p className="coin-text">Development Fund (23%)</p>
                  </Space>
                </Space>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CoinTable;
