import React from "react";
import { Col, Row, Image, Typography} from "antd";
import mobileImg from "../../assets/websiteIcon/mobile_frames.webp";

const About = () => {
  return (
    <>
      <div className="features" id="about">
        <div className="header-text">About</div>
        <Row justify="center" align="middle">
          <Col xs={24} lg={12} className="about-order2">
            <div className="text-center">
              <Image src={mobileImg} preview={false} />
            </div>
          </Col>
          <Col xs={24} lg={12} className="about-order1">
            <div>
              <Typography.Text className="about-text">
                HaveTo will become the First coin network to allow any
                programming language and supports AI. Blockchain being
                computationally powerful and unstoppable. It brings those powers
                to modern AI.
              </Typography.Text>
              <br />
              <br />
              <Typography.Text className="about-text">
                Thanks to our Ecosystem, we will be able to create 7 innovative
                projects ranging from our own Wallet with different
                functionalities to an Online Platform for NFTs.
              </Typography.Text>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default About;
