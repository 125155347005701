import { Col, Row, Image, Steps } from "antd";
// import moment from "moment";
import React, { useEffect, useState } from "react";
// import roadMap1 from "../../assets/websiteIcon/roadmap/Roadmap1.svg";
// import roadMap2 from "../../assets/websiteIcon/roadmap/Roadmap2.svg";
// import roadMap3 from "../../assets/websiteIcon/roadmap/Roadmap3.svg";
// import roadMap4 from "../../assets/websiteIcon/roadmap/Roadmap4.svg";
import roadMap from "../../assets/websiteIcon/roadmap/Roadmap4.png";
import roadMapMobile from "../../assets/websiteIcon/roadmap/Roadmap_mobile_UI.png";
const { Step } = Steps;

interface RoadmapInterface{
  key:number;
  title:string;
  description:string;
}
const Roadmap = () => {
  // const currentDate = moment().utc().format("YYYY-MM-DD");
  // const firstphase = "2022-08-09";
  // const secondPhase = "2022-08-12";
  // const thirdPhase = "2022-08-13";

  const dataSource:RoadmapInterface[] = [
    {
      key: 1,
      title: "'22 - POC",
      description: "POC With Proof Of Work",
    },
    {
      key: 2,
      title: "'23 - Code Complete",
      description: "Migrated to proof of stake with support of any programming language and Production Ready",
    },
    {
      key: 3,
      title: "'24 - Fund Raising",
      description: "After fund raising we will release the source code to public for mining",
    },
    {
      key: 4,
      title: "'25 - Listing & Adaption",
      description: "Listing and creating HaveTo ecosystem",
    },
  ];

  //  screen width dynamically withput reloading
  const [screen, setScreen] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setScreen(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <div className="features" id={"roadmap"}>
        <div className="header-text">RoadMap</div>
        <Row justify={"center"} align={"middle"}>
          <Col span={24} className="text-center">
            {/* <Image
              src={
                screen.innerWidth <= 480
                  ? roadMapMobile
                  : currentDate === firstphase
                  ? roadMap1
                  : currentDate === secondPhase
                  ? roadMap2
                  : currentDate === thirdPhase
                  ? roadMap3
                  : roadMap4
              }
              preview={false}
            /> */}
            <Image
              src={
                screen.innerWidth <= 480
                  ? roadMapMobile
                  : roadMap
              }
              preview={false}
            />
          </Col>
        </Row>
        {screen.innerWidth <= 480 && (
          <Steps direction="vertical" size="small" current={2}>
            {dataSource.map((item) => {
              return (
                <Step
                  key={item.key}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
          </Steps>
        )}
      </div>
    </>
  );
};

export default Roadmap;