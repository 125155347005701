import { AppstoreOutlined, SwapOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib/menu";
import { Navigate, Route } from "react-router-dom";
import DashboardLayout from "../components/Dashboard";
import Dashboard from "../components/Dashboard/Dashboard";
import Transaction from "../components/Dashboard/Transaction";
import UserProfile from "../components/Dashboard/UserProfile";
import Users from "../components/Dashboard/Users";
import ManageUser from "../components/Dashboard/user_components/manage_user";
import ApplicationData from "../components/User/ApplicationData";
import LetsVerified from "../components/User/LetsVerified";
import ThankyouForm from "../components/User/ThankyouForm";
import { VerifyAccount } from "../components/User/VerifyAccount";
import Website from "../components/Website";
import { STEP_STATUS } from "../constants/stepStatus";
interface RouteInterface {
  [key: string]: {
    URL: string;
  };
}

export const ROUTE: RouteInterface = {
  VERIFY_ACCOUNT: {
    URL: "/verify-account",
  },
  LETS_VERIFIED: {
    URL: "/lets-verified",
  },
  APPLICATION_DATA: {
    URL: "/application-data",
  },
  THANK_YOU: {
    URL: "/thank-you",
  },
  VERIFY_EMAIL: {
    URL: "/verify-email",
  },
};


export const stepStatusRoute = (
  <>
    <Route path={ROUTE.VERIFY_ACCOUNT.URL} element={<VerifyAccount />} />
                <Route path={ROUTE.LETS_VERIFIED.URL} element={<LetsVerified />} />
                <Route path={ROUTE.APPLICATION_DATA.URL} element={<ApplicationData />} />
                <Route path={ROUTE.THANK_YOU.URL} element={<ThankyouForm />} />
  </>
);
export const stepStatusNotFoundRoute = (
  <>
    <Route path={"/"} element={<Website />} />
    <Route
      path={"*"}
      element={<Navigate to={"/"} replace />}
    />
  </>
);

export const dashboardRoute = (
  <>
    <Route path={""} element={<Dashboard />} />
    <Route path={"transaction"} element={<Transaction />} />
    <Route path={"profile"} element={<UserProfile />} />
  </>
)

export const dashboardUserRoute = (
  <>
    <Route path={"/"} element={<DashboardLayout />}>
      {dashboardRoute}
    </Route>
    <Route
      path={"*"}
      element={<Navigate to={"/"} replace />}
    />
  </>
);
export const dashboardAdminRoute = (
  <>
    <Route path={"/"} element={<DashboardLayout />}>
        {dashboardRoute}
      <Route path={"/users"} element={<Users />} >
        <Route path={"manage/:id"} element={<ManageUser />} />
      </Route>
    </Route>
    <Route
      path={"*"}
      element={<Navigate to={"/"} replace />}
    />
  </>
);


export function replaceRoute(stepStatus: string ) {  
  const status:any = {
    [STEP_STATUS.VERIFY_ACCOUNT.STATUS] : ROUTE.VERIFY_ACCOUNT.URL,
    [STEP_STATUS.LETS_VERIFY.STATUS] : ROUTE.LETS_VERIFIED.URL,
    [STEP_STATUS.IDENTITY_DOCUMENT.STATUS] : ROUTE.APPLICATION_DATA.URL
  }

  return status[stepStatus] ?? ROUTE.THANK_YOU.URL;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
): MenuItem {
  return {
    key,
    icon,
    label,
  } as MenuItem;
}
export const items: MenuItem[] = [
  getItem("Dashboard", "/", <AppstoreOutlined />),
  getItem("Transaction", "/transaction", <SwapOutlined />),
  getItem("Profile", "/profile", <UserOutlined />),
];

export const AdminItems: MenuItem[] = [
  getItem("Dashboard", "/", <AppstoreOutlined />),
  getItem("Transaction", "/transaction", <SwapOutlined />),
  getItem("Users", "/users", <TeamOutlined />),
  getItem("Profile", "/profile", <UserOutlined />),
];

