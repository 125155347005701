import { RootState } from "../store";

const userSelectors = {
    getUserDetails: (state: RootState) =>state.user.user,
    getManageUserDetails: (state: RootState) =>state.user.manageUser,
    getAllUserDetails: (state: RootState) =>state.user.allUser,
    getIsAutheticated: (state: RootState)=>state.user.isAuthenticated,
    signInModal: (state: RootState) =>state.user.SignInModalvisible,
    signUpModal: (state: RootState) =>state.user.SignUpModalvisible,
    checkMailModal: (state: RootState) =>state.user.CheckMailvisible,
    verifyUserModal: (state: RootState) =>state.user.VerifyUserModalVisible,
    getLoader:(state: RootState) =>state.user.loaders,
    emailVerifyMessage:(state: RootState) =>state.user.emailVerifyMessage,
    stepStatusChange: (state: RootState) => state.user.stepStatus,
};

export default userSelectors;
