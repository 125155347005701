import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { userActions } from "../../../../store/user/actions";
import userSelectors from "../../../../store/user/selectors";
import haveToAPI from "../../../../services/api/user/api";
import {
  status,
} from "../../../../constants/ConstantVar";
import { CheckOutlined, CloseOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import UploadedDocsModal from "../../profile_components/UploadedDocsModal";

interface verifyModalPropsInterface {
  user_id: number;
}

const VerifyUserModal = ({ user_id }: verifyModalPropsInterface) => {
  const verifymodal = useSelector(userSelectors.verifyUserModal);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelectors.getManageUserDetails);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [dataLoader, setdataLoader] = useState<boolean>(false);
  const [dataButtonText, setDataButtonText] = useState<string>("");
  const [uploadedImage, setUploadedImage] = useState<string>("");
  function showModal(buttonType: string) {
    buttonType === "front"
      ? setUploadedImage(user.front_img || "")
      : setUploadedImage(user.back_img || "");
    setPreviewOpen(true);
  }
  const verifyData = React.useCallback(
    (buttonText: string) => {
      setDataButtonText(buttonText);
      setdataLoader(true);
      haveToAPI
        .VerifyDataAndDocsStatus({
          user_id: Number(user_id),
          application_status:
            buttonText === "Approve-Data" ? "Success" : "Failed",
        })
        .then((res) => {
          setdataLoader(false);
        });
    },
    [user_id]
  );
  const verifyDocument = React.useCallback(
    (buttonText: string) => {
      setDataButtonText(buttonText);
      setdataLoader(true);

      haveToAPI
        .VerifyDataAndDocsStatus({
          user_id: Number(user_id),
          document_status: buttonText === "Approve-Docs" ? "Success" : "Failed",
        })
        .then((res) => {
          setdataLoader(false);
        });
    },
    [user_id]
  );
  useEffect(() => {
    if (!isNaN(user_id)) {
      dispatch(userActions.manageUserDetails({ user_id: Number(user_id) }));
    }
    return () => {
      dispatch(
        userActions.getAllUserDetails({
          page: 1,
          pageSize: 10,
          kyc_status: "Pending",
        })
      );
    };
  }, [dispatch, user_id, dataLoader]);
  return (
    <Modal
      title="Verify Applicant data & documents"
      visible={verifymodal}
      centered={true}
      onCancel={() => {
        dispatch(userActions.verifyUserModal({ visible: false }));
      }}
      footer={null}
      width={1000}
    >
      <div className="manage-user-kyc-info">
        {/* Application data */}
        <div className="application-data">
          <p className="text-16">KYC Verification</p>
          <div className="kyc-title-container">
            <div className="text-24">Application Data :</div>
            <Typography.Text
              className={
                user.application_status?.toLowerCase() + "-color-button"
              }
              title={user.application_status}
            >
              {user.application_status}
            </Typography.Text>
          </div>
          <Space direction="vertical" size={10}>
            <Typography.Text className="text-16 profile-text-color"></Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              First Name : {user.first_name ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Last Name : {user.last_name ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              DOB : {moment(user.date_of_birth).format("Do MMM, Y") ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Gender : {user.gender ?? "-"}
            </Typography.Text>
            <Space size={20}>
              <Button
                className={
                  user.application_status === status.PENDING.STATUS ||
                  user.application_status === status.SUCCESS.STATUS
                    ? "approve-button"
                    : "approve-disabled-button"
                }
                disabled={
                  user.application_status === status.PENDING.STATUS
                    ? false
                    : true
                }
                icon={<CheckOutlined />}
                onClick={() => verifyData("Approve-Data")}
                loading={dataButtonText === "Approve-Data" && dataLoader}
              >
                Approve
              </Button>
              <Button
                className={
                  user.application_status === status.PENDING.STATUS ||
                  user.application_status === status.FAILED.STATUS
                    ? "reject-button"
                    : "reject-disabled-button"
                }
                icon={<CloseOutlined />}
                onClick={() => verifyData("Reject-Data")}
                loading={dataButtonText === "Reject-Data" && dataLoader}
                disabled={
                  user.application_status === status.PENDING.STATUS
                    ? false
                    : true
                }
              >
                Reject
              </Button>
            </Space>
          </Space>
        </div>
        {/* Identity Document */}
        <hr className="kyc-verify-hr"></hr>
        <div className="identity-doc">
          <p className="texxt-primary-color text-16"> &nbsp;</p>
          <div className="kyc-title-container">
            <div className="texxt-white-color text-24">Identity Document :</div>
            <Typography.Text
              className={user.document_status?.toLowerCase() + "-color-button"}
              title={user.document_status}
            >
              {user.document_status}
            </Typography.Text>
          </div>
          <Space direction="vertical" size={10}>
            <Typography.Text className="text-16 profile-text-color"></Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Document type : {user.identity_proof ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Country : {user.country ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Uploaded docs : &nbsp;
              <Space direction="horizontal">
                <Button
                  type="ghost"
                  className="kyc-button"
                  icon={<EyeOutlined />}
                  onClick={() => showModal("front")}
                >
                  Front
                </Button>
                <Button
                  type="ghost"
                  className="kyc-button"
                  icon={<EyeOutlined />}
                  onClick={() => showModal("back")}
                >
                  Back
                </Button>
              </Space>
            </Typography.Text>
            <Typography.Text></Typography.Text>
            <Space size={20}>
              <Button
                className={
                  user.document_status === status.PENDING.STATUS ||
                  user.document_status === status.SUCCESS.STATUS
                    ? "approve-button"
                    : "approve-disabled-button"
                }
                onClick={() => verifyDocument("Approve-Docs")}
                disabled={
                  user.document_status === status.PENDING.STATUS ? false : true
                }
                icon={<CheckOutlined />}
                loading={dataButtonText === "Approve-Docs" && dataLoader}
              >
                Approve
              </Button>
              <Button
                className={
                  user.document_status === status.PENDING.STATUS ||
                  user.document_status === status.FAILED.STATUS
                    ? "reject-button"
                    : "reject-disabled-button"
                }
                icon={<CloseOutlined />}
                disabled={
                  user.document_status === status.PENDING.STATUS ? false : true
                }
                onClick={() => verifyDocument("Reject-Docs")}
                loading={dataButtonText === "Reject-Docs" && dataLoader}
              >
                Reject
              </Button>
            </Space>
          </Space>
        </div>
        <hr className="kyc-identity"></hr>
        <UploadedDocsModal
          previewOpen={previewOpen}
          imageUrl={uploadedImage}
          onClose={() => {
            setPreviewOpen(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default VerifyUserModal;
