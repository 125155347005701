import React from "react";
import { Alert, AlertProps } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks";
import { setcurrentSelectedMenu } from "../../../store/ui/reducer";

interface AlertInterface {
  title: string;
  icon: React.ReactNode;
  type: AlertProps["type"] ;
}
const AlertComponent = (props: AlertInterface) => {
  const dispatch = useAppDispatch();
  return (
    <Alert
      key= {props.title}
      icon={props.icon}
      message={
        <div className="warning-alert">
          <span>{props.title}</span>
          <Link to="profile" className="check-status" onClick={()=>dispatch(setcurrentSelectedMenu('/profile'))}>
            Check Status
          </Link>
        </div>
      }
      type={props.type}
      showIcon
    />
  );
};

export default AlertComponent;
