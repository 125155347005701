// TODO: Look for  a way to block axios import in React components
// TODO: Add husky, lint-staged to package.json

import axios from "axios";

// import packageJson from "../../../package.json";

// Type Imports
import { HAVETOApiService, ProcessApiProps } from "../api/types";

// Constant Imports
import { API_EXCEPTIONS } from "../../constants";
import APIURL from "../../utils/getApiURL";


// >>>>>> Gold API Constants <<<<<<<<
// const APP_VERSION = packageJson.version;
// const APP_ENV = process.env.REACT_APP_ENV || "development";


const API_BASE = APIURL

// >>>>>> Gold API Client <<<<<<<<<<
const haveToApi = axios.create({
  baseURL: API_BASE,
  headers: {
    "Content-Type": "application/json",
    'Authorization': "JWT_TOKEN",
    // "device-version": APP_VERSION, // Should be renamed to x-device-version in the future
    // "device-env": APP_ENV, // Should be renamed to x-device-env in the future
    // "device-type": "DASHBOARD", // Should be renamed to x-device-type in the future
  },
});

// >>>>>> Request Interceptor <<<<<<<<<<
haveToApi.interceptors.request.use(
  async (request) => {
    if (request) {
      request.headers = {
        ...request.headers,
      };

      // --------->> Use auth or not? <----------
      // We sent this header from request initializer based on
      // whether the request should be verified or not
    
      return request;
    }
  },
  (err) => Promise.reject(err)
);

// >>>>>> Gold Api Service <<<<<<<<<<
export const haveToApiService: HAVETOApiService = async ({
  resource,
  options,
  headers,
}) => {
  const { url, method, data } = processApiProps({ resource, options });

  // log(`[goldApi] 📤 : ${method} -> ${url}`, data);

  let error: any, response: any;

  try {
    response = await haveToApi.request({
      url,
      method,
      data,
      headers: {
        ...headers,
      },
    });

    // log("[goldApi] 📥  Success: ", returnObject);

    return {
      response: response?.data,
      error: null,
    };
  } catch (err) {
    error = err;

    // log("[goldApi] 📥  Error: ", err);

    if (error.response) {
      return {
        response: null,
        error: { message: error.response.data },
      };
    }
    return {
      response: null,
      error: {
        code: API_EXCEPTIONS.UNKNOWN_EXCEPTION,
        message: "Unknown Error",
      },
    };
  }
};

// >>>>>> Gold Api Helper Functions <<<<<<<<<<

const processApiProps: ProcessApiProps = ({ resource, options = {} }) => {
  let url = resource.URL;
  const method = resource.METHOD;

  // Replace path vars with their value if any
  const pathVars = options.pathVars;

  if (pathVars) {
    Object.keys(pathVars).forEach(
      (key) => (url = url.replace(`:${key}`, `${pathVars[key]}`))
    );
  }

  // Add queryParams if any
  const queryParams = options.queryParams;
  if (queryParams) {
    url +=
      "?" +
      Object.keys(queryParams)
        .map((key) => key + "=" + queryParams[key])
        .join("&");
  }

  return {
    url,
    method,
    data: options.data,
  };
};

export default haveToApi;
