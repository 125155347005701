import React, { useState } from "react";
import { Button, Image, Space, Typography } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import thanks from "../../../assets/user-dashboard/thank.gif";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import userSelectors from "../../../store/user/selectors";
import moment from "moment";
import UploadedDocsModal from "./UploadedDocsModal";
import UpdateProfileModal from "./UpdateProfileModal";
import { userActions } from "../../../store/user";

const KycVerificationCard = () => {
  const user = useAppSelector(userSelectors.getUserDetails);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [updateProfileVisible, setUpdateProfileVisible] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const dispatch= useAppDispatch()
  function showModal(buttonType: string) {
    buttonType === "front"
      ? setUploadedImage(user.front_img || "")
      : setUploadedImage(user.back_img || "");
    setPreviewOpen(true);
  }
  return (
    <>
      <div className="kyc-info-container">
        <div className="application-data">
          <Space size={20} direction="horizontal">
              <div className="profile-text-color text-16">KYC Verification</div>
              {
              (user.application_status === "Failed" || user.document_status === "Failed") &&
                (<Typography.Link className="primary-color text-16 edit-profile" onClick={()=>setUpdateProfileVisible(true)}><EditOutlined/> Edit</Typography.Link>      )
              }
          </Space>
          <div className="kyc-title-container">
            <div className="text-24">Application Data :</div>
            <Typography.Text
              className={
                user.application_status?.toLowerCase() + "-color-button"
              }
              title={user.application_status}
            >
              {user.application_status}
            </Typography.Text>
          </div>
          <Space direction="vertical" size={10}>
            <Typography.Text className="text-16 profile-text-color"></Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              First Name : {user.first_name ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Last Name : {user.last_name ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              DOB : {moment(user.date_of_birth).format("Do MMM, Y") ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Gender : {user.gender ?? "-"}
            </Typography.Text>
          </Space>
        </div>
            <hr className="kyc-hr"></hr>
        <div className="identity-doc">
          <p className="texxt-primary-color text-16"> &nbsp;</p>
          <div className="kyc-title-container">
            <div className="texxt-white-color text-24">Identity Document :</div>
            <Typography.Text
              className={user.document_status?.toLowerCase() + "-color-button"}
              title={user.document_status}
            >
              {user.document_status}
            </Typography.Text>
          </div>
          <Space direction="vertical" size={10}>
            <Typography.Text className="text-16 profile-text-color"></Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Document type : {user.identity_proof ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Country : {user.country ?? "-"}
            </Typography.Text>
            <Typography.Text className="text-16 profile-text-color">
              Uploaded docs : &nbsp;
              <Space direction="horizontal">
                <Button
                  type="ghost"
                  className="kyc-button"
                  icon={<EyeOutlined />}
                  onClick={() => showModal("front")}
                >
                  Front
                </Button>
                <Button
                  type="ghost"
                  className="kyc-button"
                  icon={<EyeOutlined />}
                  onClick={() => showModal("back")}
                >
                  Back
                </Button>
              </Space>
            </Typography.Text>
          </Space>
        </div>
        <hr className="kyc-identity"></hr>
        <span className="kyc-process-image process-image">
          <Image
            src={thanks}
            preview={false}
            style={{ borderRadius: "50%" }}
          ></Image>
        </span>
      </div>
      <UploadedDocsModal
        previewOpen={previewOpen}
        imageUrl={uploadedImage}
        onClose={() => setPreviewOpen(false)}
      />
      <UpdateProfileModal
        visibleModal={updateProfileVisible}
        onClose={() => {
          setUpdateProfileVisible(false);
          dispatch(userActions.getUserDetails())
          }
        }
      />
    </>
  );
};

export default KycVerificationCard;
