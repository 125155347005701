import React from "react";
import { Col, Row } from "antd";
import ChangePassword from "./profile_components/ChangePassword";
import PersonalInfoCard from "./profile_components/PersonalInfoCard";
import KycVerificationCard from "./profile_components/KycVerificationCard";
import { useAppSelector } from "../../store/hooks";
import userSelectors from "../../store/user/selectors";

const UserProfile = () => {
  const { is_staff } = useAppSelector(userSelectors.getUserDetails);
  return (
    <Row className="row-gap">
      <Col span={24}>
        <PersonalInfoCard />
      </Col>
      {(!is_staff )
      && (
        <Col span={24}>
          <KycVerificationCard />
        </Col>
        )
      }
      <Col span={24}>
        <ChangePassword />
      </Col>
    </Row>
  );
};

export default UserProfile;
