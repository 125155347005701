import React from "react";
import { Button, Image, Menu, MenuProps } from "antd";
import havetoLogo from "../../assets/haveto.svg";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { AdminItems, items } from "../../config/route";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setcurrentSelectedMenu } from "../../store/ui/reducer";
import { uiSelectors } from "../../store/ui";
import userSelectors from "../../store/user/selectors";
import BuyHavetoCoinModal from "../Payment/BuyHavetoCoinModal";

interface menuInterface {
  menu: boolean;
  setmenu: Function;
}

const MobileSlidebar = ({menu,setmenu}: menuInterface) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = React.useState(false)
  const {is_staff} = useAppSelector(userSelectors.getUserDetails)
  const currentSelectedMenu = useAppSelector(uiSelectors.getCurrentSelectedMenu)
  const TabbarClick: MenuProps["onClick"] = (e) => {
    dispatch(setcurrentSelectedMenu(e.key));
    navigate(e.key);
    setmenu(false)
  };
  const buyHavetoCoin = ()=>{
    setModalVisible(true)
    setmenu(false)
  }
  return (
    <div
      id="mySidenav"
      className= {`sidenav ${menu && "sidenav-visible"}`}
    >
      <CloseOutlined onClick={() => setmenu(false)} className="closebtn">
        &times;
      </CloseOutlined>
      <div className="logo">
        <Image src={havetoLogo} preview={false} height={50} />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={is_staff ? AdminItems : items}
        selectedKeys={[currentSelectedMenu]}
        defaultSelectedKeys={[currentSelectedMenu]}
        onClick={TabbarClick}
      />
       <Button
        className="buy-htc-btn"
        icon={<ShoppingCartOutlined />}
        onClick={buyHavetoCoin}> Buy HaveTo Token
        </Button>
       <BuyHavetoCoinModal visible={modalVisible} onClose={()=>setModalVisible(false)}/>
    </div>
  );
};

export default MobileSlidebar;
